import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

const JSONInfoDialog = ({ open, onClose, data }) => {
   return (
      <Dialog
         open={open}
         onClose={onClose}
         fullWidth
         maxWidth="md"
         PaperProps={{
            style: {
               backgroundColor: "#1e1e1e",
               color: "#ffffff",
            },
         }}
      >
         <DialogContent>
            <JsonView
               src={data}
               collapsed={2}
               displaySize={false}
               displayObjectSize={false}
               enableClipboard={true}
               theme="default"
            />
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} style={{ color: "#ffffff" }}>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default JSONInfoDialog;
