import { createTheme } from "@mui/material";

const darkTheme = createTheme({
   palette: {
      mode: "dark",
      background: {
         paper: "rgb(15,15,15)",
      },
   },
   components: {
      MuiOutlinedInput: {
         styleOverrides: {
            root: {
               "& fieldset": {
                  borderColor: "rgba(198, 198, 198, 0.23)",
               },
               "&:hover fieldset": {
                  borderColor: "rgba(157, 220, 255)",
               },
               "& input": {
                  color: "rgb(200,200,200)",
               },
            },
         },
      },
   },
});

export default darkTheme;
