import React, { useCallback } from "react";
import { useApi } from "../../config/useApi";
import { useSnackBar } from "../../config/useSnackBar";

export const useHookServices = () => {
   const { apiRequest, loading, error } = useApi();
   const {
      handleCloseSnackbar,
      handleSnackBar,
      snackbarMessage,
      snackbarOpen,
      snackbarSeverity,
   } = useSnackBar();

   const fetchHooks = useCallback(async () => {
      try {
         const response = await apiRequest("hook");
         if (!response || !response.ok) {
            handleSnackBar("Error", "error");
            throw new Error(
               response.message || `Error: ${response.statusText}`
            );
         }
         return await response.json();
      } catch (err) {
         console.error("Error fetching hook list:", err);
      }
   }, [apiRequest]);

   const fetchHookById = useCallback(
      async (hookId) => {
         try {
            const response = await apiRequest(`hook/${hookId}`);
            if (!response || !response.ok) {
               handleSnackBar("Error", "error");
               throw new Error(
                  response.message || `Error: ${response.statusText}`
               );
            }
            return await response.json();
         } catch (err) {
            handleSnackBar(`${`Error: ${err}`}`, "error");
         }
      },
      [apiRequest]
   );

   const fetchGraphList = useCallback(async () => {
      try {
         const response = await apiRequest("graph");
         if (!response || !response.ok) {
            handleSnackBar("Error", "error");
            throw new Error(
               response.message || `Error: ${response.statusText}`
            );
         }
         return await response.json();
      } catch (err) {
         handleSnackBar(`Error:${err}`, "error");
      }
   }, [apiRequest]);

   const createHook = useCallback(
      async (url, method, requestBody) => {
         try {
            const response = await apiRequest(url, method, requestBody);
            if (!response || !response.ok) {
               handleSnackBar("Error", "error");
               throw new Error(
                  response.message || `Error: ${response.statusText}`
               );
            }
            const data = await response.json();
            return data;
         } catch (err) {
            console.error("Error creating schema:", err);
         }
      },
      [apiRequest]
   );

   const deleteHook = useCallback(
      async (hookUuid) => {
         try {
            const response = await apiRequest(`hook/${hookUuid}`, "DELETE");
            if (!response || !response.ok) {
               handleSnackBar(`Delete hook error`, "error");
               throw new Error(
                  response.message ||
                     `Delete hook error: ${response.statusText}`
               );
            }
            const data = await response.json();
            return data;
         } catch (error) {
            console.error("Delete hook error:", error);
         }
      },
      [apiRequest]
   );

   return {
      handleCloseSnackbar,
      handleSnackBar,
      snackbarMessage,
      snackbarOpen,
      snackbarSeverity,
      fetchHooks,
      fetchHookById,
      fetchGraphList,
      createHook,
      deleteHook,
      loading,
      error,
      apiRequest,
   };
};
