import React, { useState, useEffect } from "react";
import {
   Typography,
   Button,
   Modal,
   Box,
   ToggleButtonGroup,
   ToggleButton,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import JsonView from "react18-json-view";
import { marked } from "marked";
import { jsonrepair } from "jsonrepair";

function getMarkdownScore(value) {
   const tokenTypes = [];
   const markdownSpecificTokens = [
      "code",
      "fences",
      "heading",
      "hr",
      "link",
      "blockquote",
      "list",
      "html",
      "def",
      "table",
      "lheading",
      "escape",
      "tag",
      "reflink",
      "strong",
      "codespan",
      "url",
   ];

   marked(value, {
      walkTokens: (token) => {
         tokenTypes.push(token.type);
      },
   });

   const totalTokens = tokenTypes.length;
   const markdownTokens = tokenTypes.filter((type) =>
      markdownSpecificTokens.includes(type)
   );
   const markdownTokenCount = markdownTokens.length;
   return totalTokens > 0 ? markdownTokenCount / totalTokens : 0;
}

const MessageContent = ({ role, content, format }) => {
   const previewLength = 125;
   const [open, setOpen] = useState(false);
   const [contentType, setContentType] = useState("plain");
   const markdown_score = getMarkdownScore(content);
   const original_content = content;

   useEffect(() => {
      if (format === "json_object" || format === "json_list") {
         setContentType("json");
      } else if (markdown_score > 0.1) {
         setContentType("markdown");
      } else {
         setContentType("plain");
      }
   }, [content, format]);

   let jsonParsedContent = null;
   try {
      if (content.trim().startsWith("```")) {
         content = content.substring(3).trim();
         content = content.substring(0, content.lastIndexOf("```"));
         if (content.trim().startsWith("json"))
            content = content.substring(4).trim();
      }
      jsonParsedContent = JSON.parse(content);
   } catch (e) {
      try {
         jsonParsedContent = JSON.parse(jsonrepair(content));
      } catch (e) {
         jsonParsedContent = null;
         content = original_content;
      }
   }

   const renderContent = (text) => {
      return text.split("\n").map((line, index) => (
         <React.Fragment key={index}>
            {line}
            {index < text.split("\n").length - 1 && <br />}
         </React.Fragment>
      ));
   };

   const handleContentTypeChange = (event, newContentType) => {
      if (newContentType !== null) {
         setContentType(newContentType);
      }
   };

   return (
      <>
         <Typography
            variant="body2"
            style={{
               fontFamily: "monospace",
               fontSize: "0.825rem",
            }}
         >
            <b>{role}</b>:&nbsp;
            {content.length > previewLength
               ? renderContent(content.substring(0, previewLength) + "...")
               : renderContent(content)}
            {content.length > previewLength && (
               <Button size="small" onClick={() => setOpen(true)}>
                  View Full
               </Button>
            )}
         </Typography>
         <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box
               sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "60vw",
                  maxHeight: "80vh",
                  bgcolor: "background.paper",
                  p: 4,
                  overflow: "auto",
               }}
            >
               <ToggleButtonGroup
                  value={contentType}
                  exclusive
                  onChange={handleContentTypeChange}
                  aria-label="content type"
                  size="small"
                  sx={{ mb: 2 }}
               >
                  <ToggleButton value="json" aria-label="json">
                     JSON
                  </ToggleButton>
                  <ToggleButton value="plain" aria-label="plain text">
                     Plain
                  </ToggleButton>
                  <ToggleButton value="markdown" aria-label="markdown">
                     Markdown
                  </ToggleButton>
               </ToggleButtonGroup>
               <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  style={{
                     fontFamily:
                        contentType === "plain" ? "inherit" : "monospace",
                     whiteSpace: "pre-wrap",
                  }}
               >
                  {contentType === "json" && jsonParsedContent !== null ? (
                     <JsonView
                        src={jsonParsedContent}
                        collapsed={false}
                        displaySize={false}
                        displayObjectSize={false}
                        enableClipboard={true}
                        theme="default"
                     />
                  ) : contentType === "markdown" ? (
                     <ReactMarkdown>{content}</ReactMarkdown>
                  ) : (
                     renderContent(content)
                  )}
               </Typography>
            </Box>
         </Modal>
      </>
   );
};

export default MessageContent;
