import {styled} from "@mui/material/styles";

const TabLabel = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '6px 0',
    maxWidth: '200px',
    overflow: 'hidden',
    color: 'rgb(160, 160, 160)',
    '&.Mui-selected': {
        color: 'rgb(112, 168, 238)',
    },
}));

export default TabLabel;
