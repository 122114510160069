import React from "react";
import { createRoot } from "react-dom/client";
import { ReactFlowProvider } from "react-flow-renderer";
import App from "./components/App";

const container = document.getElementById("root");
const root = createRoot(container);

// root.render(
//    <React.StrictMode>
//       <ReactFlowProvider>
//          <App />
//       </ReactFlowProvider>
//    </React.StrictMode>
// );

root.render(
   <ReactFlowProvider>
      <App />
   </ReactFlowProvider>
);
