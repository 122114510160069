import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
   addEdge,
   applyEdgeChanges,
   applyNodeChanges,
   Background,
   Controls,
   MiniMap,
   useReactFlow,
} from "react-flow-renderer";
import GraphEditorNodeContextMenu from "./GraphEditorNodeContextMenu";
import GraphEditorEdgeContextMenu from "./GraphEditorEdgeContextMenu";

const GraphEditorFlow = ({
   activeTabIndex,
   nodes,
   edges,
   tabId,
   tabViewports,
   setTabViewports,
   setNodes,
   setEdges,
   setTabs,
   updateNodeAndTab,
   setHasUnsavedChanges,
   onNodeDelete,
   activeNodeId,
   isEditable,
}) => {
   const { fitView, setViewport } = useReactFlow();
   const reactFlowWrapper = useRef(null);
   const [contextMenu, setContextMenu] = useState({
      isOpen: false,
      position: { top: 0, left: 0 },
      nodeId: null,
   });

   const [edgeContextMenu, setEdgeContextMenu] = useState({
      isOpen: false,
      position: { top: 0, left: 0 },
      edgeId: null,
   });

   const onMoveEnd = useCallback(
      (event, viewport) => {
         setTabViewports((prev) => ({ ...prev, [tabId]: viewport }));
      },
      [tabId, setTabViewports]
   );

   const onEdgeContextMenu = useCallback((event, edge) => {
      event.preventDefault();
      event.stopPropagation();
      setEdgeContextMenu({
         isOpen: true,
         position: {
            left: event.clientX,
            top: event.clientY,
         },
         edgeId: edge.id,
      });
   }, []);

   const closeEdgeContextMenu = useCallback(() => {
      setEdgeContextMenu((prev) => ({ ...prev, isOpen: false }));
   }, []);

   const handleDeleteEdge = useCallback(() => {
      if (edgeContextMenu.edgeId) {
         setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.id !== edgeContextMenu.edgeId)
         );
         closeEdgeContextMenu();
         setHasUnsavedChanges(true);
      }
   }, [
      edgeContextMenu.edgeId,
      setEdges,
      closeEdgeContextMenu,
      setHasUnsavedChanges,
   ]);

   const onNodeContextMenu = useCallback((event, node) => {
      event.preventDefault();
      event.stopPropagation();
      setContextMenu({
         isOpen: true,
         position: {
            left: event.clientX,
            top: event.clientY,
         },
         nodeId: node.id,
      });
   }, []);

   const closeContextMenu = useCallback(() => {
      setContextMenu((prev) => ({ ...prev, isOpen: false }));
   }, []);

   const handleDeleteNode = useCallback(() => {
      if (contextMenu.nodeId) {
         setNodes((prevNodes) =>
            prevNodes.filter((node) => node.id !== contextMenu.nodeId)
         );

         setEdges((prevEdges) =>
            prevEdges.filter(
               (edge) =>
                  edge.source !== contextMenu.nodeId &&
                  edge.target !== contextMenu.nodeId
            )
         );

         closeContextMenu();
         setHasUnsavedChanges(true);

         // Call the new onNodeDelete prop
         onNodeDelete(contextMenu.nodeId);
      }
   }, [
      contextMenu.nodeId,
      setNodes,
      setEdges,
      closeContextMenu,
      setHasUnsavedChanges,
      onNodeDelete,
   ]);

   const onConnect = useCallback(
      (params) => {
         setEdges((eds) => addEdge(params, eds));
         setHasUnsavedChanges(true);
      },
      [setEdges, setHasUnsavedChanges]
   );

   const onNodesChange = useCallback(
      (changes) => {
         setNodes((nds) => {
            return applyNodeChanges(changes, nds);
         });
      },
      [setNodes]
   );

   const handleNodesChange = useCallback(
      (changes) => {
         onNodesChange(changes);
         changes.forEach((change) => {
            if (
               change.type === "remove" ||
               // (change.type === "position" && change.dragging === false) ||
               (change.type === "update" && change.item && change.item.data)
            ) {
               setHasUnsavedChanges(true);
               if (change.type === "update") {
                  updateNodeAndTab(change.item.id, change.item.data);
               }
            }
         });
      },
      [onNodesChange, updateNodeAndTab, setHasUnsavedChanges]
   );

   const onEdgesChange = useCallback(
      (changes) => {
         setEdges((eds) => applyEdgeChanges(changes, eds));
         if (changes.some((change) => change.type === "remove")) {
            setHasUnsavedChanges(true);
         }
      },
      [setEdges, setHasUnsavedChanges]
   );

   const onNodeClick = useCallback(
      (event, node) => {
         setTabs((prevTabs) => {
            const newTabs = [...prevTabs];
            const currentTab = newTabs[activeTabIndex];
            const existingPanelIndex = currentTab.nodePanels.findIndex(
               (panel) => panel.id === node.id
            );

            if (existingPanelIndex !== -1) {
               currentTab.activeNodePanelIndex = existingPanelIndex;
            } else {
               currentTab.nodePanels.push({ id: node.id, data: node.data });
               currentTab.activeNodePanelIndex =
                  currentTab.nodePanels.length - 1;
            }

            currentTab.isPanelOpen = true;
            return newTabs;
         });
      },
      [activeTabIndex, setTabs]
   );

   useEffect(() => {
      if (tabViewports[tabId]) {
         setViewport(tabViewports[tabId]);
      } else {
         fitView();
      }
   }, [tabId, fitView, setViewport, tabViewports]);

   useEffect(() => {
      const wrapper = reactFlowWrapper.current;
      const handleContextMenu = (event) => {
         event.preventDefault();
      };

      if (wrapper) {
         wrapper.addEventListener("contextmenu", handleContextMenu);
      }

      return () => {
         if (wrapper) {
            wrapper.removeEventListener("contextmenu", handleContextMenu);
         }
      };
   }, []);

   useEffect(() => {
      const handleGlobalClick = () => {
         if (contextMenu.isOpen) {
            closeContextMenu();
         }
         if (edgeContextMenu.isOpen) {
            closeEdgeContextMenu();
         }
      };

      const handleGlobalContextMenu = (event) => {
         event.preventDefault();
         if (contextMenu.isOpen) {
            closeContextMenu();
         }
         if (edgeContextMenu.isOpen) {
            closeEdgeContextMenu();
         }
      };

      document.addEventListener("click", handleGlobalClick);
      document.addEventListener("contextmenu", handleGlobalContextMenu);

      return () => {
         document.removeEventListener("click", handleGlobalClick);
         document.removeEventListener("contextmenu", handleGlobalContextMenu);
      };
   }, [
      contextMenu.isOpen,
      edgeContextMenu.isOpen,
      closeContextMenu,
      closeEdgeContextMenu,
   ]);

   useEffect(() => {
      setNodes((nds) =>
         nds.map((node) => ({
            ...node,
            className:
               (node.id === activeNodeId ? "isSelected" : "") +
               " " +
               (node.data.hasError ? "hasError" : ""),
         }))
      );
   }, [activeNodeId, setNodes]);

   return (
      <div
         style={{ width: "100%", height: "100%", position: "relative" }}
         ref={reactFlowWrapper}
      >
         <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={handleNodesChange}
            onEdgesChange={isEditable ? onEdgesChange : undefined}
            onConnect={isEditable ? onConnect : undefined}
            onMoveEnd={onMoveEnd}
            onNodeClick={onNodeClick}
            onNodeContextMenu={isEditable ? onNodeContextMenu : undefined}
            onEdgeContextMenu={isEditable ? onEdgeContextMenu : undefined}
            nodesDraggable={true}
            fitView
         >
            <Background />
            <Controls />
            <MiniMap />
         </ReactFlow>

         {/*Only return if isEditable is true*/}

         {isEditable && (
            <div>
               <GraphEditorNodeContextMenu
                  anchorPosition={contextMenu.position}
                  isOpen={contextMenu.isOpen}
                  onClose={closeContextMenu}
                  onDelete={handleDeleteNode}
               />
               <GraphEditorEdgeContextMenu
                  anchorPosition={edgeContextMenu.position}
                  isOpen={edgeContextMenu.isOpen}
                  onClose={closeEdgeContextMenu}
                  onDelete={handleDeleteEdge}
               />
            </div>
         )}
      </div>
   );
};

export default GraphEditorFlow;
