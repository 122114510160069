import React, { useCallback, useEffect, useMemo, useState } from "react";
import computeGraphLayout from "../../../utils/graphLayout";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ReactFlowProvider } from "react-flow-renderer";
import GraphExecutionLogToolbar from "./GraphExecutionLogToolbar";
import GraphExecutionLogSubPanel from "./GraphExecutionLogSubPanel";
import GraphExecutionLogFlow from "./GraphExecutionLogFlow";
import GraphExecutionLogReactFlowContainer from "./GraphExecutionLogReactFlowContainer";
import { getNodeStyle, getStatusIcon } from "../../../utils/styles";
import { CircularProgress } from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import SchemaIcon from "@mui/icons-material/Schema";

const GraphExecutionLogPanel = ({
   value,
   activeTabIndex,
   tab,
   panelHeight,
   onPanelHeightChange,
   tabViewports,
   setTabViewports,
   onNodePanelTabChange,
   onNodePanelClose,
   setNodes,
   setEdges,
   setTabs,
   fetchGraphExecutionLog,
   fetchAndAddGraphEditTab,
   fetchAndAddGraphExecutionTab,
   accessToken,
   logout,
   ...props
}) => {
   const [isAutoRefreshing, setIsAutoRefreshing] = useState(true);
   const [isRefreshing, setIsRefreshing] = useState(false);

   const AUTO_REFRESH_INTERVAL = 1750;

   const isGraphExecutionComplete = (status) => {
      return [200, 300, 400, 500].includes(status);
   };

   const stopAutoRefresh = useCallback(() => {
      setIsAutoRefreshing(false);
   }, []);

   const refreshGraphExecutionLogData = useCallback(async () => {
      if (isRefreshing) return;

      setIsRefreshing(true);
      try {
         const { nodes, edges, data } = await fetchGraphExecutionLog(
            tab.exec_graph.id
         );
         const layoutComputedElements = computeGraphLayout(nodes, edges);

         // Update sub-panel (node) data
         const newPanels = tab.nodePanels.map((panel) => {
            const node = nodes.find((n) => n.id === panel.id);
            return { id: panel.id, data: node.data };
         });

         // setTabs((prevTabs) => {
         //    const newTabs = [...prevTabs];
         //    const currentTab = newTabs[activeTabIndex];
         //    currentTab.exec_graph = data.exec_graph;
         //    currentTab.nodes = layoutComputedElements.nodes;
         //    currentTab.edges = layoutComputedElements.edges;
         //    currentTab.nodePanels = newPanels;
         //    return newTabs;
         // });
         setTabs((prevTabs) => {
            return prevTabs.map((currentTab) => {
               if (currentTab.id === tab.id) {
                  return {
                     ...currentTab,
                     exec_graph: data.exec_graph,
                     nodes: layoutComputedElements.nodes,
                     edges: layoutComputedElements.edges,
                     nodePanels: newPanels,
                  };
               }
               return currentTab;
            });
         });

         if (isGraphExecutionComplete(data.exec_graph.status)) {
            setIsAutoRefreshing(false);
         }
      } catch (error) {
         console.error("refreshGraphExecutionLogData:", error);
      } finally {
         setIsRefreshing(false);
      }
   }, [
      tab.exec_graph.id,
      activeTabIndex,
      fetchGraphExecutionLog,
      isRefreshing,
      isGraphExecutionComplete,
   ]);

   const graph_status_by_code = {
      0: "Graph ready to be queued",
      1: "Graph queued for execution",
      100: "Graph execution in progress",
      101: "Graph execution retry in progress",
      200: "Graph execution successful",
      300: "Graph execution failed",
      400: "Graph execution aborted",
      500: "Graph execution timed out",
   };

   const graphExecutionLogToolbarActionsRight = useMemo(() => {
      if (isGraphExecutionComplete(tab.exec_graph.status)) {
         return [
            {
               tooltip:
                  "Execution Complete: " +
                  graph_status_by_code[tab.exec_graph.status],
               icon: getStatusIcon(tab.exec_graph.status),
               image_only: true,
            },
         ];
      }

      return [
         {
            tooltip: isAutoRefreshing
               ? "Auto-refreshing..."
               : isRefreshing
                 ? "Refreshing..."
                 : "Refresh",
            icon:
               isRefreshing || isAutoRefreshing ? (
                  <CircularProgress size={24} />
               ) : (
                  <RefreshIcon />
               ),
            onClick: refreshGraphExecutionLogData,
            disabled: isRefreshing || isAutoRefreshing,
         },
         {
            tooltip: "Stop Auto-refresh",
            icon: <StopIcon />,
            color: "rgb(211, 80, 94)",
            onClick: stopAutoRefresh,
            invisible: !isAutoRefreshing,
         },
      ];
   }, [
      isAutoRefreshing,
      isRefreshing,
      refreshGraphExecutionLogData,
      stopAutoRefresh,
      tab.exec_graph.status,
   ]);

   const graphExecutionLogToolbarActionsLeft = [
      {
         tooltip: "Edit Graph",
         onClick: () => {
            fetchAndAddGraphEditTab(tab.exec_graph.graph_id);
         },
         icon: <SchemaIcon fontSize="small" />,
      },
   ];

   const onNodeClick = useCallback(
      (event, node) => {
         setTabs((prevTabs) => {
            const newTabs = [...prevTabs];
            const currentTab = newTabs[activeTabIndex];
            const existingPanelIndex = currentTab.nodePanels.findIndex(
               (panel) => panel.id === node.id
            );

            if (existingPanelIndex !== -1) {
               currentTab.activeNodePanelIndex = existingPanelIndex;
            } else {
               currentTab.nodePanels.push({ id: node.id, data: node.data });
               currentTab.activeNodePanelIndex =
                  currentTab.nodePanels.length - 1;
            }

            currentTab.isPanelOpen = true;
            return newTabs;
         });
      },
      [activeTabIndex, setTabs]
   );

   const handleReorderPanels = useCallback(
      (fromIndex, toIndex) => {
         setTabs((prevTabs) => {
            const newTabs = [...prevTabs];
            const currentTab = newTabs[activeTabIndex];
            const [movedPanel] = currentTab.nodePanels.splice(fromIndex, 1);
            currentTab.nodePanels.splice(toIndex, 0, movedPanel);
            currentTab.activeNodePanelIndex = toIndex;
            return newTabs;
         });
      },
      [activeTabIndex, setTabs]
   );

   const handleSubPanelTabChange = useCallback(
      (newIndex) => {
         setTabs((prevTabs) => {
            const newTabs = [...prevTabs];
            const currentTab = newTabs[activeTabIndex];
            currentTab.activeNodePanelIndex = newIndex;
            return newTabs;
         });
      },
      [activeTabIndex, setTabs]
   );

   const handleSubPanelTabClose = useCallback(
      (panelIndex) => {
         setTabs((prevTabs) => {
            const newTabs = [...prevTabs];
            const currentTab = newTabs[activeTabIndex];
            currentTab.nodePanels = currentTab.nodePanels.filter(
               (_, index) => index !== panelIndex
            );
            if (currentTab.nodePanels.length === 0) {
               currentTab.isPanelOpen = false;
               currentTab.activeNodePanelIndex = -1;
            } else if (currentTab.activeNodePanelIndex >= panelIndex) {
               currentTab.activeNodePanelIndex = Math.max(
                  0,
                  currentTab.activeNodePanelIndex - 1
               );
            }
            return newTabs;
         });
      },
      [activeTabIndex, setTabs]
   );

   const activeNodeId = useMemo(() => {
      if (tab.isPanelOpen && tab.nodePanels.length > 0) {
         return tab.nodePanels[tab.activeNodePanelIndex]?.id;
      }
      return null;
   }, [tab.isPanelOpen, tab.nodePanels, tab.activeNodePanelIndex]);

   const nodesWithHighlight = useMemo(() => {
      return tab.nodes.map((node) => ({
         ...node,
         style:
            node.id === activeNodeId
               ? {
                    ...getNodeStyle(node.data.status),
                    background: "rgb(116,164,232)",
                    border: "2px solid rgb(88,88,88)",
                 }
               : getNodeStyle(node.data.status),
      }));
   }, [tab.nodes, activeNodeId]);

   useEffect(() => {
      let intervalId;
      if (
         isAutoRefreshing &&
         !isGraphExecutionComplete(tab.exec_graph.status) &&
         value === activeTabIndex
      ) {
         intervalId = setInterval(
            refreshGraphExecutionLogData,
            AUTO_REFRESH_INTERVAL
         );
      }
      return () => {
         if (intervalId) {
            clearInterval(intervalId);
         }
      };
   }, [
      isAutoRefreshing,
      refreshGraphExecutionLogData,
      AUTO_REFRESH_INTERVAL,
      value,
      activeTabIndex,
   ]);

   return (
      <div
         role="tabpanel"
         hidden={value !== activeTabIndex}
         id={`simple-tabpanel-${activeTabIndex}`}
         aria-labelledby={`simple-tab-${activeTabIndex}`}
         style={{
            width: "100%",
            height: "calc(100%)",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
         }}
      >
         {value === activeTabIndex && (
            <>
               {/* Toolbar */}
               <GraphExecutionLogToolbar
                  leftActions={graphExecutionLogToolbarActionsLeft}
                  rightActions={graphExecutionLogToolbarActionsRight}
               />

               {/* Graph */}
               <div style={{ flex: 1, minHeight: 0 }}>
                  <GraphExecutionLogReactFlowContainer>
                     <ReactFlowProvider>
                        <GraphExecutionLogFlow
                           {...props}
                           tabId={tab.id}
                           tabViewports={tabViewports}
                           setTabViewports={setTabViewports}
                           onNodeClick={onNodeClick}
                           setNodes={setNodes}
                           setEdges={setEdges}
                           nodesDraggable={false}
                           activeNodeId={activeNodeId}
                           nodes={nodesWithHighlight}
                           edges={tab.edges}
                           bearerToken={accessToken}
                           logoutMethod={logout}
                           fetchAndAddGraphExecutionTab={
                              fetchAndAddGraphExecutionTab
                           }
                        />
                     </ReactFlowProvider>
                  </GraphExecutionLogReactFlowContainer>
               </div>

               {/* Tab Sub-panel */}
               {tab.isPanelOpen && tab.nodePanels.length > 0 && (
                  <GraphExecutionLogSubPanel
                     isOpen={tab.isPanelOpen}
                     panels={tab.nodePanels}
                     activeIndex={tab.activeNodePanelIndex}
                     onTabChange={handleSubPanelTabChange}
                     onTabClose={handleSubPanelTabClose}
                     tabId={tab.id}
                     height={panelHeight}
                     onHeightChange={onPanelHeightChange}
                     onReorderPanels={handleReorderPanels}
                     accessToken={accessToken}
                     logout={logout}
                  />
               )}
            </>
         )}
      </div>
   );
};

export default GraphExecutionLogPanel;
