import React, { useCallback, useState } from "react";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { getStatusIcon } from "../../utils/styles";
import computeGraphLayout from "../../utils/graphLayout";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import GraphExecutionLogSidePanelToolbar from "./GraphExecutionLogSidePanelToolbar";

const GraphExecutionLogSidePanel = ({
   logEntries,
   tabs,
   setTabs,
   setActiveTabIndex,
   fetchGraphExecutionLog,
   fetchLogEntries,
}) => {
   const [isRefreshing, setIsRefreshing] = useState(false);

   const fetchAndAddGraphExecutionTab = useCallback(
      async (graph_exec_id) => {
         const newTabId = `exec-${graph_exec_id}`;

         const existingTabIndex = tabs.findIndex((tab) => tab.id === newTabId);
         if (existingTabIndex !== -1) {
            setActiveTabIndex(existingTabIndex);
            return;
         }

         try {
            const graph = await fetchGraphExecutionLog(graph_exec_id);
            const newNodes = graph.nodes;
            const newEdges = graph.edges;
            const data = graph.data;

            const layoutComputedElements = computeGraphLayout(
               newNodes,
               newEdges
            );

            const newTabObject = {
               id: newTabId,
               name: data.exec_graph.name,
               nodes: layoutComputedElements.nodes,
               edges: layoutComputedElements.edges,
               exec_graph: data.exec_graph,
               nodePanels: [],
               activeNodePanelIndex: 0,
               isPanelOpen: false,
               tabType: "GraphExecutionLog",
            };

            setTabs((prevTabs) => {
               const newTabs = [...prevTabs, newTabObject];
               setActiveTabIndex(newTabs.length - 1);
               return newTabs;
            });
         } catch (error) {
            console.error("Error fetching graph data:", error);
         }
      },
      [tabs, setTabs, setActiveTabIndex]
   );

   const handleRefresh = useCallback(async () => {
      setIsRefreshing(true);
      try {
         await fetchLogEntries();
      } catch (error) {
         console.error("Error refreshing graph list:", error);
      } finally {
         setIsRefreshing(false);
      }
   }, [fetchLogEntries, setIsRefreshing]);

   const leftActions = [
      {
         tooltip: "Refresh",
         onClick: handleRefresh,
         disabled: isRefreshing,
         icon: isRefreshing ? (
            <CircularProgress size={20} color="inherit" />
         ) : (
            <RefreshIcon fontSize="small" />
         ),
      },
   ];

   const rightActions = [];

   /* Auto-refresh every 30 seconds */
   React.useEffect(() => {
      const interval = setInterval(() => {
         if (!isRefreshing) {
            handleRefresh();
         }
      }, 30000);
      return () => clearInterval(interval);
   }, [isRefreshing, handleRefresh]);

   return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
         <Typography
            variant="h6"
            style={{
               padding: "8px 16px",
               backgroundColor: "rgb(30,31,34)",
               color: "rgb(102,105,111)",
               borderBottom: "1px solid rgb(57,59,64)",
            }}
         >
            Graph Execution Logs
         </Typography>
         <GraphExecutionLogSidePanelToolbar
            leftActions={leftActions}
            rightActions={rightActions}
         />

         <div style={{ height: "100%", overflow: "auto" }}>
            <List style={{ maxHeight: "100%", padding: "0" }}>
               {logEntries.map((entry, index) => (
                  <ListItemButton
                     key={entry.id}
                     onClick={() => fetchAndAddGraphExecutionTab(entry.id)}
                     style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor:
                           index % 2 === 0
                              ? "rgba(0,0,0,0)"
                              : "rgb(0,0,0,0.12)",
                     }}
                  >
                     <ListItemText
                        primary={entry.name}
                        // We use created_at for log entries, updated_at elsewhere
                        secondary={new Date(entry.created_at).toLocaleString()}
                        primaryTypographyProps={{
                           style: {
                              flex: 1,
                              color: "#d8d8d8",
                           },
                        }}
                        secondaryTypographyProps={{
                           style: {
                              color: "#888888",
                           },
                        }}
                     />
                     <div
                        style={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                        }}
                     >
                        {getStatusIcon(entry.status)}
                     </div>
                  </ListItemButton>
               ))}
            </List>
         </div>
      </div>
   );
};

export default GraphExecutionLogSidePanel;
