import { styled } from "@mui/material/styles";

const TabText = styled("div")({
   flex: 1,
   overflow: "hidden",
   textOverflow: "ellipsis",
   whiteSpace: "nowrap",
});

export default TabText;
