// GraphEditorEdgeContextMenu.jsx
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const GraphEditorEdgeContextMenu = ({
   anchorPosition,
   isOpen,
   onClose,
   onDelete,
}) => {
   return (
      <Menu
         open={isOpen}
         onClose={onClose}
         anchorReference="anchorPosition"
         anchorPosition={anchorPosition}
      >
         <MenuItem onClick={onDelete}>Delete Edge</MenuItem>
      </Menu>
   );
};

export default GraphEditorEdgeContextMenu;
