import React, { useState } from "react";
import { Button, IconButton, Box, Tooltip } from "@mui/material";
import TryIcon from "@mui/icons-material/Try";
import SearchIcon from "@mui/icons-material/Search";
import { usePrompts } from "./usePrompts";
import PromptSearchDialog from "../../SearchDialogs/PromptSearchDialog";

const SelectPromptWidget = ({
   id,
   value,
   onChange,
   disabled,
   token,
   logout,
   launch,
}) => {
   const { prompts, isLoading } = usePrompts(token, logout);
   const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);

   const handleOpenSearchDialog = () => {
      setIsSearchDialogOpen(true);
   };

   const handleCloseSearchDialog = () => {
      setIsSearchDialogOpen(false);
   };

   const handleSelectPrompt = (prompt) => {
      onChange(prompt.id);
      handleCloseSearchDialog();
   };

   const selectedPrompt = prompts.find((prompt) => prompt.id === value);

   return (
      <Box display="flex" alignItems="center" width="100%">
         <Button
            id={id}
            variant="outlined"
            onClick={handleOpenSearchDialog}
            disabled={disabled || isLoading}
            startIcon={<SearchIcon />}
            fullWidth
         >
            {selectedPrompt
               ? `${selectedPrompt.name} (${selectedPrompt.id})`
               : "Select a prompt"}
         </Button>
         <Tooltip title={value ? "Edit prompt..." : ""}>
            <span>
               <IconButton
                  onClick={() => launch(value)}
                  disabled={!value}
                  size="small"
                  sx={{ ml: 1 }}
               >
                  <TryIcon fontSize="small" />
               </IconButton>
            </span>
         </Tooltip>
         <PromptSearchDialog
            isOpen={isSearchDialogOpen}
            onClose={handleCloseSearchDialog}
            onSelect={handleSelectPrompt}
            token={token}
            logout={logout}
         />
      </Box>
   );
};

export default SelectPromptWidget;
