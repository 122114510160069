import React from "react";
import { Menu, MenuItem } from "@mui/material";

const GraphEditorNodeContextMenu = ({
   anchorPosition,
   isOpen,
   onClose,
   onDelete,
}) => {
   if (
      !anchorPosition ||
      typeof anchorPosition.left === "undefined" ||
      typeof anchorPosition.top === "undefined"
   ) {
      return null;
   }

   return (
      <Menu
         open={isOpen}
         onClose={onClose}
         anchorReference="anchorPosition"
         anchorPosition={anchorPosition}
         TransitionProps={{ unmountOnExit: true }}
      >
         <MenuItem onClick={onDelete}>Delete</MenuItem>
      </Menu>
   );
};

export default GraphEditorNodeContextMenu;
