import React, { useState, useCallback } from "react";
import {
   Modal,
   Box,
   Typography,
   Button,
   RadioGroup,
   FormControlLabel,
   Radio,
   LinearProgress,
   Alert,
   List,
   ListItemIcon,
   ListItem,
   ListItemText,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const GraphImportModal = ({
   open,
   onClose,
   token,
   logout,
   onImportComplete,
}) => {
   const [files, setFiles] = useState([]);
   const [importOption, setImportOption] = useState("create");
   const [isImporting, setIsImporting] = useState(false);
   const [progress, setProgress] = useState(0);
   const [error, setError] = useState(null);

   const onDrop = useCallback((acceptedFiles) => {
      setFiles(acceptedFiles);
   }, []);

   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
   });

   const handleImport = async () => {
      setIsImporting(true);
      setError(null);
      const importedGraphIds = [];

      for (let i = 0; i < files.length; i++) {
         const file = files[i];
         const json = await file.text();

         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph/import?import_method=${importOption}`,
               {
                  method: "POST",
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
                  body: json,
               }
            );

            if (!response.ok) {
               if (response.status === 401) {
                  logout();
                  return;
               }
               const errorData = await response.json();
               throw new Error(errorData.error || "Import failed");
            }

            const data = await response.json();
            importedGraphIds.push(data.id);
         } catch (error) {
            setError(error.message);
            break;
         }

         setProgress(((i + 1) / files.length) * 100);
      }

      setIsImporting(false);
      if (importedGraphIds.length > 0) {
         onImportComplete(importedGraphIds);
      }
   };

   return (
      <Modal open={open} onClose={onClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               width: 480,
               bgcolor: "background.paper",
               boxShadow: 24,
               p: 4,
            }}
         >
            <Typography variant="h6" component="h2" gutterBottom>
               Import Graphs
            </Typography>

            <Box
               {...getRootProps()}
               sx={{
                  border: "2px dashed gray",
                  p: 2,
                  mb: 2,
                  textAlign: "center",
                  cursor: "pointer",
               }}
            >
               <input {...getInputProps()} />
               {isDragActive ? (
                  <p>Drop Graph JSON files here ...</p>
               ) : (
                  <p>Drag Graph JSON Files Here or Click to Select</p>
               )}
            </Box>

            {files.length > 0 && (
               <List dense>
                  {files.map((file, index) => (
                     <ListItem key={index}>
                        <ListItemIcon>
                           <InsertDriveFileIcon />
                        </ListItemIcon>
                        <ListItemText primary={file.name} />
                     </ListItem>
                  ))}
               </List>
            )}

            <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
               Import Options
            </Typography>
            <RadioGroup
               value={importOption}
               onChange={(e) => setImportOption(e.target.value)}
            >
               {[
                  // {
                  //    value: "smart",
                  //    label: "Smart",
                  //    description:
                  //       "Attach to existing referenced content when it matches. Existing prompts & templates will " +
                  //       "be used IFF all fields match. New content will be created otherwise.",
                  // },
                  {
                     value: "create",
                     label: "Always Create",
                     description:
                        "Referenced content will be created as new content, new IDs will always be generated. " +
                        "This may result in data duplication. Use this option if you want to import prompt, template or" +
                        "schema updates and aren't worried about data duplication.",
                  },
                  // {
                  //    value: "ignore",
                  //    label: "Ignore",
                  //    description:
                  //       "Synchronized Sources Only: Ignore referenced content and use provided IDs only. This may result in un-linked content " +
                  //       "if data source is not synchronized, or an import error if IDs are not found.",
                  // },
                  // {
                  //    value: "replace",
                  //    label: "Overwrite",
                  //    description:
                  //       "Synchronized Sources Only: Replace existing content with new content when IDs match. For IDs that do not match, " +
                  //       "new content will be created. This will result in data loss if source data has not been synchronized!",
                  // },
               ].map((option) => (
                  <FormControlLabel
                     key={option.value}
                     value={option.value}
                     control={<Radio />}
                     label={
                        <Box>
                           <Typography variant="subtitle1">
                              {option.label}
                           </Typography>
                           <Typography
                              variant="body2"
                              sx={{
                                 fontSize: "0.8rem",
                                 color: "text.secondary",
                              }}
                           >
                              {option.description}
                           </Typography>
                        </Box>
                     }
                     sx={{ alignItems: "flex-start", mb: 2 }}
                  />
               ))}
            </RadioGroup>

            {isImporting && (
               <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{ my: 2 }}
               />
            )}

            {error && (
               <Alert severity="error" sx={{ my: 2 }}>
                  {error}
               </Alert>
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
               <Button onClick={onClose} sx={{ mr: 1 }}>
                  Cancel
               </Button>
               <Button
                  onClick={handleImport}
                  disabled={files.length === 0 || isImporting}
                  variant="contained"
               >
                  Import
               </Button>
            </Box>
         </Box>
      </Modal>
   );
};

export default GraphImportModal;
