import React, { useState } from "react";
import { Button, IconButton, Box, Tooltip } from "@mui/material";
import TryIcon from "@mui/icons-material/Try";
import SearchIcon from "@mui/icons-material/Search";
import { useGraphs } from "./useGraphs";
import GraphSearchDialog from "../../SearchDialogs/GraphSearchDialog";

const SelectGraphWidget = ({
   id,
   value,
   onChange,
   disabled,
   token,
   logout,
   launch,
}) => {
   const { graphs, isLoading } = useGraphs(token, logout);
   const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);

   const handleOpenSearchDialog = () => {
      setIsSearchDialogOpen(true);
   };

   const handleCloseSearchDialog = () => {
      setIsSearchDialogOpen(false);
   };

   const handleSelectGraph = (graph) => {
      onChange(graph.id);
      handleCloseSearchDialog();
   };

   const selectedGraph = graphs.find((graph) => graph.id === value);

   return (
      <Box display="flex" alignItems="center" width="100%">
         <Button
            id={id}
            variant="outlined"
            onClick={handleOpenSearchDialog}
            disabled={disabled || isLoading}
            startIcon={<SearchIcon />}
            fullWidth
         >
            {selectedGraph
               ? `${selectedGraph.name} (${selectedGraph.id})`
               : "Select a graph"}
         </Button>
         <Tooltip title={value ? "Edit graph..." : ""}>
            <span>
               <IconButton
                  onClick={() => launch(value)}
                  disabled={!value}
                  size="small"
                  sx={{ ml: 1 }}
               >
                  <TryIcon fontSize="small" />
               </IconButton>
            </span>
         </Tooltip>
         <GraphSearchDialog
            isOpen={isSearchDialogOpen}
            onClose={handleCloseSearchDialog}
            onSelect={handleSelectGraph}
            token={token}
            logout={logout}
         />
      </Box>
   );
};

export default SelectGraphWidget;
