import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import CheckIcon from "@mui/icons-material/Check";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import HourGlassBottomIcon from "@mui/icons-material/HourglassBottom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";

const getNodeStyle = (status) => {
   switch (status) {
      case 100: // RUN
      case 101: // RETRY_RUN
         return { background: "#FFF9C4", border: "2px solid #4CAF50" };
      case 200: // SUCCESS
         return { background: "#C8E6C9", border: "2px solid #4CAF50" };
      case 300: // FAILURE
      case 400: // ABORT
      case 500: // TIMEOUT
         return { background: "#FFCDD2", border: "2px solid #F44336" };
      case 1: // QUEUE
         return { background: "#E3F2FD", border: "2px solid #2196F3" };
      case 0: // PRESENT
      default:
         return { background: "#F5F5F5", border: "2px solid #9E9E9E" };
   }
};

const getStatusIcon = (status) => {
   switch (status) {
      case 100: // RUN
         return <PlayArrowIcon style={{ color: "rgb(88, 148, 94)" }} />;
      case 101: // RETRY_RUN
         return <ReplayIcon style={{ color: "rgb(88, 148, 94)" }} />;
      case 200: // SUCCESS
         return <CheckIcon style={{ color: "rgb(88, 148, 94)" }} />;
      case 300: // FAILURE
         return <ErrorOutlineIcon style={{ color: "rgb(177,80,81)" }} />;
      case 400: // ABORT
         return <CancelIcon style={{ color: "rgb(177,80,81)" }} />;
      case 500: // TIMEOUT
         return <HourGlassBottomIcon style={{ color: "rgb(177,80,81)" }} />;
      case 1: // QUEUE
         return <HourglassEmptyIcon style={{ color: "rgb(128, 128, 128)" }} />;
      case 0: // PRESENT
      default:
         return (
            <FiberManualRecordIcon style={{ color: "rgb(128, 128, 128)" }} />
         );
   }
};

export { getNodeStyle, getStatusIcon };
