import { useState, useCallback, useEffect } from "react";

let graphs = null;
let isLoading = false;
let lastFetchTime = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const useGraphs = (token, logout) => {
   const [localGraphs, setLocalGraphs] = useState(graphs);
   const [localIsLoading, setLocalIsLoading] = useState(isLoading);

   const fetchGraphs = useCallback(
      async (force = false) => {
         const now = Date.now();
         if (!force && isLoading) return;
         if (!force && graphs && now - lastFetchTime < CACHE_DURATION) return;

         isLoading = true;
         setLocalIsLoading(true);

         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph`,
               {
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            if (!response.ok) {
               console.error("fetchGraphs: ", response);
               if (response.status === 401) logout();
               return;
            }

            const data = await response.json();
            graphs = data;

            // sort graphs by name
            graphs.sort((a, b) => a.name.localeCompare(b.name));

            setLocalGraphs(data);
            lastFetchTime = now;
         } catch (error) {
            console.error("Error fetching graphs:", error);
         } finally {
            isLoading = false;
            setLocalIsLoading(false);
         }
      },
      [token, logout]
   );

   useEffect(() => {
      fetchGraphs();
   }, [fetchGraphs]);

   return { graphs: localGraphs, isLoading: localIsLoading, fetchGraphs };
};
