import { styled } from "@mui/material/styles";

const GraphExecutionLogReactFlowContainer = styled("div")(({ theme }) => ({
   width: "100%",
   height: "100%",
   backgroundColor: "rgb(30, 31, 34)",
   "& .react-flow__node": {
      background: "rgb(64, 64, 64)",
      border: "2px solid #4c4c4c",
      borderRadius: "10px",
      cursor: "default",
   },
   "& .react-flow__edge path": {
      stroke: "#888",
      strokeWidth: "2px",
   },
   "& .react-flow__controls": {
      backgroundColor: "rgb(88, 88, 88)",
      color: "#e0e0e0",
      borderRadius: "4px",
      "& button": {
         color: "#e0e0e0",
      },
   },
   "& .react-flow__minimap": {
      backgroundColor: "rgb(43, 45, 48)",
   },
   "& .react-flow__background": {
      fill: "rgb(43, 45, 48)",
   },
}));

export default GraphExecutionLogReactFlowContainer;
