import ReactFlow, {
   Background,
   Controls,
   MiniMap,
   useReactFlow,
} from "react-flow-renderer";
import React, { useCallback, useEffect, useRef, useState } from "react";
import GraphExecutionLogNodeContextMenu from "./GraphExecutionLogNodeContextMenu";

const GraphExecutionLogFlow = ({
   updateKey,
   nodes,
   edges,
   onNodesChange,
   onEdgesChange,
   onConnect,
   tabId,
   tabViewports,
   setTabViewports,
   onNodeClick,
   nodesDraggable,
   activeNodeId,
   bearerToken,
   logoutMethod,
   fetchAndAddGraphExecutionTab,
}) => {
   const { fitView, setViewport } = useReactFlow();
   const [contextMenu, setContextMenu] = useState({
      isOpen: false,
      position: { top: 0, left: 0 },
      nodeId: null,
   });

   useEffect(() => {
      if (tabViewports[tabId]) {
         setViewport(tabViewports[tabId]);
      } else {
         fitView();
      }
   }, [tabId, fitView, setViewport, tabViewports]);

   const onMoveEnd = useCallback(
      (event, viewport) => {
         setTabViewports((prev) => ({ ...prev, [tabId]: viewport }));
      },
      [tabId, setTabViewports]
   );

   const onNodeContextMenu = useCallback((event, node) => {
      event.preventDefault();
      event.stopPropagation();
      setContextMenu({
         isOpen: true,
         position: {
            left: event.clientX,
            top: event.clientY,
         },
         nodeId: node.id,
         graphDefNodeId: node.data.node_id,
         execGraphId: node.data.exec_graph,
      });
   }, []);

   const closeContextMenu = useCallback(() => {
      setContextMenu((prev) => ({ ...prev, isOpen: false }));
   }, []);

   const onReplayButton = useCallback(
      async (event) => {
         const cloned_graph_execution_id = contextMenu.execGraphId;
         const cloned_graph_execution_node_id = contextMenu.nodeId;
         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/log/graph/replay/${cloned_graph_execution_id}/${cloned_graph_execution_node_id}`,
               {
                  method: "POST",
                  headers: {
                     Authorization: `Bearer ${bearerToken}`,
                     "Content-Type": "application/json",
                  },
                  body: JSON.stringify({}), // TODO - param option for replay
               }
            );
            if (response.status === 200) {
               const data = await response.json();
               const graph_log_execution_id = data.cloned_graph_execution_id;
               fetchAndAddGraphExecutionTab(graph_log_execution_id);
            } else {
               console.error(
                  `/log/graph/replay/${cloned_graph_execution_id}/${cloned_graph_execution_node_id}`,
                  response.status
               );
               console.log(response.content);
               if (response.status === 401) logoutMethod();
            }
         } catch (error) {
            console.error("onReplayButton Error: ", error);
         }
         closeContextMenu();
      },
      [contextMenu, bearerToken, logoutMethod, fetchAndAddGraphExecutionTab]
   );

   // useEffect(() => {
   //    if (activeNodeId) {
   //       const node = nodes.find((n) => n.id === activeNodeId);
   //       if (node) {
   //          fitView({ padding: 0.2, duration: 800, nodes: [node] });
   //       }
   //    }
   // }, [activeNodeId, nodes, fitView]);

   return (
      <div style={{ width: "100%", height: "100%" }}>
         <ReactFlow
            key={updateKey}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onMoveEnd={onMoveEnd}
            onNodeClick={onNodeClick}
            nodesDraggable={nodesDraggable}
            onNodeContextMenu={onNodeContextMenu}
            fitView
         >
            <Background />
            <Controls />
            <MiniMap />
         </ReactFlow>

         <div>
            <GraphExecutionLogNodeContextMenu
               anchorPosition={contextMenu.position}
               isOpen={contextMenu.isOpen}
               onClose={closeContextMenu}
               onReplay={onReplayButton}
            />
         </div>
      </div>
   );
};

export default GraphExecutionLogFlow;
