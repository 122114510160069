import { useCallback, useEffect, useState } from "react";

let taskList = [];

const useTaskList = (token, logout) => {
   const [localTaskList, setLocalTaskList] = useState(taskList);

   const fetchTaskList = useCallback(async () => {
      if (taskList.length > 0) return;

      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/task`,
            {
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (response.status === 200) {
            const data = await response.json();
            taskList = data;

            taskList.sort((a, b) => a.function.localeCompare(b.function));
            setLocalTaskList(taskList);
         } else {
            console.error(`Fetch task list error: ${response.status}`);
            if (response.status === 401) {
               logout();
            }
         }
      } catch (error) {
         console.error("Fetch task list error:", error);
      }
   }, [token, logout]);

   useEffect(() => {
      fetchTaskList();
   }, [token, logout, fetchTaskList]);

   return localTaskList;
};

export default useTaskList;
