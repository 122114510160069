import React from "react";
import { IconButton, Toolbar as MuiToolbar, Tooltip } from "@mui/material";

const NodeEditorToolbar = ({ actions = [] }) => {
   return (
      <MuiToolbar
         variant="dense"
         style={{
            backgroundColor: "rgb(30,31,34)",
            padding: "0 16px",
            margin: 0,
            borderBottom: "1px solid rgb(57, 59, 64)",
            height: "25px",
            minHeight: "25px",
            paddingTop: "3px",
            paddingBottom: "3px",
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
            justifyContent: "center",
         }}
      >
         {actions.map((action, index) => (
            <Tooltip key={index} title={action.tooltip}>
               <span>
                  <IconButton
                     onClick={action.onClick}
                     size="small"
                     disabled={action.disabled}
                     disableRipple
                     style={{
                        marginLeft: "8px",
                        color:
                           action.selected && !action.disabled
                              ? action.color
                              : "rgb(180, 180, 180)",
                        cursor:
                           !action.image_only &&
                           !action.disabled &&
                           !action.selected
                              ? "pointer"
                              : "default",
                     }}
                  >
                     {action.icon}
                     {action.label && (
                        <span
                           style={{
                              fontSize: "11px",
                              marginLeft: "4px",
                              marginRight: "4px",
                           }}
                        >
                           {action.label.toUpperCase()}
                        </span>
                     )}
                  </IconButton>
               </span>
            </Tooltip>
         ))}
      </MuiToolbar>
   );
};

export default NodeEditorToolbar;
