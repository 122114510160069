import React, { useState, useCallback, useRef } from "react";
import { HookEditorToolbar } from "./HookEditorToolbar";
import {
   ThemeProvider,
   Typography,
   Accordion,
   AccordionSummary,
   AccordionDetails,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CircularProgress from "@mui/material/CircularProgress";
import {
   darkTheme,
   StyledForm,
   StyledTextField,
   StyledMonacoWrapper,
} from "./HookEditorPanelStyles";
import Editor from "@monaco-editor/react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationDialog from "../../Components/DeleteConfirmationDialog";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import SnackBar from "../../Components/SnackBar";
import { useHookServices } from "../../services/useHookServices";
import SelectGraphWidget from "../GraphEditorPanel/SelectGraphWidget";

const transformHookData = (hook) => {
   if (!hook) return;
   return {
      ...hook,
      associated_graph: hook.associated_graph ? hook.associated_graph.id : null,
      input_schema_id: hook.input_schema ? hook.input_schema?.id : null,
      output_schema_id: hook.output_schema ? hook.output_schema?.id : null,
   };
};

const isJSONValid = (jsonString) => {
   try {
      JSON.parse(jsonString);
      return true;
   } catch (error) {
      return false;
   }
};

export const HookEditorPanel = ({
   activeTabIndex,
   index,
   setTabs,
   token,
   hook,
   logout,
   handleTabClose,
   setHookListNeedsRefresh,
   fetchAndAddGraphEditTab,
}) => {
   const inputSchemaEditorRef = useRef(null);
   const outputSchemaEditorRef = useRef(null);
   const [hookValues, setHookValues] = useState(transformHookData(hook));
   const [isDirty, setIsDirty] = useState(false);
   const [openAlert, setOpenAlert] = useState(false);
   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
   const {
      createHook,
      deleteHook,
      loading,
      fetchHookById,
      handleCloseSnackbar,
      handleSnackBar,
      snackbarMessage,
      snackbarOpen,
      snackbarSeverity,
      apiRequest,
   } = useHookServices();

   const updateIsDirty = useCallback(
      (value) => {
         setIsDirty(value);
         setTabs((prevTabs) =>
            prevTabs.map((t, i) =>
               i === index ? { ...t, hasUnsavedChanges: value } : t
            )
         );
      },
      [setIsDirty, setTabs, index]
   );

   const handleSaveSchema = useCallback(
      async (editorRef, schemaEditorKey) => {
         const editorValue = !editorRef.current.getValue().length
            ? "{}"
            : editorRef.current.getValue();
         if (!isJSONValid(editorValue)) {
            handleSnackBar(`Invalid object`, "error");
            return;
         }

         const method = hookValues[schemaEditorKey] ? "PUT" : "POST";
         const url = hookValues[schemaEditorKey]
            ? `schema/${hookValues[schemaEditorKey]}`
            : "schema";

         const requestBody = {
            name: `Schema for ${hookValues.name}`,
            schema: editorValue,
            schema_format: "json",
         };

         try {
            const response = await apiRequest(url, method, requestBody);
            if (!response.ok) {
               handleSnackBar(`Error:${response.message}`, "error");
               throw new Error(
                  response.message || `Error: ${response.statusText}`
               );
            }
            const data = await response.json();
            if (data) {
               updateIsDirty(true);
               setHookValues((prevValues) => ({
                  ...prevValues,
                  [schemaEditorKey]: data.id,
               }));
               handleSnackBar(
                  `${hookValues[schemaEditorKey] ? "Schema updated" : "Schema created"}`,
                  "success"
               );
            }
         } catch (err) {
            handleSnackBar(`Error creating schema:${err}`, "error");
            console.error("Error creating schema:", err);
         }
      },
      [hookValues, updateIsDirty, apiRequest]
   );

   const handleRename = useCallback(
      (newName) => {
         setTabs((prevTabs) =>
            prevTabs.map((t, i) =>
               i === index
                  ? {
                       ...t,
                       name: newName,
                    }
                  : t
            )
         );
      },
      [setTabs, index]
   );

   const handleSave = useCallback(
      async (e) => {
         e.preventDefault();
         if (!hookValues && !hookValues.name.length) return;
         const method = hookValues.uuid ? "PUT" : "POST";
         const url = hookValues.uuid ? `hook/${hookValues.uuid}` : "hook";

         const requestBody = {
            name: hookValues.name,
            description: hookValues.description,
            input_schema_id: hookValues?.input_schema_id || null,
            output_schema_id: hookValues?.output_schema_id || null,
            associated_graph_id: hookValues?.associated_graph || null,
         };

         try {
            const data = await createHook(url, method, requestBody);
            if (data) {
               updateIsDirty(false);
               const msgText = hookValues.uuid
                  ? "Hook updated"
                  : "Hook created";
               handleSnackBar(`${msgText}`, "success");
               if (!hookValues.uuid) {
                  const hookData = await fetchHookById(hookValues.uuid || data);
                  setTabs((prevTabs) =>
                     prevTabs.map((t, i) =>
                        i === index
                           ? {
                                ...t,
                                id: `hook-${data}`,
                                hook: hookData,
                             }
                           : t
                     )
                  );
               }
               setHookListNeedsRefresh(true);
            }
         } catch (err) {
            console.error("Error creating schema:", err);
         }
      },
      [hookValues, fetchHookById, setHookValues]
   );

   const handleDelete = useCallback(async () => {
      try {
         const response = await deleteHook(hookValues.uuid);
         if (response) {
            setHookListNeedsRefresh(true);
            handleTabClose(activeTabIndex);
            handleSnackBar("Hook successfully deleted", "success");
         }
      } catch (error) {
         console.error("Delete hook error:", error);
      }
   }, [deleteHook, handleTabClose, activeTabIndex]);

   const handleChange = (field, value) => {
      if (field === "name") {
         handleRename(value);
      }
      updateIsDirty(true);
      setHookValues((prevData) => ({
         ...prevData,
         [field]: value,
      }));
   };

   const leftToolbarActions = [
      {
         tooltip: "Delete Hook",
         onClick: () => setIsDeleteDialogOpen(true),
         icon: <DeleteIcon fontSize="small" />,
         disabled: isDirty,
         color: "rgb(161,91,88)",
      },
   ];

   const toolbarActions = [
      {
         tooltip: "Run",
         icon: <PlayArrowIcon fontSize="small" />,
         disabled: true,
         color: "rgb(89, 149, 95)",
      },
      {
         tooltip: "Save Changes",
         onClick: handleSave,
         icon: loading ? (
            <CircularProgress size={20} color="inherit" />
         ) : (
            <SaveIcon fontSize="small" />
         ),
         disabled: !isDirty || loading || !hookValues.name,
         color: "rgb(89, 149, 95)",
      },
   ];
   return (
      <div style={{ width: "100%", height: "100%" }}>
         <ThemeProvider theme={darkTheme}>
            <div
               role="tabpanel"
               hidden={activeTabIndex !== index}
               id={`simple-tabpanel-${index}`}
               aria-labelledby={`simple-tab-${index}`}
               style={{
                  width: "100%",
                  height: "calc(100%)",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               {activeTabIndex === index && (
                  <>
                     <HookEditorToolbar
                        leftActions={leftToolbarActions}
                        actions={toolbarActions}
                     />
                     {openAlert && (
                        <Alert
                           severity="warning"
                           onClose={() => {
                              setOpenAlert(false);
                           }}
                        >
                           Please create the graph first
                        </Alert>
                     )}
                     <SnackBar
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                        open={snackbarOpen}
                        onClose={handleCloseSnackbar}
                     />
                     <StyledForm>
                        <StyledTextField
                           id="hook-name-input"
                           label="Hook name"
                           type="text"
                           name="name"
                           value={hookValues.name || ""}
                           onChange={(e) =>
                              handleChange("name", e.target.value)
                           }
                           fullWidth
                           required
                        />
                        <StyledTextField
                           id="hook-description"
                           label="Hook description"
                           type="text"
                           defaultValue={hookValues.description}
                           onChange={(e) =>
                              handleChange("description", e.target.value)
                           }
                           multiline
                           minRows={4}
                           maxRows={20}
                           fullWidth
                        />
                        <SelectGraphWidget
                           id="associated-graph"
                           value={hookValues?.associated_graph}
                           token={token}
                           logout={logout}
                           launch={fetchAndAddGraphEditTab}
                           onChange={(value) => {
                              handleChange("associated_graph", value);
                           }}
                        />
                        <Accordion>
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                           >
                              I/O Schemas
                           </AccordionSummary>
                           <AccordionDetails>
                              <StyledMonacoWrapper>
                                 <>
                                    <Stack
                                       direction="row"
                                       spacing={2}
                                       marginBottom={"10px"}
                                       justifyContent={"space-between"}
                                    >
                                       <Typography
                                          variant="subtitle2"
                                          style={{
                                             fontSize: "16px",
                                          }}
                                       >
                                          Input schema
                                       </Typography>
                                       <Button
                                          variant="outlined"
                                          sx={{
                                             textTransform: "capitalize",
                                             fontSize: "16px",
                                             padding: "0 5px",
                                          }}
                                          onClick={() =>
                                             handleSaveSchema(
                                                inputSchemaEditorRef,
                                                "input_schema_id"
                                             )
                                          }
                                       >
                                          {hookValues.input_schema_id
                                             ? "Update Input Schema"
                                             : "Create Input Schema"}
                                       </Button>
                                    </Stack>

                                    <Editor
                                       language="json"
                                       value={
                                          hookValues.input_schema?.schema &&
                                          isJSONValid(
                                             hookValues.input_schema?.schema
                                          )
                                             ? JSON.stringify(
                                                  JSON.parse(
                                                     hookValues.input_schema
                                                        ?.schema
                                                  ),
                                                  null,
                                                  2
                                               )
                                             : "{}"
                                       }
                                       name="input_schema"
                                       height="200px"
                                       onMount={(editor) =>
                                          (inputSchemaEditorRef.current =
                                             editor)
                                       }
                                       theme="vs-dark"
                                       options={{
                                          minimap: { enabled: false },
                                          scrollBeyondLastLine: false,
                                          fontSize: 14,
                                          readOnly: false,
                                       }}
                                    />
                                 </>
                              </StyledMonacoWrapper>
                              <StyledMonacoWrapper>
                                 <>
                                    <Stack
                                       direction="row"
                                       spacing={2}
                                       marginBottom={"10px"}
                                       marginTop={"10px"}
                                       justifyContent={"space-between"}
                                    >
                                       <Typography
                                          variant="subtitle2"
                                          style={{
                                             fontSize: "16px",
                                          }}
                                       >
                                          Output schema
                                       </Typography>
                                       <Button
                                          variant="outlined"
                                          sx={{
                                             textTransform: "capitalize",
                                             fontSize: "16px",
                                             padding: "0 5px",
                                          }}
                                          onClick={() =>
                                             handleSaveSchema(
                                                outputSchemaEditorRef,
                                                "output_schema_id"
                                             )
                                          }
                                       >
                                          {hookValues.output_schema_id
                                             ? "Update Output Schema"
                                             : "Create Output Schema"}
                                       </Button>
                                    </Stack>
                                    <Editor
                                       language="json"
                                       value={
                                          hookValues.output_schema?.schema &&
                                          isJSONValid(
                                             hookValues.output_schema?.schema
                                          )
                                             ? JSON.stringify(
                                                  JSON.parse(
                                                     hookValues.output_schema
                                                        ?.schema
                                                  ),
                                                  null,
                                                  2
                                               )
                                             : "{}"
                                       }
                                       height="200px"
                                       name="output_schema"
                                       onMount={(editor) =>
                                          (outputSchemaEditorRef.current =
                                             editor)
                                       }
                                       theme="vs-dark"
                                       options={{
                                          minimap: { enabled: false },
                                          scrollBeyondLastLine: false,
                                          fontSize: 14,
                                          readOnly: false,
                                       }}
                                    />
                                 </>
                              </StyledMonacoWrapper>
                           </AccordionDetails>
                        </Accordion>
                     </StyledForm>
                  </>
               )}
            </div>
         </ThemeProvider>

         <DeleteConfirmationDialog
            open={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={handleDelete}
            objectName={"Hook"}
         />
      </div>
   );
};
