import React, { useCallback, useEffect, useState } from "react";
import {
   BrowserRouter as Router,
   Routes,
   Route,
   useLocation,
   useNavigate,
} from "react-router-dom";

import "../styles/styles.css";
import "react18-json-view/src/style.css";
import "../styles/customScrollBar.css";
import "reactflow/dist/base.css";
import str from "../utils/string";
import { getNodeStyle, getStatusIcon } from "../utils/styles";
import { AuthProvider, useAuth } from "./Context/AuthContext";
import LoginModal from "./Modals/LoginModal";
import AppMenuBar from "./AppMenuBar";
import AppSideViewContainer from "./AppSideViewContainer";
import AppTabPanelContainer from "./AppTabPanelContainer";
import computeGraphLayout from "../utils/graphLayout";
import NodeLabel from "./TabPanels/GraphEditorPanel/NodeLabel";
import PromptSearchDialog from "./SearchDialogs/PromptSearchDialog";
import GraphSearchDialog from "./SearchDialogs/GraphSearchDialog";

const AppContent = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const { isAuthenticated, accessToken, refreshAccessToken, logout } =
      useAuth();

   // All state hooks
   const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(true);
   const [activePanel, setActivePanel] = useState("edit");
   const [tabs, setTabs] = useState([]);
   const [activeTabIndex, setActiveTabIndex] = useState(-1);
   const [isInitialized, setIsInitialized] = useState(false);
   const [routeHandled, setRouteHandled] = useState(false);
   const [graphListNeedsRefresh, setGraphListNeedsRefresh] = useState(false);
   const [promptListNeedsRefresh, setPromptListNeedsRefresh] = useState(false);
   const [hookListNeedsRefresh, setHookListNeedsRefresh] = useState(false);
   const [isPromptSearchDialogOpen, setIsPromptSearchDialogOpen] =
      useState(false);
   const [isGraphSearchDialogOpen, setIsGraphSearchDialogOpen] =
      useState(false);

   // Dialog handlers
   const openPromptSearchDialog = useCallback(
      () => setIsPromptSearchDialogOpen(true),
      []
   );
   const closePromptSearchDialog = useCallback(
      () => setIsPromptSearchDialogOpen(false),
      []
   );
   const openGraphSearchDialog = useCallback(
      () => setIsGraphSearchDialogOpen(true),
      []
   );
   const closeGraphSearchDialog = useCallback(
      () => setIsGraphSearchDialogOpen(false),
      []
   );

   const handleGraphRoute = async (graphId) => {
      const existingTabIndex = tabs.findIndex(
         (tab) => tab.id === `edit-${graphId}`
      );
      if (existingTabIndex !== -1) {
         setActiveTabIndex(existingTabIndex);
      } else {
         await fetchAndAddGraphEditTab(graphId);
      }
   };

   const handleGraphExecutionRoute = async (graphExecId) => {
      const existingTabIndex = tabs.findIndex(
         (tab) => tab.id === `exec-${graphExecId}`
      );
      if (existingTabIndex !== -1) {
         setActiveTabIndex(existingTabIndex);
      } else {
         await fetchAndAddGraphExecutionTab(graphExecId);
      }
   };

   const handlePromptRoute = async (promptId) => {
      const existingTabIndex = tabs.findIndex(
         (tab) => tab.id === `prompt-${promptId}`
      );
      if (existingTabIndex !== -1) {
         setActiveTabIndex(existingTabIndex);
      } else {
         await fetchAndAddPromptEditTab(promptId);
      }
   };

   const handleHookRoute = async (hookId) => {
      const existingTabIndex = tabs.findIndex(
         (tab) => tab.id === `hook-${hookId}`
      );
      if (existingTabIndex !== -1) {
         setActiveTabIndex(existingTabIndex);
      } else {
         // Not implemented,
         // await fetchAndAddHookEditTab(hookId);
      }
   };

   const fetchGraphExecutionLog = useCallback(
      async (graph_exec_id) => {
         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/log/graph/${graph_exec_id}`,
               {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            if (!response.ok) {
               console.error(`fetchGraphExecutionLog: ${response}`);
               if (response.status === 401) {
                  logout();
               }
               return;
            }

            const data = await response.json();
            const newNodes = data.nodes.map((node) => ({
               id: str(node.id),
               data: {
                  ...node,
                  label: (
                     <div style={{ padding: "10px", fontSize: "12px" }}>
                        <div
                           style={{
                              fontWeight: "bold",
                              marginBottom: "5px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                           }}
                        >
                           {node.name}
                        </div>
                        <div
                           style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                           }}
                        >
                           {node.function_name}
                        </div>
                        {node.run_attempts > 1 && (
                           <div
                              style={{
                                 position: "absolute",
                                 bottom: "5px",
                                 left: "5px",
                                 width: "20px",
                                 height: "20px",
                                 borderRadius: "50%",
                                 backgroundColor: "#757575",
                                 color: "white",
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 fontSize: "10px",
                              }}
                           >
                              {node.run_attempts}
                           </div>
                        )}
                        <div
                           style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                           }}
                        >
                           {getStatusIcon(node.status)}
                        </div>
                     </div>
                  ),
               },
               position: { x: 0, y: 0 },
               style: {
                  width: 180,
                  height: 80,
                  ...getNodeStyle(node.status),
               },
            }));
            const newEdges = data.edges.map((edge) => ({
               id: str(edge.id),
               source: edge.source_exec_node
                  ? str(edge.source_exec_node)
                  : null,
               target: edge.target_exec_node
                  ? str(edge.target_exec_node)
                  : null,
            }));

            return { nodes: newNodes, edges: newEdges, data: data };
         } catch (error) {
            console.error("fetchGraphExecutionLog: ", error);
            return { nodes: [], edges: [], data: {} };
         }
      },
      [accessToken, logout]
   );

   const fetchAndAddGraphExecutionTab = useCallback(
      async (graph_exec_id) => {
         const newTabId = `exec-${graph_exec_id}`;

         const existingTabIndex = tabs.findIndex((tab) => tab.id === newTabId);
         if (existingTabIndex !== -1) {
            setActiveTabIndex(existingTabIndex);
            return;
         }

         try {
            const graph = await fetchGraphExecutionLog(graph_exec_id);
            const newNodes = graph.nodes;
            const newEdges = graph.edges;
            const data = graph.data;

            const layoutComputedElements = computeGraphLayout(
               newNodes,
               newEdges
            );

            const newTabObject = {
               id: newTabId,
               name: data.exec_graph.name,
               nodes: layoutComputedElements.nodes,
               edges: layoutComputedElements.edges,
               exec_graph: data.exec_graph,
               nodePanels: [],
               activeNodePanelIndex: 0,
               isPanelOpen: false,
               tabType: "GraphExecutionLog",
            };

            setTabs((prevTabs) => {
               const newTabs = [...prevTabs, newTabObject];
               setActiveTabIndex(newTabs.length - 1);
               return newTabs;
            });
         } catch (error) {
            console.error("Error fetching graph data:", error);
         }
      },
      [tabs, setTabs, setActiveTabIndex, fetchGraphExecutionLog]
   );

   const fetchAndAddGraphEditTab = useCallback(
      async (graphId) => {
         const newTabId = `edit-${graphId}`;

         const existingTabIndex = tabs.findIndex((tab) => tab.id === newTabId);
         if (existingTabIndex !== -1) {
            setActiveTabIndex(existingTabIndex);
            return;
         }

         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph/${graphId}?fmt=json_log`,
               {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            if (!response.ok) {
               console.error("fetchAndAddGraphEditTab: ", response.status);
               if (response.status === 401) {
                  logout();
               }
            }

            const data = await response.json();

            // Process nodes and edges
            const newNodes = data.nodes.map((node) => ({
               id: str(node.id),
               data: {
                  ...node,
                  label: (
                     <NodeLabel name={node.name} function={node.function} />
                  ),
               },
               position: { x: 0, y: 0 },
               style: {
                  width: 180,
                  height: 80,
               },
            }));

            const newEdges = data.edges.map((edge) => ({
               id: str(edge.id),
               uuid: edge.uuid,
               source: str(edge.source_node),
               target: str(edge.target_node),
               // style: { stroke: "#f6ab6c" },
            }));

            const layoutComputedElements = computeGraphLayout(
               newNodes,
               newEdges
            );

            const newTabObject = {
               id: newTabId,
               name: data.graph.name,
               nodes: layoutComputedElements.nodes,
               edges: layoutComputedElements.edges,
               graph: data,
               nodePanels: [],
               activeNodePanelIndex: 0,
               isPanelOpen: false,
               tabType: "GraphEditor",
            };

            setTabs((prevTabs) => {
               const newTabs = [...prevTabs, newTabObject];
               setActiveTabIndex(newTabs.length - 1);
               return newTabs;
            });
         } catch (error) {
            console.error("Error fetching graph data:", error);
         }
      },
      [tabs, setTabs, setActiveTabIndex, accessToken]
   );

   const fetchAndAddPromptEditTab = useCallback(
      async (promptId) => {
         const newPromptId = `prompt-${promptId}`;

         const existingTabIndex = tabs.findIndex(
            (tab) => tab.id === newPromptId
         );
         if (existingTabIndex !== -1) {
            setActiveTabIndex(existingTabIndex);
            return;
         }

         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/prompt/${promptId}`,
               {
                  headers: {
                     Authorization: `Bearer ${accessToken}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            if (!response.ok) {
               console.error("fetchPrompt: ", response);
               if (response.status === 401) logout();
               return;
            }

            const promptData = await response.json();
            if (!promptData) return;

            const newTabObject = {
               id: newPromptId,
               name: promptData.name,
               nodes: [],
               edges: [],
               nodePanels: [],
               prompt: promptData,
               activeNodePanelIndex: 0,
               isPanelOpen: false,
               tabType: "PromptEditor",
            };

            setTabs((prevTabs) => {
               const newTabs = [...prevTabs, newTabObject];
               setActiveTabIndex(newTabs.length - 1);
               return newTabs;
            });
         } catch (error) {
            console.error("Error fetching prompt data:", error);
         }
      },
      [tabs, setTabs, setActiveTabIndex, accessToken]
   );

   // Token refresh effect
   useEffect(() => {
      let intervalId;
      if (isAuthenticated) {
         intervalId = setInterval(refreshAccessToken, 10 * 60 * 1000);
      }
      return () => clearInterval(intervalId);
   }, [isAuthenticated, refreshAccessToken]);

   // Restore Session Tabs
   useEffect(() => {
      const restoreTabs = async () => {
         if (!isAuthenticated || isInitialized) return;

         try {
            const storedTabsData = sessionStorage.getItem("userTabs");
            if (storedTabsData) {
               const { tabs: storedTabs, activeTabIndex: storedActiveIndex } =
                  JSON.parse(storedTabsData);
               const restoredTabIds = new Set(tabs.map((t) => t.id));

               for (const tab of storedTabs) {
                  const tabId = `${tab.tabType === "GraphEditor" ? "edit" : tab.tabType.toLowerCase().replace("editor", "")}-${tab.resourceId}`;
                  if (!restoredTabIds.has(tabId)) {
                     restoredTabIds.add(tabId);
                     switch (tab.tabType) {
                        case "GraphEditor":
                           await fetchAndAddGraphEditTab(tab.resourceId);
                           break;
                        case "GraphExecutionLog":
                           await fetchAndAddGraphExecutionTab(tab.resourceId);
                           break;
                        case "PromptEditor":
                           await fetchAndAddPromptEditTab(tab.resourceId);
                           break;
                     }
                  }
               }
            }
         } catch (error) {
            console.error("Error restoring tabs:", error);
         } finally {
            setIsInitialized(true);
         }
      };

      restoreTabs();
   }, [isAuthenticated, isInitialized]); // Only run this when auth state changes

   // Route Handling
   useEffect(() => {
      const handleRoute = async () => {
         if (!isInitialized || !isAuthenticated || routeHandled) return;
         // console.log("Handling route:", location.pathname);

         const path = location.pathname;
         const match = path.match(/^\/(graph|exec|prompt|hook)\/(\w+)/);

         if (match) {
            const [, type, id] = match;
            const tabId = `${type === "graph" ? "edit" : type}-${id}`;
            const existingTabIndex = tabs.findIndex((tab) => tab.id === tabId);

            if (existingTabIndex !== -1) {
               setActiveTabIndex(existingTabIndex);
            } else {
               switch (type) {
                  case "graph":
                     await handleGraphRoute(id);
                     break;
                  case "exec":
                     await handleGraphExecutionRoute(id);
                     break;
                  case "prompt":
                     await handlePromptRoute(id);
                     break;
                  case "hook":
                     await handleHookRoute(id);
                     break;
               }
            }
         }
         setRouteHandled(true);
      };

      handleRoute();
   }, [isInitialized, isAuthenticated, location.pathname, routeHandled]); // Remove tabs dependency

   // Reset routeHandled when location changes
   useEffect(() => {
      setRouteHandled(false);
   }, [location.pathname]);

   // Tab saving effect
   useEffect(() => {
      if (isInitialized && tabs.length >= 0) {
         const tabsToStore = tabs.map((tab) => ({
            id: tab.id,
            name: tab.name,
            tabType: tab.tabType,
            resourceId: tab.id.split("-")[1],
         }));

         sessionStorage.setItem(
            "userTabs",
            JSON.stringify({
               tabs: tabsToStore,
               activeTabIndex,
            })
         );
      }
   }, [tabs, activeTabIndex, isInitialized]);

   if (!isAuthenticated) {
      return null;
   }

   if (!isAuthenticated) {
      return null; // Or a loading spinner
   }

   return (
      <div
         style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            overflow: "hidden",
            backgroundColor: "rgb(43, 45, 48)",
         }}
      >
         <AppMenuBar
            activePanel={activePanel}
            setActivePanel={setActivePanel}
            isLeftPanelOpen={isLeftPanelOpen}
            setIsLeftPanelOpen={setIsLeftPanelOpen}
            accessToken={accessToken}
            onLogout={logout}
         />

         <AppSideViewContainer
            activePanel={activePanel}
            isLeftPanelOpen={isLeftPanelOpen}
            setActiveTabIndex={setActiveTabIndex}
            tabs={tabs}
            setTabs={setTabs}
            fetchGraphExecutionLog={fetchGraphExecutionLog}
            token={accessToken}
            logout={logout}
            isAuthenticated={isAuthenticated}
            graphListNeedsRefresh={graphListNeedsRefresh}
            setGraphListNeedsRefresh={setGraphListNeedsRefresh}
            promptListNeedsRefresh={promptListNeedsRefresh}
            setPromptListNeedsRefresh={setPromptListNeedsRefresh}
            hookListNeedsRefresh={hookListNeedsRefresh}
            setHookListNeedsRefresh={setHookListNeedsRefresh}
            fetchAndAddPromptEditTab={fetchAndAddPromptEditTab}
            openPromptSearchDialog={openPromptSearchDialog}
            openGraphSearchDialog={openGraphSearchDialog}
         />

         <Routes>
            <Route
               path="/*"
               element={
                  <AppTabPanelContainer
                     fetchGraphExecutionLog={fetchGraphExecutionLog}
                     fetchAndAddGraphExecutionTab={fetchAndAddGraphExecutionTab}
                     fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
                     activeTabIndex={activeTabIndex}
                     setActiveTabIndex={setActiveTabIndex}
                     isInitialized={isInitialized}
                     tabs={tabs}
                     setTabs={setTabs}
                     token={accessToken}
                     logout={logout}
                     isAuthenticated={isAuthenticated}
                     setGraphListNeedsRefresh={setGraphListNeedsRefresh}
                     setPromptListNeedsRefresh={setPromptListNeedsRefresh}
                     setHookListNeedsRefresh={setHookListNeedsRefresh}
                     fetchAndAddPromptEditTab={fetchAndAddPromptEditTab}
                     navigate={navigate}
                  />
               }
            />
         </Routes>

         <PromptSearchDialog
            isOpen={isPromptSearchDialogOpen}
            onClose={closePromptSearchDialog}
            token={accessToken}
            logout={logout}
            fetchAndAddPromptEditTab={fetchAndAddPromptEditTab}
            onSelect={(prompt) => {
               fetchAndAddPromptEditTab(prompt.id);
            }}
         />
         <GraphSearchDialog
            isOpen={isGraphSearchDialogOpen}
            onClose={closeGraphSearchDialog}
            token={accessToken}
            logout={logout}
            fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
            onSelect={(graph) => {
               fetchAndAddGraphEditTab(graph.id);
            }}
         />
      </div>
   );
};

const ProtectedRoute = ({ children }) => {
   const { isAuthenticated, isLoading } = useAuth();
   const [showLoginModal, setShowLoginModal] = React.useState(!isAuthenticated);
   const navigate = useNavigate();
   const location = useLocation();

   const handleLoginSuccess = () => {
      setShowLoginModal(false);
      // Redirect to the current path after successful login
      navigate(location.pathname);
   };

   if (isLoading) {
      return <div>Loading...</div>; // Or a loading spinner
   }

   return (
      <>
         {children}
         {!isAuthenticated && (
            <LoginModal
               open={showLoginModal}
               onClose={() => setShowLoginModal(false)}
               onLoginSuccess={handleLoginSuccess}
            />
         )}
      </>
   );
};

const App = () => (
   <AuthProvider>
      <Router>
         <Routes>
            <Route
               path="/*"
               element={
                  <ProtectedRoute>
                     <AppContent />
                  </ProtectedRoute>
               }
            />
         </Routes>
      </Router>
   </AuthProvider>
);
export default App;
