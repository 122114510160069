import React, { useCallback } from "react";
import { Resizable } from "re-resizable";
import { ThemeProvider } from "@mui/material";
import GraphEditorSubPanelTabs from "./GraphEditorSubPanelTabs";
import useTaskList from "./useTaskList";
import darkTheme from "./NodeEditorTheme";
import GraphEditorSubPanel from "./GraphEditorSubPanel";

const GraphEditorSubPanelContainer = ({
   panels,
   activeIndex,
   onTabChange,
   onTabClose,
   tabId,
   height,
   onHeightChange,
   onSaveNode,
   onReorderPanels,
   token,
   logout,
   fetchAndAddPromptEditTab,
   fetchAndAddGraphEditTab,
}) => {
   const taskList = useTaskList(token, logout);

   const handleReorderTabs = useCallback(
      (fromIndex, toIndex) => {
         onReorderPanels(fromIndex, toIndex);
      },
      [onReorderPanels]
   );

   return (
      <ThemeProvider theme={darkTheme}>
         <Resizable
            defaultSize={{
               width: "100%",
               height: height || "40%",
            }}
            minHeight={100}
            maxHeight="80%"
            enable={{
               top: true,
               right: false,
               bottom: false,
               left: false,
               topRight: false,
               bottomRight: false,
               bottomLeft: false,
               topLeft: false,
            }}
            handleStyles={{
               top: {
                  height: "10px",
                  top: 0,
                  cursor: "ns-resize",
               },
            }}
            onResizeStop={(e, direction, ref, _) => {
               onHeightChange(tabId, ref.style.height);
            }}
         >
            <GraphEditorSubPanelTabs
               panels={panels}
               activeIndex={activeIndex}
               onTabChange={onTabChange}
               onTabClose={onTabClose}
               onReorderTabs={handleReorderTabs}
            />
            {panels.map((panel, index) => (
               <GraphEditorSubPanel
                  key={panel.id}
                  isOpen={activeIndex === index}
                  taskList={taskList}
                  token={token}
                  logout={logout}
                  fetchAndAddPromptEditTab={fetchAndAddPromptEditTab}
                  fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
                  onSaveNode={onSaveNode}
                  panel={panel}
               />
            ))}
         </Resizable>
      </ThemeProvider>
   );
};

export default GraphEditorSubPanelContainer;
