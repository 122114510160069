import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const RenameGraphDialog = ({ open, onClose, onRename, currentName }) => {
   const [newName, setNewName] = useState(currentName);

   const handleRename = () => {
      onRename(newName);
      onClose();
   };

   return (
      <Dialog open={open} onClose={onClose}>
         <DialogTitle>Rename Graph</DialogTitle>
         <DialogContent>
            <TextField
               autoFocus
               margin="dense"
               label="Graph Name"
               type="text"
               fullWidth
               value={newName}
               onChange={(e) => setNewName(e.target.value)}
            />
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleRename}>Rename</Button>
         </DialogActions>
      </Dialog>
   );
};

export default RenameGraphDialog;
