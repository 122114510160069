import React, { useCallback, useEffect, useState } from "react";
import { Resizable } from "re-resizable";

import GraphExecutionLogSidePanel from "./SidePanels/GraphExecutionLogSidePanel";
import GraphEditorSidePanel from "./SidePanels/GraphEditorSidePanel";
import PromptSidePanel from "./SidePanels/PromptSidePanel";
import GraphExecutionHooks from "./SidePanels/GraphExecutionHooks";
import str from "../utils/string";
import NodeLabel from "./TabPanels/GraphEditorPanel/NodeLabel";
import computeGraphLayout from "../utils/graphLayout";

const AppSideViewContainer = ({
   activePanel,
   isLeftPanelOpen,
   fetchGraphExecutionLog,
   setActiveTabIndex,
   tabs,
   setTabs,
   token,
   logout,
   isAuthenticated,
   graphListNeedsRefresh,
   setGraphListNeedsRefresh,
   promptListNeedsRefresh,
   setPromptListNeedsRefresh,
   hookListNeedsRefresh,
   setHookListNeedsRefresh,
   fetchAndAddPromptEditTab,
   openPromptSearchDialog,
   openGraphSearchDialog,
}) => {
   const [logEntries, setLogEntries] = useState([]);
   const [graphList, setGraphList] = useState([]);
   const [isPromptListRefreshing, setIsPromptListRefreshing] = useState(false);
   const [isHookListRefreshing, setIsHookListRefreshing] = useState(false);

   const fetchGraphList = useCallback(async () => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph`,
            {
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (!response.ok) {
            console.error("fetchGraphList: ", response);
            if (response.status === 401) logout();
            return;
         }

         const data = await response.json();
         setGraphList(data);
      } catch (error) {
         console.error("Error fetching graph list:", error);
      }
   }, [token, logout]);

   const fetchLogEntries = useCallback(async () => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/log/graph`,
            {
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (response.status === 401 || !response.ok) {
            console.error("fetchLogEntries: ", response.status);
            logout();
            return;
         }

         const data = await response.json();
         setLogEntries(data);
      } catch (error) {
         console.error("Error fetching log entries:", error);
      }
   }, [token, logout]);

   const fetchAndAddGraphEditTab = useCallback(
      async (graphId) => {
         const newTabId = `edit-${graphId}`;

         const existingTabIndex = tabs.findIndex((tab) => tab.id === newTabId);
         if (existingTabIndex !== -1) {
            setActiveTabIndex(existingTabIndex);
            return;
         }

         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph/${graphId}?fmt=json_log`,
               {
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            if (!response.ok) {
               console.error("fetchAndAddGraphEditTab: ", response);

               if (response.status === 401) {
                  logout();
               }
               return;
            }

            const data = await response.json();

            // Process nodes and edges
            const newNodes = data.nodes.map((node) => ({
               id: str(node.id),
               data: {
                  ...node,
                  label: (
                     <NodeLabel name={node.name} function={node.function} />
                  ),
               },
               position: { x: 0, y: 0 },
               style: {
                  width: 180,
                  height: 80,
               },
            }));

            const newEdges = data.edges.map((edge) => ({
               id: str(edge.id),
               uuid: edge.uuid,
               source: str(edge.source_node),
               target: str(edge.target_node),
               // style: { stroke: "#f6ab6c" },
            }));

            const layoutComputedElements = computeGraphLayout(
               newNodes,
               newEdges
            );

            const newTabObject = {
               id: newTabId,
               name: data.graph.name,
               nodes: layoutComputedElements.nodes,
               edges: layoutComputedElements.edges,
               graph: data,
               nodePanels: [],
               activeNodePanelIndex: 0,
               isPanelOpen: false,
               tabType: "GraphEditor",
            };

            setTabs((prevTabs) => {
               const newTabs = [...prevTabs, newTabObject];
               setActiveTabIndex(newTabs.length - 1);
               return newTabs;
            });
         } catch (error) {
            console.error("Error fetching graph data:", error);
         }
      },
      [tabs, setTabs, setActiveTabIndex, token, logout]
   );

   /* Poll on graphListNeedsRefresh every .25s */
   useEffect(() => {
      const interval = setInterval(() => {
         if (isAuthenticated && graphListNeedsRefresh) {
            fetchGraphList().then(() => setGraphListNeedsRefresh(false));
         }
      }, 350);
      return () => clearInterval(interval);
   }, [
      graphListNeedsRefresh,
      fetchGraphList,
      isAuthenticated,
      setGraphListNeedsRefresh,
   ]);

   useEffect(() => {
      if (!isAuthenticated) {
         return;
      }
      switch (activePanel) {
         case "edit":
            fetchGraphList().then();
            break;
         case "logs":
            fetchLogEntries().then();
            break;
         default:
            break;
      }
   }, [activePanel, fetchGraphList, fetchLogEntries, isAuthenticated]);

   return (
      <Resizable
         defaultSize={{ width: 300 }}
         minWidth={200}
         maxWidth={600}
         enable={{ right: true }}
         style={{
            borderRight: "1px solid rgb(22,22,22)",
            borderLeft: "1px solid rgb(57,59,64)",
            backgroundColor: "rgb(52, 60, 76)",
         }}
         className={`left-panel ${isLeftPanelOpen ? "" : "left-panel-hidden"}`}
      >
         {activePanel === "logs" && isLeftPanelOpen && (
            <GraphExecutionLogSidePanel
               logEntries={logEntries}
               tabs={tabs}
               setTabs={setTabs}
               setActiveTabIndex={setActiveTabIndex}
               fetchGraphExecutionLog={fetchGraphExecutionLog}
               fetchLogEntries={fetchLogEntries}
            />
         )}
         {activePanel === "edit" && isLeftPanelOpen && (
            <GraphEditorSidePanel
               graphList={graphList}
               fetchGraphList={fetchGraphList}
               fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
               token={token}
               logout={logout}
               openSearchDialog={openGraphSearchDialog}
            />
         )}
         {activePanel === "prompts" && isLeftPanelOpen && (
            <PromptSidePanel
               token={token}
               logout={logout}
               isPromptListRefreshing={isPromptListRefreshing}
               setIsPromptListRefreshing={setIsPromptListRefreshing}
               promptListNeedsRefresh={promptListNeedsRefresh}
               setPromptListNeedsRefresh={setPromptListNeedsRefresh}
               fetchAndAddPromptEditTab={fetchAndAddPromptEditTab}
               openSearchDialog={openPromptSearchDialog}
            />
         )}
         {activePanel === "hooks" && isLeftPanelOpen && (
            <GraphExecutionHooks
               tabs={tabs}
               setTabs={setTabs}
               setActiveTabIndex={setActiveTabIndex}
               token={token}
               logout={logout}
               isHookListRefreshing={isHookListRefreshing}
               setIsHookListRefreshing={setIsHookListRefreshing}
               hookListNeedsRefresh={hookListNeedsRefresh}
               setHookListNeedsRefresh={setHookListNeedsRefresh}
            />
         )}
      </Resizable>
   );
};

export default AppSideViewContainer;
