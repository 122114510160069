import { useState, useCallback, useEffect } from "react";

let prompts = null;
let isLoading = false;
let lastFetchTime = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const usePrompts = (token, logout) => {
   const [localPrompts, setLocalPrompts] = useState(prompts);
   const [localIsLoading, setLocalIsLoading] = useState(isLoading);

   const fetchPrompts = useCallback(
      async (force = false) => {
         const now = Date.now();
         if (!force && isLoading) return;
         if (!force && prompts && now - lastFetchTime < CACHE_DURATION) return;

         isLoading = true;
         setLocalIsLoading(true);

         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/prompt`,
               {
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            if (!response.ok) {
               console.error("fetchPrompts: ", response);
               if (response.status === 401) logout();
               return;
            }

            const data = await response.json();
            prompts = data;

            // sort prompts by name
            prompts.sort((a, b) => a.name.localeCompare(b.name));

            setLocalPrompts(data);
            lastFetchTime = now;
         } catch (error) {
            console.error("Error fetching prompts:", error);
         } finally {
            isLoading = false;
            setLocalIsLoading(false);
         }
      },
      [token, logout]
   );

   useEffect(() => {
      fetchPrompts();
   }, [fetchPrompts]);

   return { prompts: localPrompts, isLoading: localIsLoading, fetchPrompts };
};
