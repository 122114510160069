import React, { useState, useCallback } from "react";

export const useSnackBar = () => {
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [snackbarSeverity, setSnackbarSeverity] = useState("success");

   const handleSnackBar = useCallback(
      (message, severity = "success", open = true) => {
         setSnackbarMessage(message);
         setSnackbarSeverity(severity);
         setSnackbarOpen(open);
      },
      []
   );
   const handleCloseSnackbar = useCallback((event, reason) => {
      if (reason === "clickaway") {
         return;
      }
      setSnackbarOpen(false);
   }, []);

   return {
      handleSnackBar,
      handleCloseSnackbar,
      snackbarOpen,
      snackbarMessage,
      snackbarSeverity,
   };
};
