import { styled } from "@mui/material/styles";

const CloseButton = styled("div")({
   marginLeft: "10px",
   cursor: "pointer",
   display: "flex",
   alignItems: "center",
   flexShrink: 0,
});

export default CloseButton;
