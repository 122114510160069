import { useState, useCallback } from "react";
import { useAuth } from "../components/Context/AuthContext";

export const useApi = () => {
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const { isAuthenticated, accessToken, logout } = useAuth();

   const apiRequest = useCallback(
      async (url, method = "GET", body = null, headers = {}) => {
         if (!isAuthenticated) {
            setLoading(false);
            return;
         }
         setLoading(true);
         setError(null);
         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/${url}`,
               {
                  method,
                  headers: {
                     "Content-Type": "application/json",
                     ...(accessToken
                        ? { Authorization: `Bearer ${accessToken}` }
                        : {}),
                     ...headers,
                  },
                  body: body ? JSON.stringify(body) : null,
               }
            );

            if (!response.ok) {
               const errorResponse = await response.json();
               if (errorResponse.status === 401) {
                  logout();
               }
               throw new Error(
                  errorResponse.message || `Error: ${response.statusText}`
               );
            }
            return response;
         } catch (err) {
            setError(err);
         } finally {
            setLoading(false);
         }
      },
      [isAuthenticated, accessToken]
   );

   const refetch = useCallback(
      async (url, method = "GET", body = null, headers = {}) => {
         return await apiRequest(url, method, body, headers);
      },
      [apiRequest]
   );
   return { loading, error, apiRequest, refetch };
};
export default useApi;
