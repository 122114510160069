import {
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   TextField,
   Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";

const FunctionSelector = ({
   taskList,
   selectedFunction,
   onChange,
   inputProps,
   disabled,
}) => {
   // filter tasks with 'hidden' attribute set to true
   taskList = taskList.filter((task) => !task.hidden);
   return (
      (taskList && taskList.length > 0 && (
         <FormControl fullWidth variant="outlined" size="small">
            <InputLabel shrink>Task</InputLabel>
            <TextField
               select
               size="small"
               value={selectedFunction}
               onChange={onChange}
               disabled={disabled || taskList.length === 0}
               label="Task"
               // notched
               // displayEmpty
               // defaultValue=""
               options={taskList.map((task) => task.function)}
               // MenuProps={{
               //    PaperProps: {
               //       style: {
               //          maxHeight: "300px",
               //       },
               //    },
               // }}
               style={{
                  color: "rgb(180,180,180)",
               }}
               inputProps={inputProps}
            >
               {taskList &&
                  taskList.map((task) => (
                     <MenuItem key={task.function} value={task.function} dense>
                        {task.function}
                        <Tooltip title={task.description}>
                           <span>
                              <IconButton
                                 size="small"
                                 style={{
                                    padding: "2px",
                                    marginLeft: "4px",
                                    color: "rgb(200,200,200)",
                                 }}
                              >
                                 <InfoIcon fontSize="small" />
                              </IconButton>
                           </span>
                        </Tooltip>
                     </MenuItem>
                  ))}
            </TextField>
         </FormControl>
      )) ||
      null
   );
};

export default FunctionSelector;
