import React, { useState, useEffect, useCallback } from "react";
import {
   Dialog,
   DialogTitle,
   DialogContent,
   TextField,
   List,
   ListItem,
   DialogActions,
   Box,
   Button,
   CircularProgress,
} from "@mui/material";
import debounce from "lodash/debounce";

const GenericSearchDialog = ({
   isOpen,
   onClose,
   onSearch,
   title,
   placeholder,
   renderListItem,
   additionalControls,
   closeOnSelect = true,
}) => {
   const [searchTerm, setSearchTerm] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   const performSearch = useCallback(
      async (term) => {
         setIsLoading(true);
         const results = await onSearch(term);
         setSearchResults(results);
         setIsLoading(false);
      },
      [onSearch]
   );

   const debouncedSearch = useCallback(debounce(performSearch, 300), [
      performSearch,
   ]);

   useEffect(() => {
      debouncedSearch(searchTerm);
   }, [searchTerm, debouncedSearch]);

   // useEffect(() => {
   //    if (isOpen) {
   //       setSearchTerm("");
   //       performSearch(""); // Trigger initial search with empty term
   //    }
   // }, [isOpen, performSearch]);

   const handleItemClick = (item) => {
      if (closeOnSelect) {
         onClose();
      }
   };

   return (
      <Dialog
         open={isOpen}
         onClose={onClose}
         fullWidth
         maxWidth="md"
         PaperProps={{
            sx: { height: "80vh" },
         }}
      >
         <DialogTitle>{title}</DialogTitle>
         <DialogContent
            sx={{
               display: "flex",
               flexDirection: "column",
               height: "100%",
               overflow: "hidden",
            }}
         >
            <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
               <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={placeholder}
               />
               <CircularProgress
                  size={24}
                  sx={{
                     ml: 1,
                     color: isLoading ? "primary.main" : "grey.400",
                     animation: isLoading ? "spin 1s linear infinite" : "none",
                     visibility: isLoading ? "visible" : "hidden",
                  }}
               />
            </Box>
            {additionalControls && additionalControls()}
            <List
               sx={{
                  flexGrow: 1,
                  overflow: "auto",
                  opacity: isLoading ? 0.5 : 1,
                  pointerEvents: isLoading ? "none" : "auto",
               }}
            >
               {searchResults.map((item, index) => (
                  <ListItem
                     key={index}
                     onClick={() => handleItemClick(item)}
                     sx={{
                        cursor: "pointer",
                        "&:hover": {
                           backgroundColor: "action.hover",
                        },
                     }}
                  >
                     {renderListItem
                        ? renderListItem(item)
                        : JSON.stringify(item)}
                  </ListItem>
               ))}
            </List>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary">
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default GenericSearchDialog;
