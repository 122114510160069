import {
   IconButton,
   Toolbar as MuiToolbar,
   Tooltip,
   Typography,
   Divider,
} from "@mui/material";
import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const GraphEditorToolbar = ({
   leftActions = [],
   centerActions = [],
   rightActions = [],
   version,
   onClone,
   cloneDisabled,
   hasUnsavedChanges,
}) => {
   return (
      <MuiToolbar
         variant="dense"
         style={{
            backgroundColor: "rgb(30,31,34)",
            padding: "0 16px",
            paddingLeft: "8px",
            paddingRight: "16px",
            margin: 0,
            borderBottom: "1px solid rgb(57, 59, 64)",
            height: "25px",
            minHeight: "25px",
            paddingTop: "3px",
            paddingBottom: "3px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
         }}
      >
         <div>
            {leftActions.map(
               (action, index) =>
                  (!action.invisible && (
                     <Tooltip key={index} title={action.tooltip}>
                        <span>
                           <IconButton
                              onClick={action.onClick}
                              size="small"
                              disabled={action.disabled}
                              disableRipple={
                                 action.disabled || action.image_only
                              }
                              style={{
                                 marginRight: "8px",
                                 color: action.disabled
                                    ? "rgb(100, 100, 100)"
                                    : action.color || "rgb(180, 180, 180)",
                                 cursor:
                                    !action.image_only && !action.disabled
                                       ? "pointer"
                                       : "default",
                              }}
                           >
                              {action.icon}
                           </IconButton>
                        </span>
                     </Tooltip>
                  )) ||
                  null
            )}
         </div>
         <div style={{ display: "flex", alignItems: "center" }}>
            {centerActions.map(
               (action, index) =>
                  (!action.invisible && (
                     <Tooltip key={index} title={action.tooltip}>
                        <span>
                           <IconButton
                              onClick={action.onClick}
                              size="small"
                              disabled={action.disabled}
                              disableRipple={
                                 action.disabled || action.image_only
                              }
                              style={{
                                 marginRight: "8px",
                                 color: action.disabled
                                    ? "rgb(100, 100, 100)"
                                    : action.color || "rgb(180, 180, 180)",
                                 cursor:
                                    !action.image_only && !action.disabled
                                       ? "pointer"
                                       : "default",
                              }}
                           >
                              {action.icon}
                           </IconButton>
                        </span>
                     </Tooltip>
                  )) ||
                  null
            )}
            <Divider
               orientation="vertical"
               flexItem
               style={{
                  backgroundColor: "rgb(57, 59, 64)",
                  marginRight: "16px",
               }}
            />
            <Typography
               variant="body2"
               style={{
                  color: "rgb(180, 180, 180)",
                  fontFamily: "'Roboto Mono', monospace",
                  fontSize: "12px",
                  marginRight: "16px",
               }}
            >
               Version: {version || "N/A"}
            </Typography>
            <Tooltip title="Clone Graph">
               <span>
                  <IconButton
                     onClick={onClone}
                     size="small"
                     style={{
                        color: hasUnsavedChanges
                           ? "rgb(100, 100, 100)"
                           : "rgb(180, 180, 180)",
                        marginRight: "4px",
                     }}
                     disabled={hasUnsavedChanges || cloneDisabled}
                  >
                     {/* Replace with your preferred clone icon */}
                     <ContentCopyIcon fontSize="small" />
                  </IconButton>
               </span>
            </Tooltip>
            <Divider
               orientation="vertical"
               flexItem
               style={{
                  backgroundColor: "rgb(57, 59, 64)",
                  marginRight: "6px",
                  marginLeft: "6px",
               }}
            />
            {rightActions.map(
               (action, index) =>
                  (!action.invisible && (
                     <Tooltip key={index} title={action.tooltip}>
                        <span>
                           <IconButton
                              onClick={action.onClick}
                              size="small"
                              disabled={action.disabled}
                              disableRipple={
                                 action.disabled || action.image_only
                              }
                              style={{
                                 marginLeft: "4px",
                                 paddingRight: "0",
                                 color: action.disabled
                                    ? "rgb(100, 100, 100)"
                                    : action.color || "rgb(180, 180, 180)",
                                 cursor:
                                    !action.image_only && !action.disabled
                                       ? "pointer"
                                       : "default",
                              }}
                           >
                              {action.icon}
                           </IconButton>
                        </span>
                     </Tooltip>
                  )) ||
                  null
            )}
         </div>
      </MuiToolbar>
   );
};

export default GraphEditorToolbar;
