import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const TabCloseConfirmationDialog = ({ open, onClose, onConfirm }) => {
   return (
      <Dialog open={open} onClose={onClose}>
         <DialogTitle>Unsaved Changes</DialogTitle>
         <DialogContent>
            <DialogContentText>
               You have unsaved changes. Are you sure you want to close this
               tab?
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary">
               Cancel
            </Button>
            <Button onClick={onConfirm} color="error">
               Close Tab
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default TabCloseConfirmationDialog;
