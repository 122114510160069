import { styled } from "@mui/material/styles";

const GraphEditorReactFlowContainer = styled("div")(({ theme }) => ({
   width: "100%",
   height: "100%",
   backgroundColor: "rgb(30, 31, 34)",
   "& .react-flow__node": {
      background: "rgb(113, 164, 235)",
      border: "2px solid rgb(88, 88, 88)",
      borderRadius: "10px",
      cursor: "default",
   },
   "& .react-flow__node.isSelected": {
      background: "rgb(220, 220, 220)",
      border: "2px solid rgb(116,164,232)",
   },
   "& .react-flow__node.hasError": {
      border: "3px solid rgb(211, 80, 94)",
      backgroundColor: "rgba(211, 80, 94, 0.5)",
   },
   "& .react-flow__edge path": {
      stroke: "#888",
      strokeWidth: "2px",
   },
   "& .react-flow__controls": {
      backgroundColor: "rgb(88, 88, 88)",
      color: "#e0e0e0",
      borderRadius: "4px",
      "& button": {
         color: "#e0e0e0",
      },
   },
   "& .react-flow__minimap": {
      backgroundColor: "rgb(43, 45, 48)",
   },
   "& .react-flow__background": {
      fill: "rgb(43, 45, 48)",
   },
}));

export default GraphEditorReactFlowContainer;
