import React from "react";
import { Snackbar as MaterialSnackBar } from "@mui/material";
import Alert from "@mui/material/Alert";

const Snackbar = ({
   message,
   severity = "success",
   autoHideDuration = 6000,
   open,
   onClose,
}) => {
   return (
      <MaterialSnackBar
         open={open}
         autoHideDuration={autoHideDuration}
         anchorOrigin={{ horizontal: "right", vertical: "top" }}
         onClose={onClose}
      >
         <Alert
            onClose={onClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%", color: "#fff" }}
         >
            {message}
         </Alert>
      </MaterialSnackBar>
   );
};

export default Snackbar;
