import React, { useCallback, useMemo, useState } from "react";
import { ReactFlowProvider, useReactFlow } from "react-flow-renderer";
import GraphEditorToolbar from "./GraphEditorToolbar";
import GraphEditorSubPanelContainer from "./GraphEditorSubPanelContainer";
import GraphEditorFlow from "./GraphEditorFlow";
import SaveIcon from "@mui/icons-material/Save";
import GraphEditorReactFlowContainer from "./GraphEditorReactFlowContainer";
import AddIcon from "@mui/icons-material/Add";
import NodeLabel from "./NodeLabel";
import computeGraphLayout from "../../../utils/graphLayout";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import WebhookIcon from "@mui/icons-material/Webhook";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import GraphExecutionDialog from "./GraphExecutionDialog";
import SaveErrorModal from "./SaveErrorModal";
import DeleteConfirmationDialog from "../../Components/DeleteConfirmationDialog";
import DataObjectIcon from "@mui/icons-material/DataObject";
import JSONInfoDialog from "../../Components/JSONInfoDialog";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RenameGraphDialog from "./RenameGraphDialog";
import { usePrompts } from "./usePrompts";
import useTaskList from "./useTaskList";
import { useGraphs } from "./useGraphs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const GraphEditorPanel = ({
   index,
   tab,
   panelHeight,
   onPanelHeightChange,
   tabViewports,
   setTabViewports,
   onNodePanelTabChange,
   onNodePanelClose,
   onNodeDoubleClick,
   setNodes,
   setEdges,
   setTabs,
   activeTabIndex,
   updateNodeAndTab,
   fetchAndAddGraphExecutionTab,
   fetchAndAddGraphEditTab,
   handleTabClose,
   token,
   logout,
   setGraphListNeedsRefresh,
   fetchAndAddPromptEditTab,
   ...props
}) => {
   const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
   const graph_version = tab.graph?.graph?.version;
   const [layoutVersion, setLayoutVersion] = useState(0);
   const { fitView } = useReactFlow();
   const [isExecutionDialogOpen, setIsExecutionDialogOpen] = useState(false);
   const [isCloning, setIsCloning] = useState(false);
   const [isSaving, setIsSaving] = useState(false);
   const [saveError, setSaveError] = useState(null);
   const [duplicateNodeNames, setDuplicateNodeNames] = useState([]);
   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
   const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
   const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
   const [runInputData, setRunInputData] = useState("");
   const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

   usePrompts(token, logout);
   useTaskList(token, logout);
   useGraphs(token, logout);

   const SaveWarningDialog = ({ open, onClose, onConfirm }) => {
      return (
         <Dialog open={open} onClose={onClose}>
            <DialogTitle>Warning: Live Production Graph</DialogTitle>
            <DialogContent>
               <DialogContentText>
                  This graph is currently in active use in production. Are you
                  sure you want to save changes?
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={onClose}>Cancel</Button>
               <Button onClick={onConfirm} color="primary" autoFocus>
                  Save Changes
               </Button>
            </DialogActions>
         </Dialog>
      );
   };

   const updateHasUnsavedChanges = useCallback(
      (value) => {
         setHasUnsavedChanges(value);
         setTabs((prevTabs) =>
            prevTabs.map((t, i) =>
               i === index ? { ...t, hasUnsavedChanges: value } : t
            )
         );
      },
      [setTabs, index]
   );

   const handleInfoClick = useCallback(() => {
      setIsInfoDialogOpen(true);
   }, []);

   const handleRenameClick = useCallback(() => {
      setIsRenameDialogOpen(true);
   }, []);

   const handleRename = useCallback(
      (newName) => {
         setTabs((prevTabs) =>
            prevTabs.map((t, i) =>
               i === index
                  ? {
                       ...t,
                       name: newName,
                       graph: {
                          ...t.graph,
                          graph: { ...t.graph.graph, name: newName },
                       },
                       hasUnsavedChanges: true,
                    }
                  : t
            )
         );
         updateHasUnsavedChanges(true);
      },
      [setTabs, index, updateHasUnsavedChanges]
   );

   const openExecutionDialog = useCallback(() => {
      setIsExecutionDialogOpen(true);
   }, []);

   const closeExecutionDialog = useCallback(() => {
      setIsExecutionDialogOpen(false);
   }, []);

   const onSaveNode = useCallback(
      (nodeId, updatedData, error, hasUnsavedChanges_) => {
         const hasUnsavedChanges =
            hasUnsavedChanges_ === undefined ? true : hasUnsavedChanges_;
         const previousError =
            tab.nodes.find((node) => node.id === nodeId).data.hasError || false;

         updatedData.hasError = error !== undefined ? error : previousError;

         // re-render the graph if the error state has changed
         if (previousError !== updatedData.hasError)
            setLayoutVersion((prev) => prev + 1);

         // Update the node data
         updateNodeAndTab(nodeId, updatedData);
         if (hasUnsavedChanges) updateHasUnsavedChanges(true);
      },
      [updateNodeAndTab, tab]
   );

   const hasEditError = useMemo(() => {
      return tab.nodes.some((node) => node.data.hasError);
   }, [tab.nodes]);

   const GRAPH_KEYS_TO_INCLUDE = ["name"];
   const NODE_KEYS_TO_EXCLUDE = [
      "id",
      "uuid",
      "created_at",
      "updated_at",
      "label",
      "rawEditorData",
      "hasError",
      "hasErrorsAt",
      "selectedToolbarAction",
      "schemaEditorContent",
      "functionParameterEditorContent",
   ];

   const collectGraph = useCallback(() => {
      const nodeMap = new Map(tab.nodes.map((node) => [node.id, node]));
      const edgeMap = new Map();
      tab.edges.forEach((edge) => {
         if (!edgeMap.has(edge.source)) {
            edgeMap.set(edge.source, []);
         }
         edgeMap.get(edge.source).push(edge.target);
      });

      const serializeNode = (nodeId) => {
         const node = nodeMap.get(nodeId);
         if (!node) return null;

         const serializedNode = {};
         Object.entries(node.data).forEach(([key, value]) => {
            if (!NODE_KEYS_TO_EXCLUDE.includes(key)) {
               serializedNode[key] = value;
            }
         });

         const children = edgeMap.get(nodeId) || [];
         if (children.length > 0) {
            serializedNode.children = children.map(
               (childId) => nodeMap.get(childId).data.name
            );
         }

         return serializedNode;
      };

      const serializedNodes = Array.from(nodeMap.keys())
         .map((nodeId) => serializeNode(nodeId))
         .filter(Boolean);

      const graphData = {};
      GRAPH_KEYS_TO_INCLUDE.forEach((key) => {
         if (tab[key] !== undefined) {
            graphData[key] = tab[key];
         }
      });

      return {
         ...graphData,
         nodes: serializedNodes,
      };
   }, [tab]);

   const executeGraphWithParams = useCallback(
      async (params) => {
         const graph_id = tab.graph.graph.id;
         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph/${graph_id}/execute_by_id`,
               {
                  method: "POST",
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
                  body: JSON.stringify(params),
               }
            );
            if (response.status === 200) {
               const data = await response.json();
               const graph_log_execution_id = data.graph_log_execution_id;
               fetchAndAddGraphExecutionTab(graph_log_execution_id);
            } else {
               console.error(`/graph/${graph_id}/execute: `, response.status);
               if (response.status === 401) logout();
            }
         } catch (error) {
            console.error("executeGraph Error: ", error);
         }
         closeExecutionDialog();
      },
      [tab, token, logout, fetchAndAddGraphExecutionTab, closeExecutionDialog]
   );

   const handleClone = useCallback(async () => {
      setIsCloning(true);
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph/${tab.graph.graph.id}/clone`,
            {
               method: "POST",
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (response.status === 200) {
            const data = await response.json();
            const newGraphId = data._id;
            await fetchAndAddGraphEditTab(newGraphId);
            setGraphListNeedsRefresh(true);
         } else {
            console.error(`Clone graph error: ${response.status}`);
            if (response.status === 401) {
               logout();
            }
         }
      } catch (error) {
         console.error("Clone graph error:", error);
      } finally {
         setIsCloning(false);
      }
   }, [tab, token, fetchAndAddGraphEditTab, logout, setGraphListNeedsRefresh]);

   const saveGraph = useCallback(async () => {
      setIsSaving(true);
      setSaveError(null);
      setDuplicateNodeNames([]);

      const graphObj = collectGraph();

      // Check for duplicate node names
      const nodeNames = graphObj.nodes.map((node) => node.name);
      const duplicates = nodeNames.filter(
         (name, index) => nodeNames.indexOf(name) !== index
      );

      if (duplicates.length > 0) {
         setDuplicateNodeNames(duplicates);
         setIsSaving(false);
         return;
      }

      const serializedGraph = JSON.stringify(graphObj);

      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph/${tab.graph.graph.id}`,
            {
               method: "PUT",
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
               body: serializedGraph,
            }
         );

         if (response.status === 200) {
            updateHasUnsavedChanges(false);
            setGraphListNeedsRefresh(true);
         } else if (response.status === 401) {
            logout();
         } else {
            const errorData = await response.json();
            setSaveError(
               errorData.message || "An error occurred while saving the graph."
            );
         }
      } catch (error) {
         console.error("Save graph error:", error);
         setSaveError("An unexpected error occurred while saving the graph.");
      } finally {
         setIsSaving(false);
      }
   }, [
      collectGraph,
      tab.graph.graph.id,
      token,
      logout,
      setGraphListNeedsRefresh,
   ]);

   const handleSave = useCallback(async () => {
      if (tab.graph.graph.is_hooked) {
         setIsWarningDialogOpen(true);
      } else {
         saveGraph();
      }
   }, [tab.graph.graph.is_hooked, saveGraph]);

   const handleDelete = useCallback(async () => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph/${tab.graph.graph.id}`,
            {
               method: "DELETE",
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );

         if (response.status === 200) {
            handleTabClose(activeTabIndex);
            setGraphListNeedsRefresh(true);
         } else if (response.status === 401) {
            logout();
         } else {
            console.error("Delete graph error:", response.status);
         }
      } catch (error) {
         console.error("Delete graph error:", error);
      } finally {
         setIsDeleteDialogOpen(false);
      }
   }, [
      tab.graph.graph.id,
      token,
      logout,
      handleTabClose,
      activeTabIndex,
      setGraphListNeedsRefresh,
   ]);

   const handleExport = useCallback(async () => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph/${tab.graph.graph.id}/export`,
            {
               method: "GET",
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );

         if (response.status === 200) {
            const data = await response.json();
            const blob = new Blob([JSON.stringify(data, null, 2)], {
               type: "application/json",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            const tab_name = tab.name.replace(/[^a-zA-Z0-9]/g, "_");
            const unix_ts = new Date().getTime();
            const filename_with_datetime = `graph-${tab_name}-${unix_ts}`;
            a.href = url;
            a.download = `${filename_with_datetime}.json`;
            a.click();
            URL.revokeObjectURL(url);
         } else if (response.status === 401) {
            logout();
         }
      } catch (error) {
         console.error("Export graph error:", error);
      }
   }, [tab.graph.graph.id, tab.name, token, logout]);

   const handleNewNode = useCallback(() => {
      const newNode = {
         id: `${Date.now()}`, // Generate a unique ID
         name: "New Task",
         data: {
            name: "New Task",
            function: "",
            function_params: {},
            label: <NodeLabel name="New Task" function="" />,
         },
         position: { x: 0, y: 0 }, // TODO: calculate a better position
      };

      setNodes((prevNodes) => [...prevNodes, newNode]);
      updateHasUnsavedChanges(true);

      // Open associated edit sub-panel
      onNodePanelTabChange(newNode.id);
   }, [setNodes, updateHasUnsavedChanges]);

   const handleDeleteNode = useCallback(
      (nodeId) => {
         setNodes((prevNodes) =>
            prevNodes.filter((node) => node.id !== nodeId)
         );
         setEdges((prevEdges) =>
            prevEdges.filter(
               (edge) => edge.source !== nodeId && edge.target !== nodeId
            )
         );
         updateHasUnsavedChanges(true);

         // Close the sub-panel for the deleted node
         onNodePanelClose(nodeId);
      },
      [setNodes, setEdges, updateHasUnsavedChanges, onNodePanelClose]
   );

   const handleReorderPanels = useCallback(
      (fromIndex, toIndex) => {
         setNodes((prevNodes) => {
            const newNodes = [...prevNodes];
            const currentNode = newNodes.find(
               (node) => node.id === tab.nodePanels[fromIndex].id
            );
            if (currentNode) {
               const [movedPanel] = tab.nodePanels.splice(fromIndex, 1);
               tab.nodePanels.splice(toIndex, 0, movedPanel);
               currentNode.data = { ...currentNode.data, ...movedPanel.data };
            }
            return newNodes;
         });

         // Update the active index
         onNodePanelTabChange(tab.nodePanels[toIndex].id);
      },
      [tab.nodePanels, setNodes, onNodePanelTabChange]
   );

   const handleApplyLayout = useCallback(() => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = computeGraphLayout(
         tab.nodes,
         tab.edges
      );

      setNodes(layoutedNodes);
      setEdges(layoutedEdges);

      // Force a re-render of React Flow
      setLayoutVersion((prev) => prev + 1);

      // Use setTimeout to ensure the layout is applied before fitting the view
      setTimeout(() => {
         fitView({ padding: 0.2 });
      }, 0);
   }, [
      tab.nodes,
      tab.edges,
      setNodes,
      setEdges,
      updateHasUnsavedChanges,
      fitView,
   ]);

   const graphEditorToolbarActionsLeft = [
      {
         tooltip: "New Node",
         onClick: handleNewNode,
         icon: <AddIcon fontSize="small" />,
         disabled: isSaving || isCloning,
      },
      {
         tooltip: "Auto Layout",
         onClick: handleApplyLayout,
         icon: <AutoGraphIcon fontSize="small" />,
      },
      {
         tooltip: "Rename Graph",
         onClick: handleRenameClick,
         icon: <DriveFileRenameOutlineIcon fontSize="small" />,
         disabled: isSaving || isCloning,
      },
      {
         tooltip: "Graph Data",
         onClick: handleInfoClick,
         icon: <DataObjectIcon fontSize="small" />,
         disabled: isSaving || isCloning,
      },
      {
         tooltip: "Export Graph",
         onClick: () => handleExport(),
         icon: <FileDownloadIcon fontSize="small" />,
         disabled: hasUnsavedChanges || isSaving || isCloning,
      },
      {
         tooltip: "Delete Graph",
         onClick: () => setIsDeleteDialogOpen(true),
         icon: <DeleteIcon fontSize="small" />,
         disabled: hasUnsavedChanges || isSaving || isCloning,
         color: "rgb(161,91,88)",
      },
   ];

   const graphEditorToolbarActionsCenter = [
      {
         tooltip: "Errors found",
         icon: <ErrorOutlineIcon fontSize="small" />,
         disabled: !hasEditError,
         invisible: !hasEditError,
         image_only: true,
         color: "rgb(211, 80, 94)",
      },
      {
         tooltip: tab.graph.graph.is_hooked
            ? "Linked to hook"
            : "Not linked to hook",
         icon: <WebhookIcon fontSize="small" />,
         disabled: !tab.graph.graph.is_hooked,
         image_only: true,
         color: "rgb(255, 255, 0)",
      },
      {
         tooltip: "Run Graph",
         onClick: openExecutionDialog,
         icon: <PlayArrowIcon fontSize="small" />,
         disabled: hasUnsavedChanges,
         color: "rgb(89, 149, 95)",
      },
   ];

   const graphEditorToolbarActionsRight = [
      {
         tooltip: "Save Changes",
         onClick: handleSave,
         icon: <SaveIcon fontSize="small" />,
         disabled: !hasUnsavedChanges || hasEditError || isSaving,
         color: "rgb(89, 149, 95)",
      },
   ];

   const activeNodeId = useMemo(() => {
      if (tab.isPanelOpen && tab.nodePanels.length > 0) {
         return tab.nodePanels[tab.activeNodePanelIndex]?.id;
      }
      return null;
   }, [tab.isPanelOpen, tab.nodePanels, tab.activeNodePanelIndex]);

   return (
      <div
         role="tabpanel"
         hidden={activeTabIndex !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         style={{
            width: "100%",
            height: "calc(100%)",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
         }}
      >
         {activeTabIndex === index && (
            <>
               {/* Toolbar */}
               <GraphEditorToolbar
                  leftActions={graphEditorToolbarActionsLeft}
                  rightActions={graphEditorToolbarActionsRight}
                  centerActions={graphEditorToolbarActionsCenter}
                  version={graph_version}
                  hasUnsavedChanges={hasUnsavedChanges}
                  onClone={handleClone}
                  cloneDisabled={isCloning}
               />

               {/* Graph */}
               <div style={{ flex: 1, minHeight: 0 }}>
                  <GraphEditorReactFlowContainer>
                     <ReactFlowProvider>
                        <GraphEditorFlow
                           {...props}
                           key={layoutVersion}
                           tabId={tab.id}
                           tabViewports={tabViewports}
                           setTabViewports={setTabViewports}
                           onNodeDoubleClick={onNodeDoubleClick}
                           setNodes={setNodes}
                           setEdges={setEdges}
                           setTabs={setTabs}
                           nodes={tab.nodes}
                           edges={tab.edges}
                           activeTabIndex={activeTabIndex}
                           updateNodeAndTab={updateNodeAndTab}
                           setHasUnsavedChanges={updateHasUnsavedChanges}
                           onNodeDelete={handleDeleteNode}
                           activeNodeId={activeNodeId}
                           isEditable={true}
                        />
                     </ReactFlowProvider>
                  </GraphEditorReactFlowContainer>
               </div>

               {/* Tab Sub-panel */}
               {tab.isPanelOpen && tab.nodePanels.length > 0 && (
                  <GraphEditorSubPanelContainer
                     panels={tab.nodePanels}
                     activeIndex={tab.activeNodePanelIndex}
                     onTabChange={(newIndex) => {
                        const nodeId = tab.nodePanels[newIndex].id;
                        onNodePanelTabChange(nodeId);
                     }}
                     onTabClose={(panelIndex) => {
                        const nodeId = tab.nodePanels[panelIndex].id;
                        onNodePanelClose(nodeId);
                     }}
                     tabId={tab.id}
                     height={panelHeight}
                     onHeightChange={onPanelHeightChange}
                     onSaveNode={onSaveNode}
                     onReorderPanels={handleReorderPanels}
                     token={token}
                     logout={logout}
                     fetchAndAddPromptEditTab={fetchAndAddPromptEditTab}
                     fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
                  />
               )}

               <JSONInfoDialog
                  open={isInfoDialogOpen}
                  onClose={() => setIsInfoDialogOpen(false)}
                  data={tab.graph}
               />
               <RenameGraphDialog
                  open={isRenameDialogOpen}
                  onClose={() => setIsRenameDialogOpen(false)}
                  onRename={handleRename}
                  currentName={tab.name}
               />
               <GraphExecutionDialog
                  open={isExecutionDialogOpen}
                  onClose={closeExecutionDialog}
                  onExecute={executeGraphWithParams}
                  inputData={runInputData}
                  setInputData={setRunInputData}
               />
               <SaveWarningDialog
                  open={isWarningDialogOpen}
                  onClose={() => setIsWarningDialogOpen(false)}
                  onConfirm={() => {
                     setIsWarningDialogOpen(false);
                     saveGraph();
                  }}
               />
               <SaveErrorModal
                  open={duplicateNodeNames.length > 0 || !!saveError}
                  onClose={() => {
                     setDuplicateNodeNames([]);
                     setSaveError(null);
                  }}
                  duplicateNodeNames={duplicateNodeNames}
                  saveError={saveError}
               />
               <DeleteConfirmationDialog
                  open={isDeleteDialogOpen}
                  onClose={() => setIsDeleteDialogOpen(false)}
                  onConfirm={handleDelete}
                  objectName={"graph"}
               />
            </>
         )}
      </div>
   );
};

export default GraphEditorPanel;
