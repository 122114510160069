import { useState, useCallback, useEffect } from "react";

let models = null;
let isLoading = false;
let lastFetchTime = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const useModels = (token, logout) => {
   const [localModels, setLocalModels] = useState(models);
   const [localIsLoading, setLocalIsLoading] = useState(isLoading);

   const fetchModels = useCallback(
      async (force = false) => {
         const now = Date.now();
         if (!force && isLoading) return;
         if (!force && models && now - lastFetchTime < CACHE_DURATION) return;

         isLoading = true;
         setLocalIsLoading(true);

         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/model`,
               {
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            if (!response.ok) {
               console.error("fetchModels: ", response);
               if (response.status === 401) logout();
               return;
            }

            const data = await response.json();
            const modelList = Object.entries(data)
               .flatMap(([provider, models]) =>
                  models.map((model) => `${provider}/${model}`)
               )
               .sort();

            setLocalModels(modelList);
            lastFetchTime = now;
         } catch (error) {
            console.error("Error fetching models:", error);
         } finally {
            isLoading = false;
            setLocalIsLoading(false);
         }
      },
      [token, logout]
   );

   useEffect(() => {
      fetchModels();
   }, [fetchModels]);

   return { models: localModels, isLoading: localIsLoading, fetchModels };
};
