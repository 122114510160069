import React, { useState } from "react";
import { Resizable } from "re-resizable";
import {
   Paper,
   Typography,
   Box,
   IconButton,
   Collapse,
   Tooltip,
} from "@mui/material";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import renderPromptExecutionLogs from "./renderPromptExecutionLogs";
import GraphExecutionLogSubPanelTabs from "./GraphExecutionLogSubPanelTabs";
import Alert from "@mui/material/Alert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AssistantIcon from "@mui/icons-material/Assistant";
import Editor from "@monaco-editor/react";
import AgentHelpModal from "../../Modals/AgentHelpModal";

const GraphExecutionLogSubPanel = ({
   isOpen,
   panels,
   activeIndex,
   onTabChange,
   onTabClose,
   tabId,
   height,
   onHeightChange,
   onReorderPanels,
   accessToken,
   logout,
}) => {
   const [outputExpanded, setOutputExpanded] = useState(true);
   const [inputExpanded, setInputExpanded] = useState(true);
   const [rawExpanded, setRawExpanded] = useState(false);
   const [leftPanelWidth, setLeftPanelWidth] = useState("60%");
   const [helpModalOpen, setHelpModalOpen] = useState(false);
   const [helpInstruction, setHelpInstruction] = useState("");
   const [helpData, setHelpData] = useState("");

   if (!isOpen || panels.length === 0) return null;

   const handleOpenHelpModal = (instruction, data) => {
      setHelpInstruction(instruction);
      setHelpData(data);
      setHelpModalOpen(true);
   };

   const activePanel = panels[activeIndex] || panels[0];

   const handleReorderTabs = (fromIndex, toIndex) => {
      onReorderPanels(fromIndex, toIndex);
   };

   const FILTERED_KEYS = [
      "id",
      "uuid",
      "name",
      "exec_graph",
      "node_id",
      "function_name",
      "input_state",
      "output_state",
      "prompt_execution_logs",
      "error",
      "label",
   ];

   const FILTERED_IO_KEYS = ["bearer_token", "callback_url"];

   const filterIOExecutionData = (obj) => {
      const newObj = {};
      if (!obj) return newObj;
      Object.keys(obj).forEach((key) => {
         if (!FILTERED_IO_KEYS.includes(key)) {
            newObj[key] = obj[key];
         }
      });
      return newObj;
   };

   const filterExecutionData = (obj) => {
      const newObj = {};
      if (!obj) return newObj;
      Object.keys(obj).forEach((key) => {
         if (!FILTERED_KEYS.includes(key)) {
            newObj[key] = obj[key];
         }
      });
      return newObj;
   };

   return (
      <Resizable
         defaultSize={{
            width: "100%",
            height: height || "75%",
         }}
         minHeight={100}
         maxHeight="92%"
         enable={{
            top: true,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
         }}
         handleStyles={{
            top: {
               height: "10px",
               top: 0,
               cursor: "ns-resize",
            },
         }}
         onResizeStop={(e, direction, ref, _) => {
            onHeightChange(tabId, ref.style.height);
         }}
      >
         <Paper
            elevation={3}
            style={{
               height: "100%",
               display: "flex",
               flexDirection: "column",
               overflow: "hidden",
               borderTop: "1px solid rgb(43, 45, 48)",
               backgroundColor: "rgb(30, 31, 34)",
               color: "rgb(180, 180, 180)",
            }}
         >
            <GraphExecutionLogSubPanelTabs
               panels={panels}
               activeIndex={activeIndex}
               onTabChange={onTabChange}
               onTabClose={onTabClose}
               onReorderTabs={handleReorderTabs}
            />
            {activePanel && (
               <Box display="flex" flex={1} overflow="hidden">
                  <Resizable
                     defaultSize={{
                        // width: activePanel.data.prompt_execution_logs
                        //    ? leftPanelWidth
                        //    : "100%",
                        width: leftPanelWidth,
                        height: "100%",
                     }}
                     minWidth="40%"
                     maxWidth="90%"
                     enable={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                     }}
                     handleStyles={{
                        right: {
                           width: "5px",
                           right: 0,
                           cursor: "ew-resize",
                           backgroundColor: "rgb(43, 45, 48)",
                        },
                     }}
                     onResizeStop={(e, direction, ref, d) => {
                        setLeftPanelWidth(ref.style.width);
                     }}
                  >
                     <Box
                        p={2}
                        overflow="auto"
                        style={{
                           margin: 0,
                           padding: 0,
                           height: "100%",
                        }}
                     >
                        {activePanel.data.error && (
                           <Box mt={2} mb={2}>
                              <Alert
                                 severity="error"
                                 icon={<ErrorOutlineIcon />}
                                 style={{
                                    backgroundColor: "rgba(211, 47, 47, 0.1)",
                                    color: "#f44336",
                                    position: "relative", // Add this
                                    paddingRight: "48px", // Add this to make space for the button
                                 }}
                              >
                                 <Typography variant="subtitle2">
                                    Execution Error
                                 </Typography>
                                 <Typography
                                    variant="body2"
                                    component="pre"
                                    style={{
                                       whiteSpace: "pre-wrap",
                                       wordBreak: "break-word",
                                       fontFamily: "monospace",
                                       marginTop: "8px",
                                    }}
                                 >
                                    {activePanel.data.error}
                                 </Typography>
                                 <Tooltip
                                    title="Get AI assistance for this error"
                                    arrow
                                 >
                                    <IconButton
                                       size="small"
                                       onClick={() =>
                                          handleOpenHelpModal(
                                             "Please explain this error and suggest potential solutions.",
                                             activePanel.data.error
                                          )
                                       }
                                       sx={{
                                          color: "white",
                                          backgroundColor:
                                             "rgba(244, 67, 54, 0.8)",
                                          "&:hover": {
                                             backgroundColor:
                                                "rgba(244, 67, 54, 1)",
                                          },
                                          position: "absolute",
                                          top: "8px",
                                          right: "8px",
                                          padding: "4px",
                                          borderRadius: "4px",
                                          "& .MuiSvgIcon-root": {
                                             fontSize: "1.0rem",
                                          },
                                       }}
                                    >
                                       <AssistantIcon />
                                    </IconButton>
                                 </Tooltip>
                              </Alert>
                           </Box>
                        )}
                        <Box mt={2}>
                           <Box display="flex" alignItems="flex-start">
                              <IconButton
                                 size="small"
                                 onClick={() =>
                                    setOutputExpanded(!outputExpanded)
                                 }
                                 sx={{ mt: -0.5, mr: 1 }}
                                 style={{
                                    color: "rgb(180, 180, 180)",
                                 }}
                              >
                                 {outputExpanded ? (
                                    <ExpandMoreIcon fontSize="small" />
                                 ) : (
                                    <ChevronRightIcon fontSize="small" />
                                 )}
                              </IconButton>
                              <Box flex={1}>
                                 <Typography variant="subtitle2" mb={1}>
                                    Output
                                 </Typography>
                                 <Collapse in={outputExpanded}>
                                    <Box ml={1}>
                                       <JsonView
                                          src={filterIOExecutionData(
                                             activePanel.data.output_state
                                          )}
                                          collapsed={false}
                                          displaySize={false}
                                          displayObjectSize={false}
                                          enableClipboard={true}
                                          theme="default"
                                       />
                                    </Box>
                                 </Collapse>
                              </Box>
                           </Box>
                        </Box>
                        <Box mt={2}>
                           <Box display="flex" alignItems="flex-start">
                              <IconButton
                                 size="small"
                                 onClick={() =>
                                    setInputExpanded(!inputExpanded)
                                 }
                                 sx={{ mt: -0.5, mr: 1 }}
                                 style={{
                                    color: "rgb(180, 180, 180)",
                                 }}
                              >
                                 {inputExpanded ? (
                                    <ExpandMoreIcon fontSize="small" />
                                 ) : (
                                    <ChevronRightIcon fontSize="small" />
                                 )}
                              </IconButton>
                              <Box flex={1}>
                                 <Typography variant="subtitle2" mb={1}>
                                    Input
                                 </Typography>
                                 <Collapse in={inputExpanded}>
                                    <Box ml={1}>
                                       <JsonView
                                          src={filterIOExecutionData(
                                             activePanel.data.input_state
                                          )}
                                          collapsed={false}
                                          displaySize={false}
                                          displayObjectSize={false}
                                          enableClipboard={true}
                                          theme="default"
                                       />
                                    </Box>
                                 </Collapse>
                              </Box>
                           </Box>
                        </Box>
                        <Box mt={2}>
                           <Box display="flex" alignItems="flex-start">
                              <IconButton
                                 size="small"
                                 onClick={() => setRawExpanded(!rawExpanded)}
                                 sx={{ mt: -0.5, mr: 1 }}
                                 style={{
                                    color: "rgb(180, 180, 180)",
                                 }}
                              >
                                 {rawExpanded ? (
                                    <ExpandMoreIcon fontSize="small" />
                                 ) : (
                                    <ChevronRightIcon fontSize="small" />
                                 )}
                              </IconButton>
                              <Box flex={1}>
                                 <Typography variant="subtitle2" mb={1}>
                                    All Data
                                 </Typography>
                                 <Collapse in={rawExpanded}>
                                    <Box ml={1}>
                                       <JsonView
                                          src={filterExecutionData(
                                             activePanel.data
                                          )}
                                          collapsed={false}
                                          displaySize={false}
                                          displayObjectSize={false}
                                          enableClipboard={true}
                                          theme="default"
                                       />
                                    </Box>
                                 </Collapse>
                              </Box>
                           </Box>
                        </Box>
                     </Box>
                  </Resizable>

                  <Box
                     display="flex"
                     flexDirection="column"
                     overflow="auto"
                     width="100%"
                  >
                     {Array.isArray(activePanel.data.prompt_execution_logs) &&
                        activePanel.data.prompt_execution_logs.length > 0 && (
                           <Box>
                              <Typography variant="subtitle1" mb={2}>
                                 Prompt Execution Logs
                              </Typography>
                              {renderPromptExecutionLogs(
                                 activePanel.data.prompt_execution_logs
                              )}
                           </Box>
                        )}
                     {activePanel.data.input_state &&
                        activePanel.data.input_state._error_json_input_str && (
                           <Box>
                              <Alert
                                 severity="error"
                                 icon={<ErrorOutlineIcon />}
                                 style={{
                                    backgroundColor: "rgba(211, 47, 47, 0.1)",
                                    color: "#f44336",
                                    marginBottom: "16px",
                                 }}
                              >
                                 <Typography variant="subtitle2">
                                    JSON Decode Error Input
                                 </Typography>
                              </Alert>
                              <Editor
                                 height="400px"
                                 defaultLanguage="json"
                                 value={
                                    activePanel.data.input_state
                                       ._error_json_input_str
                                 }
                                 theme="vs-dark"
                                 options={{
                                    readOnly: true,
                                    minimap: { enabled: false },
                                    lineNumbers: "on",
                                    scrollBeyondLastLine: false,
                                 }}
                                 style={{ width: "100%" }}
                              />
                           </Box>
                        )}
                  </Box>
               </Box>
            )}
         </Paper>
         <AgentHelpModal
            open={helpModalOpen}
            onClose={() => setHelpModalOpen(false)}
            instruction={helpInstruction}
            data={helpData}
            token={accessToken}
            logout={logout}
         />
      </Resizable>
   );
};

export default GraphExecutionLogSubPanel;
