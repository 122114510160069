import { IconButton, Toolbar as MuiToolbar, Tooltip } from "@mui/material";
import React from "react";

const PromptEditorToolbar = ({ leftActions, actions = [] }) => {
   return (
      <MuiToolbar
         variant="dense"
         style={{
            backgroundColor: "rgb(30,31,34)",
            padding: "0 16px",
            paddingLeft: "0px",
            paddingRight: "11px",
            margin: 0,
            borderBottom: "1px solid rgb(57, 59, 64)",
            height: "25px",
            minHeight: "25px",
            paddingTop: "3px",
            paddingBottom: "3px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
         }}
      >
         <div>
            {leftActions.map(
               (action, index) =>
                  (!action.invisible && (
                     <Tooltip key={index} title={action.tooltip}>
                        <span>
                           <IconButton
                              onClick={action.onClick}
                              size="small"
                              disabled={action.disabled}
                              disableRipple={
                                 action.disabled || action.image_only
                              }
                              style={{
                                 marginLeft: "8px",
                                 color: action.disabled
                                    ? "rgb(100, 100, 100)"
                                    : action.color || "rgb(180, 180, 180)",
                                 cursor:
                                    !action.image_only && !action.disabled
                                       ? "pointer"
                                       : "default",
                              }}
                           >
                              {action.icon}
                           </IconButton>
                        </span>
                     </Tooltip>
                  )) ||
                  null
            )}
         </div>
         <div style={{ display: "flex", alignItems: "center" }}>
            {actions.map(
               (action, index) =>
                  (!action.invisible && (
                     <Tooltip key={index} title={action.tooltip}>
                        <span>
                           <IconButton
                              onClick={action.onClick}
                              size="small"
                              disabled={action.disabled}
                              disableRipple={
                                 action.disabled || action.image_only
                              }
                              style={{
                                 marginLeft: "8px",
                                 color: action.disabled
                                    ? "rgb(100, 100, 100)"
                                    : action.color || "rgb(180, 180, 180)",
                                 cursor:
                                    !action.image_only && !action.disabled
                                       ? "pointer"
                                       : "default",
                              }}
                           >
                              {action.icon}
                           </IconButton>
                        </span>
                     </Tooltip>
                  )) ||
                  null
            )}
         </div>
      </MuiToolbar>
   );
};

export default PromptEditorToolbar;
