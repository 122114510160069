import { Tab, Tabs, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useCallback, useState } from "react";

import TabLabel from "../../Components/TabLabel";
import TabText from "../../Components/TabText";
import CloseButton from "../../Components/CloseButton";
import SchemaIcon from "@mui/icons-material/Schema";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const GraphEditorSubPanelTabs = ({
   panels,
   activeIndex,
   onTabChange,
   onTabClose,
   onReorderTabs,
}) => {
   const [draggedTabIndex, setDraggedTabIndex] = useState(null);
   const [isDragging, setIsDragging] = useState(false);
   const [dragEndTime, setDragEndTime] = useState(0);

   const handleDragStart = useCallback((e, index) => {
      setIsDragging(true);
      setDraggedTabIndex(index);
      e.dataTransfer.effectAllowed = "move";
   }, []);

   const handleDragOver = useCallback(
      (e, index) => {
         e.preventDefault();
         if (draggedTabIndex === null) return;
         if (draggedTabIndex !== index) {
            onReorderTabs(draggedTabIndex, index);
            setDraggedTabIndex(index);
         }
      },
      [draggedTabIndex, onReorderTabs]
   );

   const handleDragEnd = useCallback(() => {
      setIsDragging(false);
      setDraggedTabIndex(null);
      setDragEndTime(Date.now());
   }, []);

   const handleTabClick = useCallback(
      (e, index) => {
         if (Date.now() - dragEndTime > 50) {
            onTabChange(index);
         }
      },
      [dragEndTime, onTabChange]
   );

   return (
      <Tabs
         value={activeIndex}
         variant="scrollable"
         scrollButtons="auto"
         sx={{
            flex: 1,
            "& .MuiTabs-scrollButtons": {
               color: "rgb(100,100,100)",
            },
            "& .MuiTabs-scrollButtons.Mui-disabled": {
               opacity: 0.3, // This sets the opacity for disabled scroll buttons
            },
            height: "20px",
            maxHeight: "20px",
            backgroundColor: "rgb(43,45,48)",
            borderTop: "1px solid rgb(57, 59, 64)",
         }}
         onChange={(event, newValue) => onTabChange(newValue)}
      >
         {panels.map((panel, index) => (
            <Tooltip
               key={panel.id}
               title={panel.data.name}
               arrow
               placement="top"
               enterDelay={250}
            >
               <Tab
                  key={panel.id}
                  disableRipple
                  draggable={true}
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onDragEnd={handleDragEnd}
                  onClick={(e) => handleTabClick(e, index)}
                  label={
                     <TabLabel
                        className={activeIndex === index ? "Mui-selected" : ""}
                     >
                        {panel.data.hasError ? (
                           <ErrorOutlineIcon
                              fontSize="small"
                              style={{
                                 marginRight: "5px",
                                 flexShrink: 0,
                                 color: "rgb(211, 80, 94)",
                              }}
                           />
                        ) : (
                           <span></span>
                        )}
                        <TabText>{panel.data.name}</TabText>
                        <CloseButton
                           onClick={(event) => {
                              event.stopPropagation();
                              onTabClose(index);
                           }}
                        >
                           <CancelIcon
                              fontSize="small"
                              style={{
                                 color: "rgb(74, 74, 74)",
                              }}
                           />
                        </CloseButton>
                     </TabLabel>
                  }
                  style={{ height: "20px" }}
               />
            </Tooltip>
         ))}
      </Tabs>
   );
};

export default GraphEditorSubPanelTabs;
