import React, { useCallback, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SchemaIcon from "@mui/icons-material/Schema";
import TryIcon from "@mui/icons-material/Try";
import WebhookIcon from "@mui/icons-material/Webhook";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ImportExportModal from "./Modals/ImportExportModal";
import { useAuth } from "./Context/AuthContext";

const AppMenuBar = ({
   activePanel,
   setActivePanel,
   isLeftPanelOpen,
   setIsLeftPanelOpen,
   onLogout,
   accessToken,
}) => {
   const { isAuthenticated, logout } = useAuth();
   const [isImportExportModalOpen, setIsImportExportModalOpen] =
      useState(false);

   const menuStyle = {
      backgroundColor: "rgb(34, 34, 34)",
      height: "100vh",
      width: "44px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "start",
      paddingTop: "5px",
      marginBottom: "0px",
      marginTop: "0px",
      marginLeft: "0px",
      marginRight: "0px",
   };

   const iconStyle = {
      color: "rgb(160, 160, 160)",
      padding: "4px",
   };

   const accountIconStyle = {
      ...iconStyle,
      color: "rgba(241, 245, 60, 0.8)",
   };

   const disabledIconStyle = {
      ...iconStyle,
      color: "rgb(52, 58, 62)",
      cursor: "default",
   };

   const activeIconStyle = {
      ...iconStyle,
      color: "rgb(232,232,232)",
      backgroundColor: "rgb(52, 60, 76)",
      borderRadius: "4px",
   };

   const handleIconClick = (panel) => {
      if (activePanel === panel && isLeftPanelOpen) {
         toggleLeftPanel();
      } else {
         onPanelChange(panel);
      }
   };

   const handleAccountIconClick = () => {
      if (isAuthenticated) {
         logout();
      }
      onLogout();
   };

   const toggleLeftPanel = useCallback(() => {
      setIsLeftPanelOpen((prevState) => !prevState);
   }, []);

   const onPanelChange = useCallback(
      (panel) => {
         if (panel === activePanel) {
            toggleLeftPanel();
         } else {
            setActivePanel(panel);
            setIsLeftPanelOpen(true);
         }
      },
      [activePanel, toggleLeftPanel]
   );

   const handleImportExportClick = () => {
      if (isAuthenticated) {
         setIsImportExportModalOpen(true);
      }
   };

   return (
      <>
         <div
            style={{
               ...menuStyle,
               display: "flex",
               flexDirection: "column",
               justifyContent: "space-between",
               height: "100%",
            }}
         >
            <div>
               <Tooltip title="Graphs" placement="right" arrow>
                  <IconButton onClick={() => handleIconClick("edit")}>
                     <SchemaIcon
                        style={
                           activePanel === "edit" && isLeftPanelOpen
                              ? activeIconStyle
                              : iconStyle
                        }
                     />
                  </IconButton>
               </Tooltip>
               <Tooltip title="Graph Execution Logs" placement="right" arrow>
                  <IconButton onClick={() => handleIconClick("logs")}>
                     <VisibilityIcon
                        style={
                           activePanel === "logs" && isLeftPanelOpen
                              ? activeIconStyle
                              : iconStyle
                        }
                     />
                  </IconButton>
               </Tooltip>
               <Tooltip title="Prompts" placement="right" arrow>
                  <IconButton onClick={() => handleIconClick("prompts")}>
                     <TryIcon
                        style={
                           activePanel === "prompts" && isLeftPanelOpen
                              ? activeIconStyle
                              : iconStyle
                        }
                     />
                  </IconButton>
               </Tooltip>

               <Tooltip title="Graph Execution Hooks" placement="right" arrow>
                  <IconButton onClick={() => handleIconClick("hooks")}>
                     <WebhookIcon
                        style={
                           activePanel === "hooks" && isLeftPanelOpen
                              ? activeIconStyle
                              : iconStyle
                        }
                     />
                  </IconButton>
               </Tooltip>
            </div>
            <div
               style={{
                  paddingBottom: "5px",
               }}
            >
               <Tooltip title="Import & Export" placement="right">
                  <IconButton onClick={handleImportExportClick}>
                     <ImportExportIcon
                        style={isAuthenticated ? iconStyle : disabledIconStyle}
                     />
                  </IconButton>
               </Tooltip>

               <Tooltip
                  title={isAuthenticated ? "Sign out" : "Sign in"}
                  placement="right"
               >
                  <IconButton onClick={handleAccountIconClick}>
                     <AccountCircleIcon
                        style={
                           isAuthenticated
                              ? accountIconStyle
                              : disabledIconStyle
                        }
                     />
                  </IconButton>
               </Tooltip>
            </div>
         </div>
         <ImportExportModal
            open={isImportExportModalOpen}
            onClose={() => setIsImportExportModalOpen(false)}
            accessToken={accessToken}
            logout={logout}
         />
      </>
   );
};

export default AppMenuBar;
