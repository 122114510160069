import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
   const [isAuthenticated, setIsAuthenticated] = useState(false);
   const [accessToken, setAccessToken] = useState(null);
   const [refreshToken, setRefreshToken] = useState(null);
   const [expiresIn, setExpiresIn] = useState(null);

   useEffect(() => {
      const storedAccessToken = localStorage.getItem("accessToken");
      const storedRefreshToken = localStorage.getItem("refreshToken");
      const storedexpiresIn = localStorage.getItem("expiresIn");
      if (storedAccessToken && storedRefreshToken && storedexpiresIn) {
         login(storedAccessToken, storedRefreshToken, storedexpiresIn);
      }
   }, []);

   const login = (accessToken, refreshToken, expiresIn) => {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setExpiresIn(expiresIn);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("expiresIn", expiresIn);
      setIsAuthenticated(true);
   };

   const logout = () => {
      setIsAuthenticated(false);
      setAccessToken(null);
      setRefreshToken(null);
      setExpiresIn(null);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expiresIn");
      // Clear session storage on logout
      sessionStorage.removeItem("userTabs");
   };

   const refreshAccessToken = async () => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/auth/refresh`,
            {
               method: "GET",
               headers: {
                  Authorization: `Bearer ${refreshToken}`,
                  "Content-Type": "application/json",
               },
            }
         );
         if (response.status === 200) {
            const data = await response.json();
            login(data.accessToken, data.refreshToken, data.expiresIn);
            // console.log("[R] Access Token:", data.accessToken);
         } else {
            logout();
         }
      } catch (error) {
         console.error("Error refreshing token:", error);
         logout();
      }
   };

   return (
      <AuthContext.Provider
         value={{
            isAuthenticated,
            accessToken,
            refreshToken,
            expiresIn,
            login,
            logout,
            refreshAccessToken,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export const useAuth = () => useContext(AuthContext);
