import React, { useState, useEffect } from "react";
import {
   Modal,
   Box,
   Typography,
   Button,
   CircularProgress,
} from "@mui/material";
import ReactMarkdown from "react-markdown";

const AgentHelpModal = ({
   open,
   onClose,
   instruction,
   data,
   token,
   logout,
}) => {
   const [loading, setLoading] = useState(true);
   const [result, setResult] = useState("");

   useEffect(() => {
      if (open) {
         fetchHelp();
      }
   }, [open]);

   const fetchHelp = async () => {
      setLoading(true);

      // Prepend instructions with note to output markdown
      instruction =
         "Respond in pure Markdown only without backticks. " + instruction;

      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/help/agent`,
            {
               method: "POST",
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
               body: JSON.stringify({ instruction, data }),
            }
         );

         if (!response.ok) {
            if (response.status === 401) {
               logout();
               return;
            }
            throw new Error("Failed to fetch help");
         }

         const result = await response.json();
         setResult(result.response);
      } catch (error) {
         setResult("An error occurred while fetching help. Please try again.");
      } finally {
         setLoading(false);
      }
   };

   return (
      <Modal open={open} onClose={onClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               width: "80%",
               height: "80%",
               bgcolor: "background.paper",
               boxShadow: 24,
               p: 4,
               display: "flex",
               flexDirection: "column",
            }}
         >
            <Typography variant="h6" component="h2" gutterBottom>
               Agent Help
            </Typography>
            <Box sx={{ flexGrow: 1, overflow: "auto", my: 2 }}>
               {loading ? (
                  <Box
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     height="100%"
                  >
                     <CircularProgress />
                  </Box>
               ) : (
                  <Typography variant="body1">
                     <ReactMarkdown>{result}</ReactMarkdown>
                  </Typography>
               )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
               <Button onClick={onClose} variant="contained">
                  Close
               </Button>
            </Box>
         </Box>
      </Modal>
   );
};

export default AgentHelpModal;
