import React, { useCallback, useState, useEffect } from "react";
import { Paper, Tabs, Tab, TabScrollButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TabLabel from "./Components/TabLabel";
import TabText from "./Components/TabText";
import CloseButton from "./Components/CloseButton";
import CancelIcon from "@mui/icons-material/Cancel";
import GraphEditorPanel from "./TabPanels/GraphEditorPanel/GraphEditorPanel";
import GraphExecutionLogPanel from "./TabPanels/GraphExecutionLogPanel/GraphExecutionLogPanel";
import SchemaIcon from "@mui/icons-material/Schema";
import NodeLabel from "./TabPanels/GraphEditorPanel/NodeLabel";
import TabCloseConfirmationDialog from "./Components/TabCloseConfirmationDialog";
import TryIcon from "@mui/icons-material/Try";
import WebhookIcon from "@mui/icons-material/Webhook";
import PromptEditorPanel from "./TabPanels/PromptEditorPanel/PromptEditorPanel";
import { HookEditorPanel } from "./TabPanels/HookEditorPanel/HookEditorPanel";
import { useGraphs } from "./TabPanels/GraphEditorPanel/useGraphs";

const AppTabPanelContainer = ({
   fetchGraphExecutionLog,
   activeTabIndex,
   setActiveTabIndex,
   tabs,
   setTabs,
   token,
   logout,
   fetchAndAddGraphExecutionTab,
   fetchAndAddGraphEditTab,
   setGraphListNeedsRefresh,
   setPromptListNeedsRefresh,
   setHookListNeedsRefresh,
   fetchAndAddPromptEditTab,
   navigate,
   isInitialized,
}) => {
   const [tabViewports, setTabViewports] = useState({});
   const [panelHeights, setPanelHeights] = useState({});
   const [draggedTabIndex, setDraggedTabIndex] = useState(null);
   const [dragEndTime, setDragEndTime] = useState(0);
   const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] =
      useState(false);
   const [tabToClose, setTabToClose] = useState(null);

   useGraphs(token, logout);

   useEffect(() => {
      if (!isInitialized) return;

      if (activeTabIndex !== -1 && tabs[activeTabIndex]) {
         const currentTab = tabs[activeTabIndex];
         if (currentTab.tabType === "GraphEditor") {
            const graphId = currentTab.id.replace("edit-", "");
            navigate(`/graph/${graphId}`, { replace: true });
         } else if (currentTab.tabType === "PromptEditor") {
            const promptId = currentTab.id.replace("prompt-", "");
            navigate(`/prompt/${promptId}`, { replace: true });
         } else if (currentTab.tabType === "HookEditor") {
            const hookId = currentTab.id.replace("hook-", "");
            navigate(`/hook/${hookId}`, { replace: true });
         } else if (currentTab.tabType === "GraphExecutionLog") {
            const graphId = currentTab.id.replace("exec-", "");
            navigate(`/exec/${graphId}`, { replace: true });
         }
      }
   }, [activeTabIndex, tabs, navigate, isInitialized]);

   const handlePanelHeightChange = useCallback((tabId, newHeight) => {
      setPanelHeights((prevHeights) => ({
         ...prevHeights,
         [tabId]: newHeight,
      }));
   }, []);

   const determineNewActiveIndex = (tabs, closedIndex, currentActiveIndex) => {
      if (tabs.length === 0) {
         return -1; // No tabs left
      }

      if (closedIndex < currentActiveIndex) {
         // If we're closing a tab to the left of the active tab,
         // the new active index is one less than it was
         return currentActiveIndex - 1;
      } else if (closedIndex === currentActiveIndex) {
         // If we're closing the active tab
         if (closedIndex === tabs.length) {
            // If it's the last tab, activate the new last tab
            return tabs.length - 1;
         } else {
            // Otherwise, activate the next tab
            return closedIndex;
         }
      }

      // If we're closing a tab to the right of the active tab,
      // the active index doesn't change
      return currentActiveIndex;
   };

   const handleTabClose = useCallback(
      (indexToClose) => {
         const tabToClose = tabs[indexToClose];
         const warnOnCloseTabTypes = [
            "GraphEditor",
            "PromptEditor",
            "HookEditor",
         ];

         if (
            warnOnCloseTabTypes.includes(tabToClose.tabType) &&
            tabToClose.hasUnsavedChanges
         ) {
            setTabToClose(indexToClose);
            setIsCloseConfirmationOpen(true);
         } else {
            closeTab(indexToClose);
         }
      },
      [tabs]
   );

   const closeTab = useCallback(
      (indexToClose) => {
         setTabs((prevTabs) => {
            const newTabs = prevTabs.filter(
               (_, index) => index !== indexToClose
            );
            const newActiveIndex = determineNewActiveIndex(
               newTabs,
               indexToClose,
               activeTabIndex
            );

            setActiveTabIndex(newActiveIndex);

            // Handle navigation after closing
            if (
               newActiveIndex !== -1 &&
               newTabs[newActiveIndex].tabType === "GraphEditor"
            ) {
               const graphId = newTabs[newActiveIndex].id.replace("edit-", "");
               navigate(`/graph/${graphId}`);
            } else {
               navigate("/");
            }

            return newTabs;
         });
      },
      [activeTabIndex, navigate]
   );

   const handleConfirmClose = useCallback(() => {
      closeTab(tabToClose);
      setIsCloseConfirmationOpen(false);
      setTabToClose(null);
   }, [closeTab, tabToClose]);

   const handleNodePanelClose = useCallback((tabIndex, nodeId) => {
      setTabs((prevTabs) => {
         const newTabs = [...prevTabs];
         const targetTab = newTabs[tabIndex];

         const panelIndex = targetTab.nodePanels.findIndex(
            (panel) => panel.id === nodeId
         );
         if (panelIndex !== -1) {
            targetTab.nodePanels = targetTab.nodePanels.filter(
               (_, i) => i !== panelIndex
            );

            if (targetTab.nodePanels.length === 0) {
               targetTab.isPanelOpen = false;
               targetTab.activeNodePanelIndex = -1;
            } else {
               if (targetTab.activeNodePanelIndex === panelIndex) {
                  targetTab.activeNodePanelIndex = Math.max(0, panelIndex - 1);
               } else if (targetTab.activeNodePanelIndex > panelIndex) {
                  targetTab.activeNodePanelIndex--;
               }
            }
         }

         return newTabs;
      });
   }, []);

   const handleNodePanelTabChange = useCallback((tabIndex, nodeId) => {
      setTabs((prevTabs) => {
         const newTabs = [...prevTabs];
         const currentTab = newTabs[tabIndex];

         const existingPanelIndex = currentTab.nodePanels.findIndex(
            (panel) => panel.id === nodeId
         );
         if (existingPanelIndex !== -1) {
            currentTab.activeNodePanelIndex = existingPanelIndex;
         } else {
            const node = currentTab.nodes.find((node) => node.id === nodeId);
            if (node) {
               currentTab.nodePanels.push({ id: nodeId, data: node.data });
               currentTab.activeNodePanelIndex =
                  currentTab.nodePanels.length - 1;
            }
         }

         currentTab.isPanelOpen = true;
         return newTabs;
      });
   }, []);

   const handleDragStart = (e, index) => {
      setActiveTabIndex(index); // Select the tab being dragged
      setDraggedTabIndex(index);
      e.dataTransfer.effectAllowed = "move";
   };

   const handleDragOver = (e, index) => {
      e.preventDefault();
      if (draggedTabIndex === null) return;
      if (draggedTabIndex !== index) {
         reorderTabs(draggedTabIndex, index);
         setDraggedTabIndex(index);
      }
   };

   const handleDragEnd = () => {
      setDraggedTabIndex(null);
      setDragEndTime(Date.now());
   };

   const handleTabClick = (e, index) => {
      if (Date.now() - dragEndTime > 50) {
         setActiveTabIndex(index);
      }
   };

   const reorderTabs = (fromIndex, toIndex) => {
      setTabs((prevTabs) => {
         const newTabs = [...prevTabs];
         const [movedTab] = newTabs.splice(fromIndex, 1);
         newTabs.splice(toIndex, 0, movedTab);
         return newTabs;
      });

      // Update the active tab index if it was affected by the reorder
      setActiveTabIndex((prevActiveIndex) => {
         if (prevActiveIndex === fromIndex) {
            return toIndex;
         } else if (fromIndex < prevActiveIndex && prevActiveIndex <= toIndex) {
            return prevActiveIndex - 1;
         } else if (toIndex <= prevActiveIndex && prevActiveIndex < fromIndex) {
            return prevActiveIndex + 1;
         }
         return prevActiveIndex;
      });
   };

   const updateNodeAndTab = useCallback(
      (nodeId, updatedData) => {
         setTabs((prevTabs) => {
            return prevTabs.map((tab, index) => {
               if (index === activeTabIndex) {
                  const updatedNodes = tab.nodes.map((node) =>
                     node.id === nodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                ...updatedData,
                                label: (
                                   <NodeLabel
                                      name={updatedData.name || node.data.name}
                                      function={
                                         updatedData.function ||
                                         node.data.function
                                      }
                                   />
                                ),
                             },
                          }
                        : node
                  );

                  return {
                     ...tab,
                     nodes: updatedNodes,
                     nodePanels: tab.nodePanels.map((panel) =>
                        panel.id === nodeId
                           ? {
                                ...panel,
                                data: { ...panel.data, ...updatedData },
                             }
                           : panel
                     ),
                  };
               }
               return tab;
            });
         });
      },
      [activeTabIndex]
   );

   const setNodes = useCallback(
      (updater) =>
         setTabs((prevTabs) => {
            const newTabs = [...prevTabs];
            const currentNodes = newTabs[activeTabIndex].nodes;
            newTabs[activeTabIndex].nodes =
               typeof updater === "function" ? updater(currentNodes) : updater;
            return newTabs;
         }),
      [activeTabIndex]
   );

   const setEdges = useCallback(
      (updater) =>
         setTabs((prevTabs) => {
            const newTabs = [...prevTabs];
            const currentEdges = newTabs[activeTabIndex].edges;
            newTabs[activeTabIndex].edges =
               typeof updater === "function" ? updater(currentEdges) : updater;
            return newTabs;
         }),
      [activeTabIndex]
   );

   const tabConfigs = {
      GraphEditor: { icon: SchemaIcon, color: "#4CAF50" },
      GraphExecutionLog: { icon: VisibilityIcon, color: "#2196F3" },
      PromptEditor: { icon: TryIcon, color: "#9C27B0" },
      HookEditor: { icon: WebhookIcon, color: "#bfbf00" },
   };

   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100px",
            backgroundColor: "rgb(30,31,34)",
         }}
      >
         {/* Tab bar */}
         <Paper
            elevation={2}
            style={{
               display: "flex",
               alignItems: "center",
               backgroundColor: "rgb(30,31,34)",
               overflow: "auto",
               borderRadius: 0,
               borderBottom: "1px solid rgb(57, 59, 64)",
            }}
         >
            <Tabs
               value={activeTabIndex}
               variant="scrollable"
               scrollButtons="auto"
               ScrollButtonComponent={TabScrollButton}
               sx={{
                  flex: 1,
                  height: "20px",
                  "& .MuiTabs-scrollButtons": {
                     color: "rgb(100,100,100)",
                  },
                  "& .MuiTabs-scrollButtons.Mui-disabled": {
                     opacity: 0.3, // This sets the opacity for disabled scroll buttons
                  },
               }}
               onChange={(event, _) => {
                  const activeTabId = tabs[activeTabIndex].id;
                  const newActiveIndex = tabs.findIndex(
                     (tab) => tab.id === activeTabId
                  );
                  setActiveTabIndex(newActiveIndex);
               }}
            >
               {tabs.map((tab, index) => {
                  const { icon: IconComponent, color } =
                     tabConfigs[tab.tabType] || {};

                  return (
                     <Tooltip
                        key={tab.id}
                        title={tab.name}
                        arrow
                        placement="bottom"
                        enterDelay={250}
                     >
                        <Tab
                           key={tab.id}
                           draggable={true}
                           onDragStart={(e) => handleDragStart(e, index)}
                           onDragOver={(e) => handleDragOver(e, index)}
                           onDragEnd={handleDragEnd}
                           onClick={(e) => handleTabClick(e, index)}
                           disableRipple
                           sx={{
                              backgroundColor:
                                 activeTabIndex === index
                                    ? "rgb(40,41,44)"
                                    : "rgb(30,31,34)",
                           }}
                           label={
                              <TabLabel
                                 isActive={activeTabIndex === index}
                                 color={color}
                              >
                                 {IconComponent && (
                                    <IconComponent
                                       fontSize="small"
                                       style={{
                                          marginRight: "5px",
                                          flexShrink: 0,
                                          color: color,
                                          opacity:
                                             activeTabIndex === index ? 1 : 0.6,
                                       }}
                                    />
                                 )}
                                 <TabText
                                    // color
                                    style={{
                                       color:
                                          activeTabIndex === index
                                             ? color
                                             : "inherit",
                                    }}
                                 >
                                    {tab.name}
                                 </TabText>
                                 {/*<Tooltip*/}
                                 {/*   title={"Close tab"}*/}
                                 {/*   arrow*/}
                                 {/*   key={tab.id}*/}
                                 {/*   placement="bottom"*/}
                                 {/*   enterDelay={1000}*/}
                                 {/*>*/}
                                 <span className="tab_close_button">
                                    <CloseButton
                                       onClick={(event) => {
                                          event.stopPropagation();
                                          handleTabClose(index);
                                       }}
                                    >
                                       <CancelIcon fontSize="small" />
                                    </CloseButton>
                                 </span>
                                 {/*</Tooltip>*/}
                              </TabLabel>
                           }
                           style={{
                              height: "20px",
                           }}
                        />
                     </Tooltip>
                  );
               })}
            </Tabs>
         </Paper>

         {/* Tab content */}
         <div style={{ flex: 1, position: "relative" }}>
            {tabs.map((tab, index) => (
               <div
                  key={tab.id}
                  style={{
                     position: "absolute",
                     width: "100%",
                     height: "100%",
                     visibility:
                        activeTabIndex === index ? "visible" : "hidden",
                  }}
               >
                  {tab.tabType === "GraphEditor" && (
                     <GraphEditorPanel
                        activeTabIndex={activeTabIndex}
                        index={index}
                        tab={tab}
                        panelHeight={panelHeights[tab.id]}
                        onPanelHeightChange={handlePanelHeightChange}
                        onNodePanelTabChange={(newNodeId) =>
                           handleNodePanelTabChange(activeTabIndex, newNodeId)
                        }
                        onNodePanelClose={(nodeId) =>
                           handleNodePanelClose(activeTabIndex, nodeId)
                        }
                        setNodes={setNodes}
                        setEdges={setEdges}
                        setTabs={setTabs}
                        updateNodeAndTab={updateNodeAndTab}
                        fetchAndAddGraphExecutionTab={
                           fetchAndAddGraphExecutionTab
                        }
                        fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
                        handleTabClose={handleTabClose}
                        setGraphListNeedsRefresh={setGraphListNeedsRefresh}
                        /* auth for api calls */
                        token={token}
                        logout={logout}
                        /* props shared with GraphEditorPanel & reactFlowWithProvider */
                        tabViewports={tabViewports}
                        setTabViewports={setTabViewports}
                        fetchAndAddPromptEditTab={fetchAndAddPromptEditTab}
                     />
                  )}
                  {tab.tabType === "GraphExecutionLog" && (
                     <GraphExecutionLogPanel
                        // value={activeTabIndex}
                        // activeTabIndex={index}
                        activeTabIndex={activeTabIndex}
                        value={index}
                        tab={tab}
                        panelHeight={panelHeights[tab.id]}
                        onPanelHeightChange={handlePanelHeightChange}
                        onNodePanelTabChange={(newIndex) =>
                           handleNodePanelTabChange(index, newIndex)
                        }
                        onNodePanelClose={(panelIndex) =>
                           handleNodePanelClose(index, panelIndex)
                        }
                        setNodes={setNodes}
                        setEdges={setEdges}
                        fetchGraphExecutionLog={fetchGraphExecutionLog}
                        fetchAndAddGraphExecutionTab={
                           fetchAndAddGraphExecutionTab
                        }
                        fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
                        setTabs={setTabs}
                        /* props shared with GraphEditorPanel & reactFlowWithProvider */
                        tabViewports={tabViewports}
                        setTabViewports={setTabViewports}
                        logout={logout}
                        accessToken={token}
                     />
                  )}
                  {tab.tabType === "PromptEditor" && (
                     <PromptEditorPanel
                        activeTabIndex={activeTabIndex}
                        index={index}
                        setTabs={setTabs}
                        token={token}
                        logout={logout}
                        prompt={tab.prompt}
                        handleTabClose={handleTabClose}
                        setPromptListNeedsRefresh={setPromptListNeedsRefresh}
                     />
                  )}
                  {tab.tabType === "HookEditor" && (
                     <HookEditorPanel
                        activeTabIndex={activeTabIndex}
                        index={index}
                        tab={tab}
                        setTabs={setTabs}
                        token={token}
                        hook={tab.hook}
                        logout={logout}
                        handleTabClose={handleTabClose}
                        setHookListNeedsRefresh={setHookListNeedsRefresh}
                        fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
                     />
                  )}
               </div>
            ))}
         </div>
         <TabCloseConfirmationDialog
            open={isCloseConfirmationOpen}
            onClose={() => setIsCloseConfirmationOpen(false)}
            onConfirm={handleConfirmClose}
         />
      </div>
   );
};

export default AppTabPanelContainer;
