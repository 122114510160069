import React, { useCallback, useEffect, useRef, useState } from "react";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import PromptSidePanelToolbar from "./PromptSidePanelToolbar";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

const PromptSidePanel = ({
   token,
   logout,
   isPromptListRefreshing,
   setIsPromptListRefreshing,
   promptListNeedsRefresh,
   setPromptListNeedsRefresh,
   fetchAndAddPromptEditTab,
   openSearchDialog,
}) => {
   const initialFetchDone = useRef(false);
   const [prompts, setPrompts] = useState([]);
   const [isCreating, setIsCreating] = useState(false);

   const fetchPrompts = useCallback(async () => {
      if (isPromptListRefreshing) return;
      setIsPromptListRefreshing(true);
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/prompt`,
            {
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (!response.ok) {
            console.error("fetchPrompts: ", response);
            if (response.status === 401) logout();
            return;
         }

         const data = await response.json();
         setPrompts(data);
      } catch (error) {
         console.error("Error fetching prompts:", error);
      } finally {
         setIsPromptListRefreshing(false);
      }
   }, [
      token,
      logout,
      setPrompts,
      isPromptListRefreshing,
      setIsPromptListRefreshing,
   ]);

   const saveNewTemplate = useCallback(
      async (name, template) => {
         const url = `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/template`;
         const method = "POST";

         const response = await fetch(url, {
            method,
            headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               name: name,
               template: template,
            }),
         });

         if (response.status === 200 || response.status === 201) {
            const data = await response.json();
            return data.id;
         } else if (response.status === 401) {
            logout();
         } else {
            throw new Error(`Failed to save new template`);
         }
      },
      [logout]
   );

   const handleNewPrompt = useCallback(async () => {
      setIsCreating(true);

      // First create a new blank template
      const templateId = await saveNewTemplate(
         "Prompt Template",
         "Enter your prompt here..."
      );

      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/prompt`,
            {
               method: "POST",
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
               body: JSON.stringify({
                  name: "New Prompt",
                  parent_folder_id: null,
                  system_template_id: null,
                  user_template_id: templateId,
                  result_schema_id: null,
                  response_format: "plain",
                  max_tokens: null,
                  temperature: null,
                  seed: null,
                  timeout: null,
                  default_model: null,
               }),
            }
         );

         if (!response.ok) {
            console.error("handleNewPrompt: ", response);
            if (response.status === 401) logout();
            return;
         }

         const data = await response.json();

         if (data.id) {
            await fetchAndAddPromptEditTab(data.id);
            await fetchPrompts();
         } else {
            console.error("Failed to create new prompt:", data);
         }
      } catch (error) {
         console.error("Error creating new prompt:", error);
      } finally {
         setIsCreating(false);
      }
   }, [
      token,
      logout,
      fetchAndAddPromptEditTab,
      fetchPrompts,
      isCreating,
      setIsCreating,
   ]);

   /* Refresh the prompt list if needed */
   useEffect(() => {
      if (promptListNeedsRefresh) {
         fetchPrompts();
         setPromptListNeedsRefresh(false);
      }
   }, [promptListNeedsRefresh, fetchPrompts, setPromptListNeedsRefresh]);

   const leftActions = [
      {
         tooltip: "Refresh",
         onClick: fetchPrompts,
         disabled: isPromptListRefreshing,
         icon: isPromptListRefreshing ? (
            <CircularProgress size={20} color="inherit" />
         ) : (
            <RefreshIcon fontSize="small" />
         ),
      },
      {
         tooltip: "Search Prompts",
         onClick: openSearchDialog,
         icon: <SearchIcon fontSize="small" />,
      },
   ];

   const rightActions = [
      {
         tooltip: "New Prompt",
         onClick: handleNewPrompt,
         disabled: isCreating,
         icon: isCreating ? (
            <CircularProgress size={20} color="inherit" />
         ) : (
            <AddIcon fontSize="small" />
         ),
      },
   ];

   /* Initial fetch, 30s refresh */
   useEffect(() => {
      if (!initialFetchDone.current) {
         fetchPrompts();
         initialFetchDone.current = true;
      }

      // Set up auto-refresh every 30 seconds
      const interval = setInterval(() => {
         fetchPrompts();
      }, 30000);

      // Clean up function to clear the interval when the component unmounts
      return () => clearInterval(interval);
   }, []);

   return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
         <Typography
            variant="h6"
            style={{
               padding: "8px 16px",
               backgroundColor: "rgb(30,31,34)",
               color: "rgb(102,105,111)",
               borderBottom: "1px solid rgb(57,59,64)",
            }}
         >
            Prompts
         </Typography>
         <PromptSidePanelToolbar
            leftActions={leftActions}
            rightActions={rightActions}
         />

         <div style={{ height: "100%", overflow: "auto" }}>
            <List style={{ maxHeight: "100%", padding: "0" }}>
               {prompts.map((entry, index) => (
                  <ListItemButton
                     key={entry.id}
                     onClick={() => fetchAndAddPromptEditTab(entry.id)}
                     style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor:
                           index % 2 === 0
                              ? "rgba(0,0,0,0)"
                              : "rgb(0,0,0,0.12)",
                     }}
                  >
                     <ListItemText
                        primary={entry.name}
                        secondary={new Date(entry.updated_at).toLocaleString()}
                        primaryTypographyProps={{
                           style: {
                              flex: 1,
                              color: "#d8d8d8",
                           },
                        }}
                        secondaryTypographyProps={{
                           style: {
                              color: "#888888",
                           },
                        }}
                     />
                  </ListItemButton>
               ))}
            </List>
         </div>
      </div>
   );
};

export default PromptSidePanel;
