import React, { useState } from "react";
import { Modal, TextField, Button, Box, Typography } from "@mui/material";
import { useAuth } from "../Context/AuthContext";

const LoginModal = ({ open, onClose, onLoginSuccess }) => {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const { login } = useAuth();

   const handleLogin = async (e) => {
      e.preventDefault();
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/auth/signin`,
            {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ email: email, password: password }),
            }
         );
         if (response.ok) {
            const data = await response.json();
            login(data.accessToken, data.refreshToken, data.expiresIn);
            // console.log("[L] Access Token:", data.accessToken);
            onLoginSuccess();
            onClose();
         } else {
            console.error("handleLogin: ", response);
         }
      } catch (error) {
         console.error("handleLogin:", error);
      }
   };

   return (
      <Modal
         open={open}
         onClose={onClose}
         aria-labelledby="login-modal-title"
         aria-describedby="login-modal-description"
      >
         <Box
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               width: "100%",
               height: "100%",
               backgroundColor: "rgba(0, 0, 0, 0.05)",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <Box
               sx={{
                  width: 300,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  p: 4,
               }}
            >
               <Typography
                  id="login-modal-title"
                  variant="h6"
                  component="h2"
                  gutterBottom
               >
                  <center>Prompt Composer</center>
               </Typography>
               <form onSubmit={handleLogin}>
                  <TextField
                     label="Email"
                     type="email"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     required
                     fullWidth
                     margin="normal"
                  />
                  <TextField
                     label="Password"
                     type="password"
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                     required
                     fullWidth
                     margin="normal"
                  />
                  <Button
                     type="submit"
                     variant="contained"
                     fullWidth
                     sx={{ mt: 2 }}
                  >
                     Sign In
                  </Button>
               </form>
            </Box>
         </Box>
      </Modal>
   );
};

export default LoginModal;
