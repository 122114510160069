import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const SaveErrorModal = ({ open, onClose, duplicateNodeNames, saveError }) => {
   return (
      <Dialog open={open} onClose={onClose}>
         <DialogTitle>
            {duplicateNodeNames.length > 0 ? "Warning" : "Error"}
         </DialogTitle>
         <DialogContent>
            <DialogContentText>
               {duplicateNodeNames.length > 0 ? (
                  <>
                     The following node names are duplicated:
                     <ul>
                        {duplicateNodeNames.map((name, index) => (
                           <li key={index}>{name}</li>
                        ))}
                     </ul>
                     Please ensure all node names are unique before saving.
                  </>
               ) : (
                  saveError
               )}
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary">
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default SaveErrorModal;
