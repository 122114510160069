import React from "react";
import {
   Box,
   Typography,
   Accordion,
   AccordionSummary,
   AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MessageContent from "./MessageContent";

const renderPromptExecutionLogs = (logs) => {
   if (!logs || !Array.isArray(logs) || logs.length === 0) {
      return <Typography>No logs available</Typography>;
   }

   return logs
      .map((log, index) => {
         if (!log) {
            console.warn(`Invalid log entry at index ${index}`);
            return null;
         }

         return (
            <Box
               key={index}
               mb={2}
               style={{
                  paddingBottom: "12px",
                  borderBottom: "1px solid rgb(43, 45, 48)",
               }}
            >
               <Typography variant="subtitle2">Attempt {index + 1}</Typography>
               <Accordion
                  defaultExpanded={true}
                  disableGutters={true}
                  style={{
                     backgroundColor: "rgb(43, 45, 48)",
                     color: "rgb(180, 180, 180)",
                  }}
               >
                  <AccordionSummary
                     expandIcon={
                        <ExpandMoreIcon
                           style={{
                              color: "rgb(120, 120, 120)",
                           }}
                        />
                     }
                  >
                     <Typography variant="subtitle1">Input</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     {log.input.messages.map((message, msgIndex) => (
                        <Box key={msgIndex} mb={1}>
                           <MessageContent
                              role={
                                 message?.error
                                    ? "error"
                                    : message?.role ?? "unknown"
                              }
                              content={
                                 message?.error ??
                                 message?.content ??
                                 "No content available"
                              }
                              format="plain"
                           />
                        </Box>
                     ))}
                  </AccordionDetails>
               </Accordion>
               <Accordion
                  defaultExpanded={true}
                  disableGutters={true}
                  style={{
                     backgroundColor: "rgb(43, 45, 48)",
                     color: "rgb(180, 180, 180)",
                  }}
               >
                  <AccordionSummary
                     expandIcon={
                        <ExpandMoreIcon
                           style={{
                              color: "rgb(120, 120, 120)",
                           }}
                        />
                     }
                  >
                     <Typography variant="subtitle1">Output</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <MessageContent
                        role={
                           log?.error
                              ? "error"
                              : log?.output?.choices?.[0]?.message?.role ??
                                "unknown"
                        }
                        content={
                           log?.error ??
                           log?.output?.choices?.[0]?.message?.content ??
                           "No content available"
                        }
                        format={log?.input?.response_format?.type ?? "plain"}
                     />
                  </AccordionDetails>
               </Accordion>
               <Box mt={1}>
                  <Typography variant="caption" display="block">
                     Model: {log.output.model}
                  </Typography>
                  <Typography variant="caption" display="block">
                     Response Format:{" "}
                     {log.input.response_format?.type ?? "Unspecified/plain"}
                  </Typography>
               </Box>
            </Box>
         );
      })
      .filter(Boolean);
};

export default renderPromptExecutionLogs;
