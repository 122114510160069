import React, { useCallback, useState } from "react";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CircularProgress from "@mui/material/CircularProgress";
import GraphEditorSidePanelToolbar from "./GraphEditorSidePanelToolbar";
import GraphImportModal from "../Modals/GraphImportModal";
import SearchIcon from "@mui/icons-material/Search";

const GraphEditorSidePanel = ({
   graphList,
   fetchGraphList,
   fetchAndAddGraphEditTab,
   token,
   logout,
   openSearchDialog,
}) => {
   const [isRefreshing, setIsRefreshing] = useState(false);
   const [isCreating, setIsCreating] = useState(false);
   const [isImportModalOpen, setIsImportModalOpen] = useState(false);

   const handleRefresh = useCallback(async () => {
      setIsRefreshing(true);
      try {
         await fetchGraphList();
      } catch (error) {
         console.error("Error refreshing graph list:", error);
      } finally {
         setIsRefreshing(false);
      }
   }, [fetchGraphList]);

   const handleNewGraph = useCallback(async () => {
      setIsCreating(true);
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph`,
            {
               method: "POST",
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
               body: JSON.stringify({
                  name: "New Graph",
                  nodes: [
                     {
                        name: "New Node",
                        function: "nop",
                        function_params: {},
                        children: [],
                     },
                  ],
               }),
            }
         );

         if (!response.ok) {
            console.error("handleNewGraph: ", response);
            if (response.status === 401) logout();
            return;
         }

         const data = await response.json();
         if (data.success && data.id) {
            await fetchAndAddGraphEditTab(data.id);
            await fetchGraphList();
         } else {
            console.error("Failed to create new graph:", data);
         }
      } catch (error) {
         console.error("Error creating new graph:", error);
      } finally {
         setIsCreating(false);
      }
   }, [
      token,
      logout,
      fetchAndAddGraphEditTab,
      fetchGraphList,
      isCreating,
      setIsCreating,
   ]);

   const leftActions = [
      {
         tooltip: "Refresh",
         onClick: handleRefresh,
         disabled: isRefreshing,
         icon: isRefreshing ? (
            <CircularProgress size={20} color="inherit" />
         ) : (
            <RefreshIcon fontSize="small" />
         ),
      },
      {
         tooltip: "Search Graphs",
         onClick: openSearchDialog,
         icon: <SearchIcon fontSize="small" />,
      },
   ];

   const rightActions = [
      {
         tooltip: "Import Graph",
         onClick: () => setIsImportModalOpen(true),
         icon: <FileUploadIcon fontSize="small" />,
      },
      {
         tooltip: "New Graph",
         onClick: handleNewGraph,
         disabled: isCreating,
         icon: isCreating ? (
            <CircularProgress size={20} color="inherit" />
         ) : (
            <AddIcon fontSize="small" />
         ),
      },
   ];

   return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
         <Typography
            variant="h6"
            style={{
               padding: "8px 16px",
               backgroundColor: "rgb(30,31,34)",
               color: "rgb(102,105,111)",
               borderBottom: "1px solid rgb(57,59,64)",
            }}
         >
            Graphs
         </Typography>
         <GraphEditorSidePanelToolbar
            leftActions={leftActions}
            rightActions={rightActions}
         />
         <div style={{ flexGrow: 1, overflow: "auto" }}>
            <List style={{ padding: "0" }}>
               {graphList.map((graph, index) => (
                  <ListItemButton
                     key={graph.id}
                     onClick={() => fetchAndAddGraphEditTab(graph.id)}
                     style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor:
                           index % 2 === 0
                              ? "rgba(0,0,0,0)"
                              : "rgb(0,0,0,0.12)",
                     }}
                  >
                     <ListItemText
                        primary={graph.name}
                        secondary={new Date(graph.updated_at).toLocaleString()}
                        primaryTypographyProps={{
                           style: {
                              flex: 1,
                              color: "#d8d8d8",
                           },
                        }}
                        secondaryTypographyProps={{
                           style: {
                              color: "#888888",
                           },
                        }}
                     />
                  </ListItemButton>
               ))}
            </List>
         </div>
         <GraphImportModal
            open={isImportModalOpen}
            onClose={() => setIsImportModalOpen(false)}
            token={token}
            logout={logout}
            onImportComplete={(graphIds) => {
               setIsImportModalOpen(false);
               graphIds.forEach((id) => fetchAndAddGraphEditTab(id));
               fetchGraphList();
            }}
         />
      </div>
   );
};

export default GraphEditorSidePanel;
