import { Box, createTheme, Select, TextField } from "@mui/material";
import { height, styled } from "@mui/system";

const darkTheme = createTheme({
   palette: {
      mode: "dark",
      background: {
         paper: "#1e1f22",
         input: "#25262b", // Slightly brighter than paper
      },
   },
});

const StyledForm = styled("form")(({ theme }) => ({
   display: "flex",
   flexDirection: "column",
   gap: theme.spacing(3),
   padding: theme.spacing(3),
   backgroundColor: theme.palette.background.paper,
   color: theme.palette.text.primary,
   height: "100%",
   overflowY: "auto",
}));

const StyledMonacoWrapper = styled(Box)(({ theme }) => ({
   "& .monaco-editor": {
      backgroundColor: theme.palette.background.input,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      overflow: "hidden",
   },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
   "& .MuiOutlinedInput-root": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.input,
      "& fieldset": {
         borderColor: theme.palette.divider,
      },
      "&:hover fieldset": {
         borderColor: theme.palette.primary.main,
      },
      // Add this block for fixed-size font
      "& textarea": {
         fontFamily: "'Roboto Mono', monospace", // or any other monospace font you prefer
         fontSize: "14px", // adjust this value as needed
      },
   },
   "& .MuiInputLabel-root": {
      color: theme.palette.text.secondary,
   },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
   backgroundColor: theme.palette.background.input,
}));

export {
   darkTheme,
   StyledForm,
   StyledMonacoWrapper,
   StyledTextField,
   StyledSelect,
};
