import NodeEditorToolbar from "./NodeEditorToolbar";
import { Grid, Paper, TextField, Typography } from "@mui/material";
import FunctionSelector from "./FunctionSelector";
import FunctionParamsEditor from "./FunctionParamsEditor";
import Editor from "@monaco-editor/react";
import React from "react";
import FunctionsIcon from "@mui/icons-material/Functions";
import KeyIcon from "@mui/icons-material/Key";
import { yamlToJson } from "../../../utils/yaml";

const GraphEditorSubPanel = ({
   isOpen,
   taskList,
   token,
   logout,
   fetchAndAddPromptEditTab,
   fetchAndAddGraphEditTab,
   onSaveNode,
   panel,
}) => {
   const selectedToolbarActionMethod = () => {
      if ("selectedToolbarAction" in panel.data)
         return panel.data.selectedToolbarAction || "function";
      return "function";
   };
   const selectedToolbarAction = selectedToolbarActionMethod();
   const setSelectedToolbarAction = (value) => {
      onSaveNode(
         panel.id,
         { ...panel.data, selectedToolbarAction: value },
         undefined,
         false
      );
   };

   const updateNode = (updatedFields) => {
      onSaveNode(panel.id, { ...updatedFields });
   };

   const setNodeHasError = (field) => {
      updateNode({
         hasErrorsAt: {
            ...panel.data.hasErrorsAt,
            [field]: true,
         },
      });

      const hasError =
         "data" in panel &&
         panel.data instanceof Object &&
         "hasErrorsAt" in panel.data &&
         Object.keys(panel.data.hasErrorsAt).length > 0;

      onSaveNode(panel.id, {}, hasError);
   };

   const setNodeClearError = (field) => {
      if (
         "data" in panel &&
         panel.data instanceof Object &&
         "hasErrorsAt" in panel.data
      ) {
         const hasErrorsAt = panel.data.hasErrorsAt;
         if (hasErrorsAt && field in hasErrorsAt) {
            delete hasErrorsAt[field];
            updateNode({
               hasErrorsAt: hasErrorsAt,
            });
         }
      }

      const hasError =
         "data" in panel &&
         panel.data instanceof Object &&
         "hasErrorsAt" in panel.data &&
         Object.keys(panel.data.hasErrorsAt).length > 0;

      onSaveNode(panel.id, {}, hasError);
   };

   const handleChange = (field) => (event) => {
      // console.log("handleChange", field, event);
      updateNode({ [field]: event.target ? event.target.value : event });
   };

   const handleEditorChange = (field) => (value) => {
      try {
         if (field === "schema") {
            updateNode({ schemaEditorContent: value });
            value = JSON.parse(value);
         } else if (field === "function_params") {
            updateNode({ functionParameterEditorContent: value });
            if (typeof value !== "object") value = yamlToJson(value);
         }
         updateNode({ [field]: value });
         setNodeClearError(field);
      } catch (error) {
         console.error("handleEditorChange: ", error);
         console.log(typeof value);
         console.log(value);
         setNodeHasError(field);
      }
   };

   const toolbarActions = [
      {
         tooltip: "Node, Task & Parameters",
         icon: <FunctionsIcon fontSize="small" />,
         label: "Task",
         selected: selectedToolbarAction === "function",
         color: "rgb(115,168,235)",
         onClick: () => setSelectedToolbarAction("function"),
      },
      {
         tooltip: "Result Schema",
         icon: <KeyIcon fontSize="small" />,
         label: "Schema",
         selected: selectedToolbarAction === "schema",
         color: "rgb(115,168,235)",
         onClick: () => setSelectedToolbarAction("schema"),
      },
   ];

   if (!isOpen) {
      return null;
   }

   return (
      <>
         <NodeEditorToolbar actions={toolbarActions} />
         <Paper
            elevation={3}
            style={{
               height: "calc(100% - 85px)",
               display: "flex",
               flexDirection: "column",
               overflow: "auto",
               borderTop: null,
               borderRadius: "0",
            }}
         >
            <div
               style={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  backgroundColor: "rgb(34,34,34)",
                  height: "100%",
               }}
            >
               {selectedToolbarAction === "function" && (
                  <>
                     <TextField
                        label="Name"
                        value={panel.data.name}
                        onChange={handleChange("name")}
                        variant="outlined"
                        size="small"
                        fullWidth
                     />
                     <Grid container spacing={2}>
                        <Grid item xs={6}>
                           <FunctionSelector
                              taskList={taskList}
                              selectedFunction={panel.data.function}
                              onChange={handleChange("function")}
                              disabled={
                                 panel.data.hasErrorsAt &&
                                 Object.keys(panel.data.hasErrorsAt).length > 0
                              }
                           />
                        </Grid>
                        <Grid item xs={6}>
                           {taskList &&
                           panel.data.function &&
                           panel.data.function !== "" &&
                           taskList.find(
                              (task) => task.function === panel.data.function
                           ).response_name_enabled === true ? (
                              <TextField
                                 label="Response Key (optional)"
                                 value={panel.data.response_name}
                                 onChange={handleChange("response_name")}
                                 variant="outlined"
                                 size="small"
                                 fullWidth
                                 disabled={
                                    !taskList ||
                                    !panel.data.function ||
                                    panel.data.function === "" ||
                                    (taskList &&
                                       taskList.find(
                                          (task) =>
                                             task.function ===
                                             panel.data.function
                                       ).response_name_enabled === false)
                                 }
                                 inputProps={{
                                    readOnly:
                                       !taskList ||
                                       !panel.data.function ||
                                       panel.data.function === "" ||
                                       (taskList &&
                                          taskList.find(
                                             (task) =>
                                                task.function ===
                                                panel.data.function
                                          ).response_name_enabled === false),
                                 }}
                              />
                           ) : null}
                        </Grid>
                     </Grid>
                     <Typography
                        variant="subtitle2"
                        style={{
                           color: "rgb(180,180,180)",
                        }}
                     >
                        Task Parameters
                     </Typography>
                     <FunctionParamsEditor
                        selectedFunction={taskList.find(
                           (task) => task.function === panel.data.function
                        )}
                        value={
                           panel.data.functionParameterEditorContent ||
                           panel.data.function_params
                        }
                        onChange={handleEditorChange("function_params")}
                        token={token}
                        logout={logout}
                        fetchAndAddPromptEditTab={fetchAndAddPromptEditTab}
                        fetchAndAddGraphEditTab={fetchAndAddGraphEditTab}
                     />
                  </>
               )}

               {selectedToolbarAction === "schema" && (
                  <>
                     <Typography
                        variant="subtitle2"
                        style={{ color: "rgb(180,180,180)" }}
                     >
                        Result JSON Schema
                     </Typography>
                     <div
                        style={{
                           border: "1px solid rgba(198, 198, 198, 0.23)",
                           height: "calc(100% - 40px)",
                        }}
                     >
                        <Editor
                           language="json"
                           value={
                              panel.data.schemaEditorContent ||
                              JSON.stringify(panel.data.schema || {}, null, 2)
                           }
                           onChange={handleEditorChange("schema")}
                           theme="vs-dark"
                           options={{
                              minimap: { enabled: false },
                              scrollBeyondLastLine: false,
                              fontSize: 12,
                           }}
                        />
                     </div>
                  </>
               )}
            </div>
         </Paper>
      </>
   );
};

export default GraphEditorSubPanel;
