import React from "react";

const NodeLabel = ({ name, function: nodeFunction }) => (
   <div
      style={{
         padding: "10px",
         fontSize: "12px",
      }}
   >
      <div
         style={{
            fontWeight: "bold",
            marginBottom: "5px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
         }}
      >
         {name}
      </div>
      <div
         style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
         }}
      >
         {nodeFunction}
      </div>
   </div>
);

export default NodeLabel;
