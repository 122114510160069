import React, { useState } from "react";
import {
   Modal,
   Box,
   Tabs,
   Tab,
   Button,
   Typography,
   Checkbox,
   FormControlLabel,
   CircularProgress,
   Icon,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const ImportExportModal = ({ open, onClose, accessToken, logout }) => {
   const [tabValue, setTabValue] = useState(0);
   const [file, setFile] = useState(null);
   const [deleteBeforeInsert, setDeleteBeforeInsert] = useState(true);
   const [isLoading, setIsLoading] = useState(false);

   const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
   };

   const handleExport = async () => {
      setIsLoading(true);
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/export`,
            {
               method: "GET",
               headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (response.status === 200) {
            const data = await response.json();
            const blob = new Blob([JSON.stringify(data, null, 2)], {
               type: "application/json",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            const unix_ts = new Date().getTime();
            const filename_with_datetime = `export-${unix_ts}`;
            a.href = url;
            a.download = `${filename_with_datetime}.json`;
            a.click();
            URL.revokeObjectURL(url);
         } else if (response.status === 401) {
            logout();
         }
      } catch (error) {
         console.error("Export error:", error);
         alert("Export failed. Please try again.");
      } finally {
         setIsLoading(false);
      }
   };

   const handleImport = async () => {
      setIsLoading(true);

      try {
         const jsonString = await file.text();
         const delete_existing = deleteBeforeInsert ? 1 : 0;
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/import?delete_existing=${delete_existing}`,
            {
               method: "POST",
               headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
               },
               body: jsonString,
            }
         );

         if (!response.ok) {
            if (response.status === 401) {
               logout();
               return;
            }
            const errorData = await response.json();
            throw new Error(errorData.error || "Import failed");
         }
         alert("Import successful. Please refresh the page to see changes.");
         window.location.reload();
      } catch (error) {
         alert("Import failed");
      } finally {
         setIsLoading(false);
      }
   };

   const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles) => {
         setFile(acceptedFiles[0]);
      },
      multiple: false,
   });

   return (
      <Modal open={open} onClose={onClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               width: 400,
               bgcolor: "background.paper",
               boxShadow: 24,
               p: 4,
               display: "flex",
               flexDirection: "column",
               height: "80%",
               maxHeight: 600,
            }}
         >
            <Tabs value={tabValue} onChange={handleTabChange}>
               <Tab label="Export" />
               <Tab label="Import" />
            </Tabs>

            <Box sx={{ flex: 1, mt: 2, overflowY: "auto" }}>
               {tabValue === 0 && (
                  <Box>
                     <Typography>
                        Click the "Export" button below to export all graphs,
                        prompts, hooks and their dependencies.
                     </Typography>
                     <Typography
                        style={{
                           paddingTop: "10px",
                        }}
                     >
                        Execution log data are not exported.
                     </Typography>
                  </Box>
               )}

               {tabValue === 1 && (
                  <Box>
                     <div
                        {...getRootProps()}
                        style={{
                           border: "2px dashed #ccc",
                           borderRadius: "4px",
                           padding: "20px",
                           textAlign: "center",
                           cursor: "pointer",
                        }}
                     >
                        <input {...getInputProps()} />
                        <Typography>
                           {file
                              ? `File selected: ${file.name}`
                              : "Drag and drop a file here, or click to select a file"}
                        </Typography>
                     </div>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={deleteBeforeInsert}
                              onChange={(e) =>
                                 setDeleteBeforeInsert(e.target.checked)
                              }
                           />
                        }
                        label="Delete before insert"
                     />
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           backgroundColor: "rgba(82, 92,87, 1.0)",
                           borderRadius: 1,
                           padding: 2,
                           marginTop: 2,
                        }}
                     >
                        <PriorityHighIcon
                           color="error"
                           sx={{ marginRight: 1 }}
                        />
                        <Typography variant="body2" color="rgb(225, 225, 225)">
                           Existing execution log data is deleted on all
                           imports.
                        </Typography>
                     </Box>
                  </Box>
               )}
            </Box>

            <Box
               sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
               <Button
                  onClick={onClose}
                  variant="outlined"
                  disabled={isLoading}
               >
                  Cancel
               </Button>
               {tabValue === 0 ? (
                  <Button
                     onClick={handleExport}
                     variant="contained"
                     disabled={isLoading}
                  >
                     {isLoading ? <CircularProgress size={24} /> : "Export"}
                  </Button>
               ) : (
                  <Button
                     onClick={handleImport}
                     variant="contained"
                     disabled={!file || isLoading}
                  >
                     {isLoading ? <CircularProgress size={24} /> : "Import"}
                  </Button>
               )}
            </Box>
         </Box>
      </Modal>
   );
};

export default ImportExportModal;
