import React, { useCallback, useState, useEffect } from "react";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import GraphExecutionHooksToolbar from "./GraphExecutionHooksToolbar";
import { useHookServices } from "../services/useHookServices";

const GraphExecutionHooks = ({
   tabs,
   setTabs,
   setActiveTabIndex,
   hookListNeedsRefresh,
   setHookListNeedsRefresh,
}) => {
   const { fetchHooks, fetchHookById, loading } = useHookServices();
   const [hooks, setHooks] = useState([]);

   const fetchHook = useCallback(
      async (hookId) => {
         const response = await fetchHookById(hookId);
         return response;
      },
      [fetchHookById]
   );

   const fetchHookList = useCallback(async () => {
      const hookList = await fetchHooks();
      setHooks(hookList);
   }, []);

   const fetchAndAddHookEditTab = useCallback(
      async (hookId) => {
         const newHookId = `hook-${hookId ? hookId : tabs.length + 1}`;

         const existingTabIndex = tabs.findIndex((tab) => tab.id === newHookId);

         if (existingTabIndex !== -1) {
            setActiveTabIndex(existingTabIndex);
            return;
         }
         let newTabObject = {
            id: newHookId,
            name: "Add new hook",
            nodes: [],
            edges: [],
            nodePanels: [],
            hook: [],
            activeNodePanelIndex: 0,
            isPanelOpen: false,
            tabType: "HookEditor",
         };
         if (hookId) {
            const hookData = await fetchHook(hookId);
            if (!hookData) return;

            newTabObject = {
               ...newTabObject,
               name: hookData.name,
               hook: hookData,
            };
         }

         setTabs((prevTabs) => {
            const newTabs = [...prevTabs, newTabObject];
            setActiveTabIndex(newTabs.length - 1);
            return newTabs;
         });
      },
      [tabs, setTabs, fetchHook, setActiveTabIndex]
   );

   const handleNewHook = useCallback(async () => {
      await fetchAndAddHookEditTab();
   }, [fetchAndAddHookEditTab]);

   useEffect(() => {
      if (hookListNeedsRefresh) {
         fetchHookList();
         setHookListNeedsRefresh(false);
      }
   }, [hookListNeedsRefresh, setHookListNeedsRefresh]);

   useEffect(() => {
      fetchHookList();
   }, []);

   const leftActions = [
      {
         tooltip: "Refresh",
         onClick: fetchHookList,
         disabled: loading,
         icon: loading ? (
            <CircularProgress size={20} color="inherit" />
         ) : (
            <RefreshIcon fontSize="small" />
         ),
      },
   ];

   const rightActions = [
      {
         tooltip: "New Hook",
         onClick: handleNewHook,
         disabled: loading,
         icon: loading ? (
            <CircularProgress size={20} color="inherit" />
         ) : (
            <AddIcon fontSize="small" />
         ),
      },
   ];

   return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
         <Typography
            variant="h6"
            style={{
               padding: "8px 16px",
               backgroundColor: "rgb(30,31,34)",
               color: "rgb(102,105,111)",
               borderBottom: "1px solid rgb(57,59,64)",
            }}
         >
            Hooks
         </Typography>
         <GraphExecutionHooksToolbar
            leftActions={leftActions}
            rightActions={rightActions}
         />

         <div style={{ height: "100%", overflow: "auto" }}>
            <List style={{ maxHeight: "100%", padding: "0" }}>
               {!!hooks.length &&
                  hooks.map((entry, index) => (
                     <ListItemButton
                        key={entry.id}
                        onClick={() => fetchAndAddHookEditTab(entry.uuid)}
                        style={{
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                           backgroundColor:
                              index % 2 === 0
                                 ? "rgba(0,0,0,0)"
                                 : "rgb(0,0,0,0.12)",
                        }}
                     >
                        <ListItemText
                           primary={entry.name}
                           secondary={new Date(
                              entry.updated_at
                           ).toLocaleString()}
                           primaryTypographyProps={{
                              style: {
                                 flex: 1,
                                 color: "#d8d8d8",
                              },
                           }}
                           secondaryTypographyProps={{
                              style: {
                                 color: "#888888",
                              },
                           }}
                        />
                     </ListItemButton>
                  ))}
            </List>
         </div>
      </div>
   );
};

export default GraphExecutionHooks;
