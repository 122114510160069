import yaml from "js-yaml";

function jsonToYaml(jsonObj) {
   if (!jsonObj || Object.keys(jsonObj).length === 0) return "";

   return yaml.dump(jsonObj, {
      indent: 2,
      noRefs: true,
      forceQuotes: false,
      noCompatMode: true,
      lineWidth: -1,
      // quotingType: '"',
      flowLevel: -1,
   });
}

function yamlToJson(yamlString) {
   return yaml.load(yamlString, { json: true });
}

export { jsonToYaml, yamlToJson };
