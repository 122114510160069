import React from "react";
import { Form, Templates } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { Editor } from "@monaco-editor/react";
import { jsonToYaml, yamlToJson } from "../../../utils/yaml";
import SelectPromptWidget from "./SelectPromptWidget";
import SelectModelWidget from "./SelectModelWidget";
import SelectGraphWidget from "./SelectGraphWidget";

const TwoColumnFieldTemplate = (props) => {
   const {
      id,
      classNames,
      label,
      help,
      required,
      description,
      errors,
      children,
   } = props;
   return (
      <div className={`${classNames} two-column-field`}>
         <label htmlFor={id}>
            {label}
            {required ? "*" : null}
         </label>
         {description}
         {children}
         {errors}
         {help}
      </div>
   );
};

const TwoColumnObjectFieldTemplate = ({ properties, uiSchema, idSchema }) => {
   return (
      <div className="two-column-form">
         {properties.map((prop, index) => (
            <div key={prop.name} className="form-field">
               {prop.content}
            </div>
         ))}
      </div>
   );
};

const { BaseInputTemplate } = Templates;

const MyBaseInputTemplate = (props) => {
   return (
      <BaseInputTemplate
         {...props}
         size="small"
         inputProps={{
            style: {
               margin: 0,
            },
         }}
         InputProps={{
            className: "rjsf-input",
            classes: {
               root: "rjsf-input-root",
            },
         }}
      />
   );
};

const FunctionParamsEditor = ({
   selectedFunction,
   value,
   onChange,
   token,
   logout,
   fetchAndAddPromptEditTab,
   fetchAndAddGraphEditTab,
}) => {
   const paramSchema = selectedFunction?.schema;
   const baseUiSchema = selectedFunction?.ui_schema || {};
   const uiSchema = {
      ...baseUiSchema,
      "ui:FieldTemplate": TwoColumnFieldTemplate,
      "ui:ObjectFieldTemplate": TwoColumnObjectFieldTemplate,
      "ui:submitButtonOptions": { norender: true },
      "ui:classNames": "p-0",
   };

   const handleChange = (formData) => {
      onChange(formData);
   };

   try {
      if (paramSchema && Object.keys(paramSchema).length > 0) {
         const formData = typeof value === "string" ? yamlToJson(value) : value;
         return (
            <>
               <style>
                  {`
                     .two-column-form {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 16px;
                     }
                     .form-field {
                        margin-bottom: 16px;
                     }
                     .two-column-field {
                        display: flex;
                        flex-direction: column;
                     }
                     .rjsf-input-root {
                        width: 100%;
                     }
                  `}
               </style>
               <Form
                  schema={paramSchema}
                  uiSchema={uiSchema}
                  formData={formData}
                  onChange={({ formData }) => handleChange(formData)}
                  validator={validator}
                  templates={{
                     BaseInputTemplate: MyBaseInputTemplate,
                     ObjectFieldTemplate: TwoColumnObjectFieldTemplate,
                  }}
                  widgets={{
                     SelectPromptWidget: (props) => (
                        <SelectPromptWidget
                           {...props}
                           token={token}
                           logout={logout}
                           launch={fetchAndAddPromptEditTab}
                        />
                     ),
                     SelectModelWidget: (props) => (
                        <SelectModelWidget
                           {...props}
                           token={token}
                           logout={logout}
                        />
                     ),
                     SelectGraphWidget: (props) => (
                        <SelectGraphWidget
                           {...props}
                           token={token}
                           logout={logout}
                           launch={fetchAndAddGraphEditTab}
                        />
                     ),
                  }}
               />
            </>
         );
      } else {
         return (
            <div
               style={{
                  border: "1px solid rgba(198, 198, 198, 0.23)",
               }}
            >
               <Editor
                  height="200px"
                  language="yaml"
                  value={typeof value === "string" ? value : jsonToYaml(value)}
                  onChange={(newValue) => {
                     onChange(newValue);
                  }}
                  theme="vs-dark"
                  options={{
                     minimap: { enabled: false },
                     scrollBeyondLastLine: false,
                     fontSize: 12,
                  }}
               />
            </div>
         );
      }
   } catch (error) {
      console.error(error);
      console.log("value", value);
      return <></>;
   }
};

export default FunctionParamsEditor;
