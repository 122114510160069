import React, { useState, useCallback } from "react";
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   TextField,
   ThemeProvider,
   createTheme,
} from "@mui/material";
import Editor from "@monaco-editor/react";
import { yamlToJson } from "../../../utils/yaml";

const darkTheme = createTheme({
   palette: {
      mode: "dark",
      background: {
         paper: "#1e1e1e",
      },
   },
});

const GraphExecutionDialog = ({
   open,
   onClose,
   onExecute,
   inputData,
   setInputData,
}) => {
   const [bearerToken, setBearerToken] = useState("");
   const [callbackUrl, setCallbackUrl] = useState("");
   const [yamlError, setYamlError] = useState(null);

   const handleExecute = useCallback(() => {
      if (yamlError) return;

      let parsedInputData;
      try {
         parsedInputData = yamlToJson(inputData);
      } catch (error) {
         setYamlError("Invalid YAML");
         return;
      }

      onExecute({
         input_data: parsedInputData,
         bearer_token: bearerToken || null,
         callback_url: callbackUrl || null,
      });
   }, [bearerToken, callbackUrl, inputData, yamlError, onExecute]);

   const handleEditorChange = (value) => {
      setInputData(value);
      setYamlError(null);
   };

   const handleEditorValidation = (markers) => {
      if (markers.length > 0) {
         setYamlError("YAML syntax error");
      } else {
         setYamlError(null);
      }
   };

   return (
      <ThemeProvider theme={darkTheme}>
         <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
               style: {
                  backgroundColor: "#1e1e1e",
               },
            }}
         >
            <DialogTitle style={{ color: "#e8e8e8" }}>
               Graph Execution Parameters
            </DialogTitle>
            <DialogContent>
               <p style={{ color: "#b3b3b3" }}>
                  Input data (YAML format). The data will be passed to the
                  execution graph as input.
               </p>
               <Editor
                  height="300px"
                  defaultLanguage="yaml"
                  theme="vs-dark"
                  value={inputData}
                  onChange={handleEditorChange}
                  onValidate={handleEditorValidation}
                  options={{
                     minimap: { enabled: false },
                  }}
               />
               {yamlError && <p style={{ color: "red" }}>{yamlError}</p>}
               <TextField
                  label="Callback URL (optional)"
                  fullWidth
                  margin="normal"
                  value={callbackUrl}
                  onChange={(e) => setCallbackUrl(e.target.value)}
                  InputLabelProps={{
                     style: { color: "#b3b3b3" },
                  }}
                  InputProps={{
                     style: { color: "#ffffff" },
                  }}
               />
               <TextField
                  label="Bearer Token (optional)"
                  fullWidth
                  margin="normal"
                  value={bearerToken}
                  onChange={(e) => setBearerToken(e.target.value)}
                  InputLabelProps={{
                     style: { color: "#b3b3b3" },
                  }}
                  InputProps={{
                     style: { color: "#ffffff" },
                  }}
               />
            </DialogContent>
            <DialogActions>
               <Button onClick={onClose} style={{ color: "#b3b3b3" }}>
                  Cancel
               </Button>
               <Button
                  onClick={handleExecute}
                  disabled={!!yamlError}
                  style={{ color: yamlError ? "#666666" : "#4caf50" }}
               >
                  Run
               </Button>
            </DialogActions>
         </Dialog>
      </ThemeProvider>
   );
};

export default GraphExecutionDialog;
