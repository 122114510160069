import React from "react";
import { MenuItem, IconButton, Box, Tooltip, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useModels } from "./useModels";

const SelectModelWidget = ({ value, onChange, disabled, token, logout }) => {
   const { models, isLoading, fetchModels } = useModels(token, logout);

   const handleChange = (event) => {
      onChange(event.target.value);
   };

   const handleRefresh = () => {
      fetchModels(true);
   };

   return (
      (models && (
         <>
            <Box display="flex" alignItems="center" width="100%">
               <TextField
                  select
                  label="Model"
                  // displayEmpty
                  value={value || ""}
                  onChange={handleChange}
                  disabled={disabled || isLoading}
                  size="small"
                  options={models.map((model) => model.id)}
                  fullWidth
               >
                  {models &&
                     models.map((model) => (
                        <MenuItem key={model} value={model}>
                           {model}
                        </MenuItem>
                     ))}
               </TextField>
               <Tooltip
                  title={isLoading ? "Loading models..." : "Refresh models"}
               >
                  <span>
                     <IconButton
                        onClick={handleRefresh}
                        disabled={isLoading}
                        size="small"
                        sx={{ ml: 1 }}
                     >
                        <RefreshIcon fontSize="small" />
                     </IconButton>
                  </span>
               </Tooltip>
            </Box>
         </>
      )) ||
      null
   );
};

export default SelectModelWidget;
