import React, { useState, useCallback } from "react";
import {
   MenuItem,
   Select,
   FormControl,
   InputLabel,
   Box,
   Typography,
} from "@mui/material";
import GenericSearchDialog from "./GenericSearchDialog";

const GraphSearchDialog = ({ isOpen, onClose, onSelect, token, logout }) => {
   const [sortBy, setSortBy] = useState("updated_at");
   const [sortOrder, setSortOrder] = useState("desc");

   const handleSearch = useCallback(
      async (searchTerm) => {
         try {
            const response = await fetch(
               `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/graph?name=${encodeURIComponent(searchTerm)}&sort_key=${sortBy}&sort_order=${sortOrder}`,
               {
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            if (!response.ok) {
               console.error("Search graphs error: ", response);
               if (response.status === 401) logout();
               return [];
            }

            const data = await response.json();
            return data;
         } catch (error) {
            console.error("Error searching graphs:", error);
            return [];
         }
      },
      [token, logout, sortBy, sortOrder]
   );

   const renderListItem = (item) => (
      <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         width="100%"
      >
         <Box
            onClick={() => onSelect(item)}
            sx={{ cursor: "pointer", flexGrow: 1 }}
         >
            <Typography variant="subtitle1" component="div">
               {item.name}
               <Typography variant={"body2"} color="text.secondary">
                  Updated: {new Date(item.updated_at).toLocaleString()},
                  Created: {new Date(item.created_at).toLocaleString()}, ID:{" "}
                  {item.id}
               </Typography>
            </Typography>
         </Box>
         {/*<IconButton*/}
         {/*   onClick={() => fetchAndAddGraphEditTab(item.id)}*/}
         {/*   size="small"*/}
         {/*>*/}
         {/*   <TryIcon fontSize="small" />*/}
         {/*</IconButton>*/}
      </Box>
   );

   const additionalControls = () => (
      <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
         <FormControl variant="outlined" sx={{ width: "48%" }}>
            <InputLabel>Sort By</InputLabel>
            <Select
               value={sortBy}
               onChange={(e) => setSortBy(e.target.value)}
               label="Sort By"
            >
               <MenuItem value="id">ID</MenuItem>
               <MenuItem value="updated_at">Last Updated</MenuItem>
               <MenuItem value="name">Name</MenuItem>
               <MenuItem value="created_at">Created Date</MenuItem>
            </Select>
         </FormControl>
         <FormControl variant="outlined" sx={{ width: "48%" }}>
            <InputLabel>Sort Order</InputLabel>
            <Select
               value={sortOrder}
               onChange={(e) => setSortOrder(e.target.value)}
               label="Sort Order"
            >
               <MenuItem value="desc">Descending</MenuItem>
               <MenuItem value="asc">Ascending</MenuItem>
            </Select>
         </FormControl>
      </Box>
   );

   return (
      <GenericSearchDialog
         isOpen={isOpen}
         onClose={onClose}
         onSearch={handleSearch}
         title="Search Graphs"
         placeholder="Enter graph name or keywords"
         renderListItem={renderListItem}
         additionalControls={additionalControls}
         closeOnSelect={true}
      />
   );
};

export default GraphSearchDialog;
