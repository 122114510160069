import React, { useState, useEffect, useCallback } from "react";
import PromptEditorToolbar from "./PromptEditorToolbar";
import {
   MenuItem,
   FormControl,
   InputLabel,
   ThemeProvider,
   Grid,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
   darkTheme,
   StyledForm,
   StyledTextField,
   StyledSelect,
} from "./PromptEditorPanelStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationDialog from "../../Components/DeleteConfirmationDialog";
import DataObjectIcon from "@mui/icons-material/DataObject";
import JSONInfoDialog from "../../Components/JSONInfoDialog";
import SelectModelWidget from "../GraphEditorPanel/SelectModelWidget";

const PromptEditorPanel = ({
   prompt,
   activeTabIndex,
   index,
   setTabs,
   token,
   logout,
   setPromptListNeedsRefresh,
   handleTabClose,
}) => {
   const [localPrompt, setLocalPrompt] = useState(prompt);
   const [isDirty, setIsDirty] = useState(false);
   const [isInputJsonValid, setIsInputJsonValid] = useState(true);
   const [isResultJsonValid, setIsResultJsonValid] = useState(true);
   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
   const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);

   const updateIsDirty = useCallback(
      (value) => {
         setIsDirty(value);
         setTabs((prevTabs) =>
            prevTabs.map((t, i) =>
               i === index ? { ...t, hasUnsavedChanges: value } : t
            )
         );
      },
      [setIsDirty, setTabs, index]
   );

   useEffect(() => {
      setLocalPrompt(prompt);
   }, [prompt]);

   const handleSave = useCallback(async () => {
      if (isDirty && isInputJsonValid && isResultJsonValid) {
         try {
            // Update or create system template
            const systemTemplateId = await saveTemplate(
               localPrompt.system_template,
               "system"
            );

            // Update or create user template
            const userTemplateId = await saveTemplate(
               localPrompt.user_template,
               "user"
            );

            // Prepare prompt data
            console.log(localPrompt);
            const promptData = {
               name: localPrompt.name,
               parent_folder_id: localPrompt.parent_folder_id,
               system_template_id: systemTemplateId,
               user_template_id: userTemplateId,
               result_schema_id: localPrompt.result_schema_id,
               response_format: localPrompt.response_format,
               max_tokens: localPrompt.max_tokens,
               default_model: localPrompt.default_model,
               timeout: localPrompt.timeout,
               temperature: localPrompt.temperature,
               seed: localPrompt.seed,
            };

            // Update or create prompt
            await savePrompt(promptData);

            updateIsDirty(false);
         } catch (error) {
            console.error("Error saving prompt:", error);
         }
      }
   }, [isDirty, isInputJsonValid, isResultJsonValid, localPrompt]);

   const saveTemplate = async (template, type) => {
      if (!template || !template.template) return null;

      const endpoint = `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/template`;
      const method = template.id ? "PUT" : "POST";
      const url = template.id ? `${endpoint}/${template.id}` : endpoint;

      const response = await fetch(url, {
         method,
         headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            name: `${localPrompt.name} ${type} template`,
            template: template.template,
         }),
      });

      if (response.status === 200 || response.status === 201) {
         const data = await response.json();
         return data.id;
      } else if (response.status === 401) {
         logout();
      } else {
         throw new Error(`Failed to save ${type} template`);
      }
   };

   const handleInfoClick = useCallback(() => {
      setIsInfoDialogOpen(true);
   }, []);

   const savePrompt = async (promptData) => {
      const endpoint = `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/prompt`;
      const method = localPrompt.id ? "PUT" : "POST";
      const url = localPrompt.id ? `${endpoint}/${localPrompt.id}` : endpoint;

      const response = await fetch(url, {
         method,
         headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
         },
         body: JSON.stringify(promptData),
      });

      if (response.status === 200 || response.status === 201) {
         const data = await response.json();
         setLocalPrompt({ ...localPrompt, id: data.id });
         setPromptListNeedsRefresh(true);
      } else if (response.status === 401) {
         logout();
      } else {
         throw new Error("Failed to save prompt");
      }
   };

   const validateJson = (jsonString, schemaType) => {
      try {
         JSON.parse(jsonString);
         if (schemaType === "input") {
            setIsInputJsonValid(true);
         } else if (schemaType === "result") {
            setIsResultJsonValid(true);
         }
      } catch (error) {
         if (schemaType === "input") {
            setIsInputJsonValid(false);
         } else if (schemaType === "result") {
            setIsResultJsonValid(false);
         }
      }
   };

   const handleDelete = useCallback(async () => {
      try {
         const response = await fetch(
            `${process.env.REACT_APP_PROMPT_COMPOSER_API_URL}/prompt/${localPrompt.id}`,
            {
               method: "DELETE",
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );

         if (response.status === 200) {
            handleTabClose(activeTabIndex);
            setPromptListNeedsRefresh(true);
         } else if (response.status === 401) {
            logout();
         } else {
            console.error("Delete prompt error:", response.status);
         }
      } catch (error) {
         console.error("Delete prompt error:", error);
      } finally {
         setIsDeleteDialogOpen(false);
      }
   }, [
      localPrompt,
      token,
      logout,
      handleTabClose,
      activeTabIndex,
      setPromptListNeedsRefresh,
   ]);

   const handleRename = useCallback(
      (newName) => {
         setTabs((prevTabs) =>
            prevTabs.map((t, i) =>
               i === index
                  ? {
                       ...t,
                       name: newName,
                    }
                  : t
            )
         );
      },
      [setTabs, index]
   );

   const handleChange = (field, value) => {
      const updatedPrompt = { ...localPrompt };

      if (field.includes(".")) {
         const [parent, child] = field.split(".");
         if (!updatedPrompt[parent]) {
            updatedPrompt[parent] = {};
         }
         updatedPrompt[parent][child] = value === "" ? null : value;
      } else {
         updatedPrompt[field] = value === "" ? null : value;
      }

      setLocalPrompt(updatedPrompt);
      updateIsDirty(true);

      if (field === "name") {
         handleRename(value);
      } else if (field === "input_schema") {
         validateJson(value, "input");
      } else if (field === "result_schema") {
         validateJson(value, "result");
      }
   };

   const leftToolbarActions = [
      {
         tooltip: "Prompt Data",
         onClick: handleInfoClick,
         icon: <DataObjectIcon fontSize="small" />,
      },
      {
         tooltip: "Delete Prompt",
         onClick: () => setIsDeleteDialogOpen(true),
         icon: <DeleteIcon fontSize="small" />,
         disabled: isDirty,
         color: "rgb(161,91,88)",
      },
   ];

   const toolbarActions = [
      {
         tooltip: "Errors found",
         icon: <ErrorOutlineIcon fontSize="small" />,
         disabled: isInputJsonValid && isResultJsonValid,
         invisible: isInputJsonValid && isResultJsonValid,
         image_only: true,
         color: "rgb(211, 80, 94)",
      },
      {
         tooltip: "Save Changes",
         onClick: handleSave,
         icon: <SaveIcon fontSize="small" />,
         disabled: !isDirty || !isInputJsonValid || !isResultJsonValid,
         color: "rgb(89, 149, 95)",
      },
   ];

   return (
      <div style={{ width: "100%", height: "100%" }}>
         <ThemeProvider theme={darkTheme}>
            <div
               role="tabpanel"
               hidden={activeTabIndex !== index}
               id={`simple-tabpanel-${index}`}
               aria-labelledby={`simple-tab-${index}`}
               style={{
                  width: "100%",
                  height: "calc(100%)",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               {activeTabIndex === index && (
                  <>
                     <PromptEditorToolbar
                        leftActions={leftToolbarActions}
                        actions={toolbarActions}
                     />
                     <StyledForm>
                        <StyledTextField
                           label="Name"
                           value={localPrompt.name || ""}
                           onChange={(e) =>
                              handleChange("name", e.target.value)
                           }
                           fullWidth
                        />
                        <StyledTextField
                           label="System Prompt Template (Optional)"
                           value={localPrompt.system_template?.template || ""}
                           onChange={(e) =>
                              handleChange(
                                 "system_template.template",
                                 e.target.value
                              )
                           }
                           multiline
                           minRows={4}
                           maxRows={20}
                           fullWidth
                        />
                        <StyledTextField
                           label="User Prompt Template"
                           value={localPrompt.user_template?.template || ""}
                           onChange={(e) =>
                              handleChange(
                                 "user_template.template",
                                 e.target.value
                              )
                           }
                           multiline
                           minRows={4}
                           maxRows={20}
                           fullWidth
                        />
                        <Grid container spacing={2}>
                           <Grid item xs={6}>
                              <FormControl fullWidth>
                                 <InputLabel>Response Format</InputLabel>
                                 <StyledSelect
                                    value={localPrompt.response_format || ""}
                                    onChange={(e) =>
                                       handleChange(
                                          "response_format",
                                          e.target.value
                                       )
                                    }
                                    label="Response Format"
                                 >
                                    <MenuItem value="plain">Plain</MenuItem>
                                    <MenuItem value="json">
                                       JSON Object
                                    </MenuItem>
                                    <MenuItem value="json_list">
                                       JSON List
                                    </MenuItem>
                                 </StyledSelect>
                              </FormControl>
                           </Grid>
                           <Grid item xs={6}>
                              <StyledTextField
                                 label="Max Tokens (Optional)"
                                 type="number"
                                 value={localPrompt.max_tokens || ""}
                                 onChange={(e) =>
                                    handleChange("max_tokens", e.target.value)
                                 }
                                 fullWidth
                              />
                           </Grid>
                           <Grid item xs={6}>
                              <SelectModelWidget
                                 value={localPrompt.default_model}
                                 token={token}
                                 logout={logout}
                                 disabled={false}
                                 onChange={(value) =>
                                    handleChange("default_model", value)
                                 }
                              ></SelectModelWidget>
                           </Grid>
                           <Grid item xs={6}>
                              <StyledTextField
                                 label="Timeout (Optional)"
                                 type="number"
                                 value={localPrompt.timeout || ""}
                                 onChange={(e) =>
                                    handleChange("timeout", e.target.value)
                                 }
                                 fullWidth
                              />
                           </Grid>
                           <Grid item xs={6}>
                              <StyledTextField
                                 label="Temperature (Optional)"
                                 type="number"
                                 inputProps={{ step: 0.1, min: 0, max: 2 }}
                                 value={localPrompt.temperature || ""}
                                 onChange={(e) =>
                                    handleChange("temperature", e.target.value)
                                 }
                                 fullWidth
                              />
                           </Grid>
                           <Grid item xs={6}>
                              <StyledTextField
                                 label="Seed (Optional)"
                                 type="number"
                                 value={localPrompt.seed || ""}
                                 onChange={(e) =>
                                    handleChange("seed", e.target.value)
                                 }
                                 fullWidth
                              />
                           </Grid>
                        </Grid>
                     </StyledForm>
                  </>
               )}
            </div>
         </ThemeProvider>
         <JSONInfoDialog
            open={isInfoDialogOpen}
            onClose={() => setIsInfoDialogOpen(false)}
            data={localPrompt}
         />
         <DeleteConfirmationDialog
            open={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={handleDelete}
            objectName={"prompt"}
         />
      </div>
   );
};

export default PromptEditorPanel;
